<template>
	<div class="rating">
		<div class="col-1200">
			<div class="stars">
				<img class="star" src="../assets/img/icons/star.svg" alt="5 stars out of 5">
				<img class="star" src="../assets/img/icons/star.svg" alt="">
				<img class="star" src="../assets/img/icons/star.svg" alt="">
				<img class="star" src="../assets/img/icons/star.svg" alt="">
				<img class="star" src="../assets/img/icons/star.svg" alt="">
				<span class="mark">5.0</span>
			</div>
			<div class="info" v-html="$t('rating.info')"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Rating'
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.col-1200 {

	text-align: center;

}

.stars {

	display: inline-flex;
	align-items: center;

}

.star {

	margin-right: 8px;

}

.mark {

	margin-left: 8px;

	font-weight: $bold;
	font-size: 17px;
	color: $color-black;

}

.info {

	margin-top: 10px;

	font-size: 14px;
	color: $color-gray;
	line-height: 1.4;

}

::v-deep .link {

	color: $color-gray;
	text-decoration: none;
	transition: color 0.1s ease;

	&:hover {
		color: $color-black;
	}

}
</style>
