<template>
	<section class="changes-modal" :class="{ '-loaded': loaded, '-active': active }" @click="$emit('close')">
		<div class="content" @click.stop>
			<img class="close" src="../assets/img/icons/close.svg" @click="$emit('close')" alt="Close">
			<div class="titles-modal">
				<img class="icon-modal" src="../assets/img/icons/love.svg" alt="">
				<h2 class="title-modal">{{ $t('changesModal.features.title') }}</h2>
			</div>
			<div class="changes">
				<ul class="column">
					<li class="change" v-for="change in $t('changesModal.features.list1')" :key="change">{{ change }}</li>
				</ul>
				<ul class="column">
					<li class="change" v-for="change in $t('changesModal.features.list2')" :key="change">{{ change }}</li>
				</ul>
			</div>
			<div class="titles-modal">
				<img class="icon-modal" src="../assets/img/icons/integrate.svg" alt="">
				<h2 class="title-modal">{{ $t('changesModal.enhancements.title') }}</h2>
			</div>
			<div class="changes">
				<ul class="column">
					<li class="change" v-for="change in $t('changesModal.enhancements.list1')" :key="change">{{ change }}</li>
				</ul>
				<ul class="column">
					<li class="change" v-for="change in $t('changesModal.enhancements.list2')" :key="change">{{ change }}</li>
				</ul>
			</div>
			<div class="titles-modal">
				<img class="icon-modal" src="../assets/img/icons/bug.svg" alt="">
				<h2 class="title-modal">{{ $t('changesModal.fixes.title') }}</h2>
			</div>
			<div class="changes">
				<ul class="column">
					<li class="change" v-for="change in $t('changesModal.fixes.list1')" :key="change">{{ change }}</li>
				</ul>
				<ul class="column">
					<li class="change" v-for="change in $t('changesModal.fixes.list2')" :key="change">{{ change }}</li>
				</ul>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'ChangesModal',
	data() {
		return {
			loaded: false
		}
	},
	props: {
		active: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		active: (isActive) => {
			if (isActive) {
				document.querySelector('body').classList.add('-modal')
			} else {
				document.querySelector('body').classList.remove('-modal')
			}
		}
	},
	mounted() {
		if (typeof window.__PRERENDER_INJECTED === 'undefined') {
			this.loaded = true
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.changes-modal {

	width: 100%;
	height: 100%;

	display: none;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;

	background-image: linear-gradient(55deg, rgba($color-black, 0.6), rgba(#171330, 0.6));
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.2s ease;

	&.-loaded {
		display: block;
	}

	&.-active {
		opacity: 1;
		pointer-events: all;
	}

}

.content {

	max-width: 800px;

	margin: 40px auto;
	box-sizing: border-box;
	padding: 60px;
	position: relative;

	border-radius: 16px;
	box-shadow: 0 48px 100px 0 rgba(17, 12, 46, 0.15);
	background-color: $color-white;
	transform: scale(0.85);
	opacity: 0;
	transition: transform 0.2s ease, opacity 0.2s ease;

	@media (max-width: 880px) {
		margin: 4%;
		padding: 40px;
	}

	@media (max-width: 600px) {
		padding: 25px;
	}

}

.-active > .content {

	transform: scale(1);
	opacity: 1;

}

.changes {

	display: flex;
	margin-bottom: 62px;

	&:last-of-type {

		margin-bottom: 0;

	}

	@media (max-width: 600px) {
		flex-direction: column;
	}

}

.change {

	max-width: 280px;

	margin-top: 8px;

	font-size: 15px;
	line-height: 1.87;
	color: #6e6d76;

	list-style-type: none;

	&:first-of-type {

		margin-top: 0;

	}

	&::before {

		content: "— ";
		margin-left: -28px;
		margin-right: 8px;

	}

	@media (max-width: 600px) {
		max-width: initial;
	}

}

.column {

	flex: 1;
	margin: 0;
	margin-left: 28px;
	margin-right: 40px;
	padding: 0;

	&:last-of-type {
		margin-right: 0;
		margin-bottom: 0;
	}

	@media (max-width: 600px) {
		margin-bottom: 8px;
	}

}

.titles-modal {

	display: flex;
	margin-bottom: 26px;

}

.icon-modal {

	margin-right: 20px;

}

.title-modal {

	margin: 0;

	font-size: 16px;
	font-weight: $bold;
	line-height: 2.5;
	letter-spacing: -0.1px;
	color: $color-black;

}

.close {

	position: absolute;
	right: 15px;
	top: 15px;
	padding: 15px;

	cursor: pointer;
	transition: all 0.1s ease;

	@media (max-width: 880px) {
		right: 10px;
		top: 10px;
	}

	&:hover {
		transform: scale(0.9);
	}

}

</style>
