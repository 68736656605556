<template>
	<section class="discounts" id="discounts">
		<div class="col-1200">
			<div class="content">

				<!-- CleanShot -->
				<div class="discount">
					<div class="icon">
						<a href="https://getcleanshot.com?ref=pixelsnap" target="_blank">
							<img src="../assets/img/cleanshot-icon.png" srcset="../assets/img/cleanshot-icon@2x.png 2x" alt="">
						</a>
					</div>
					<div class="info">
						<div class="title" v-html="$t('discounts.cleanshot.title')"></div>
						<p class="description" v-html="$t('discounts.cleanshot.description')"></p>
					</div>
					<router-link class="action-link" :to="localizedPath('/cleanshot-discount')">
						<ActionButton size="medium" :label="$t('button.upgrade')" />
					</router-link>
				</div>

				<!-- Student -->
				<div class="discount">
					<div class="icon">
						<img src="../assets/img/student.svg" alt="">
					</div>
					<div class="info">
						<div class="title">{{ $t('discounts.student.title') }}</div>
						<p class="description" v-html="$t('discounts.student.description')"></p>
					</div>
					<a class="action-link" href="mailto:hello@mtw.team?subject=PixelSnap 2 edu discount">
						<ActionButton size="medium" :label="$t('menu.contact')" />
					</a>
				</div>

				<!-- Setapp -->
				<div class="discount">
					<div class="icon">
						<img src="../assets/img/setapp.svg" alt="">
					</div>
					<div class="info">
						<div class="title">{{ $t('discounts.setapp.title') }}</div>
						<p class="description">{{ $t('discounts.setapp.description') }}</p>
					</div>
					<a class="action-link" href="https://go.setapp.com/stp226?refAppID=358&stc=pixelsnap" target="_blank">
						<img src="../assets/img/setapp-button.svg" alt="Available on Setapp">
					</a>
				</div>

			</div>
		</div>
	</section>
</template>

<script>
import ActionButton from './ActionButton.vue'

export default {
	name: 'Discounts',
	components: {
		ActionButton
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.content {

	max-width: 800px;

	padding: 80px 0;
	margin: 0 auto;

}

.discount {

	padding: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;

	background: $color-white;
	border-radius: 8px;
	box-shadow: 0 10px 50px 0 rgba(17,12,46,0.08);

	&:last-of-type {
		margin-bottom: 0;
	}

	@media (max-width: 800px) {
		flex-direction: column;
		text-align: center;
		padding: 30px;
	}

}

.icon {

	flex-shrink: 0;

}

.info {

	margin-left: 40px;

	@media (max-width: 800px) {
		max-width: 400px;
		margin-left: 0;
		margin-top: 10px;
	}

}

::v-deep .cleanshot-link {

	&:hover {
		text-decoration: underline;
	}

}

.title {

	font-size: 18px;
	font-weight: $bold;
	color: $color-black;

	::v-deep .cleanshot-link {
		color: $color-black;
	}

}

.description {

	margin-top: 10px;
	margin-bottom: 0;

	color: $color-gray;
	line-height: 1.5;

	::v-deep .bold {
		font-weight: $medium;
		color: $color-black;
	}

	::v-deep .cleanshot-link {
		color: $color-gray;
	}

}

.action-link {

	flex-shrink: 0;
	margin-left: 50px;

	@media (max-width: 800px) {
		margin-left: 0;
		margin-top: 30px;
	}

}
</style>
