<template>
	<div class="language-dropdown">
		<select class="select" @change="updateLanguage">
			<option v-for="(name, code) in languages" :value="code" :key="code" :selected="language === code">{{ name }}</option>
		</select>
		<div class="flag" :class="`-${language}`"></div>
		<img class="arrow" src="../assets/img/language-arrow.svg" alt="">
	</div>
</template>

<script>
import { languages, localizedPath } from '../i18n'

export default {
	name: 'LanguageDropdown',
	data() {
		return {
			languages
		}
	},
	computed: {
		language() {
			return this.$i18n.locale
		}
	},
	methods: {
		setCookie(cname, cvalue, exdays) {
			var d = new Date()
			d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
			var expires = 'expires=' + d.toUTCString()
			document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
		},
		updateLanguage(event) {
			const language = event.target.value
			let path = this.$route.fullPath
			if (this.$route.params.lang !== undefined) {
				path = path.replace(`/${this.$route.params.lang}`, '')
			}
			this.setCookie('preferredLanguage', language, 30)
			this.$router.push(localizedPath(path, language))
			this.$emit('change')
			window.sa_event('language_change')
		}
	}
}
</script>

<style lang="scss" scoped>
.language-dropdown {

	position: relative;
	display: flex;
	align-items: center;

	cursor: pointer;

}

.select {

	width: 100%;
	height: 100%;

	position: absolute;
	z-index: 105;
	left: 0;
	top: 0;

	cursor: pointer;
	opacity: 0;
	-webkit-appearance: none;

}

.flag {

	width: 20px;
	height: 20px;

	border-radius: 100%;

	&.-zh {
		background-image: url('../assets/img/flags/cn.svg');
	}

	&.-en {
		background-image: url('../assets/img/flags/en.svg');
	}

	&.-es {
		background-image: url('../assets/img/flags/es.svg');
	}

	&.-fr {
		background-image: url('../assets/img/flags/fr.svg');
	}

	&.-it {
		background-image: url('../assets/img/flags/it.svg');
	}

	&.-ja {
		background-image: url('../assets/img/flags/jp.svg');
	}

	&.-ru {
		background-image: url('../assets/img/flags/ru.svg');
	}

}

.arrow {

	width: 9px;
	height: 5px;

	margin-left: 7px;

}
</style>
