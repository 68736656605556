<template>
	<div class="faq">
		<NavBar />
		<div class="col-1200">
			<h1 class="heading">{{ $t('faq.title') }}</h1>
			<section class="questions">
				<div class="question">
					<div class="title">
						{{ $t('faq.questions[0].question') }}
						<img class="plus" src="../assets/img/icons/plus.svg" alt="+">
					</div>
					<p class="answer" v-html="$t('faq.questions[0].answer')"></p>
				</div>
				<div class="question">
					<div class="title">
						{{ $t('faq.questions[1].question') }}
						<img class="plus" src="../assets/img/icons/plus.svg" alt="+">
					</div>
					<p class="answer" v-html="$t('faq.questions[1].answer')"></p>
				</div>
				<div class="question">
					<div class="title">
						{{ $t('faq.questions[2].question') }}
						<img class="plus" src="../assets/img/icons/plus.svg" alt="+">
					</div>
					<p class="answer" v-html="$t('faq.questions[2].answer', { lost_link: localizedPath('/lost-license') })"></p>
				</div>
				<div class="question">
					<div class="title">
						{{ $t('faq.questions[3].question') }}
						<img class="plus" src="../assets/img/icons/plus.svg" alt="+">
					</div>
					<p class="answer" v-html="$t('faq.questions[3].answer')"></p>
				</div>
				<div class="question">
					<div class="title">
						{{ $t('faq.questions[4].question') }}
						<img class="plus" src="../assets/img/icons/plus.svg" alt="+">
					</div>
					<p class="answer" v-html="$t('faq.questions[4].answer')"></p>
				</div>
				<div class="question">
					<div class="title">
						{{ $t('faq.questions[5].question') }}
						<img class="plus" src="../assets/img/icons/plus.svg" alt="+">
					</div>
					<p class="answer" v-html="$t('faq.questions[5].answer')"></p>
				</div>
				<div class="question">
					<div class="title">
						{{ $t('faq.questions[6].question') }}
						<img class="plus" src="../assets/img/icons/plus.svg" alt="+">
					</div>
					<p class="answer" v-html="$t('faq.questions[6].answer')"></p>
				</div>
				<div class="question">
					<div class="title">
						{{ $t('faq.questions[7].question') }}
						<img class="plus" src="../assets/img/icons/plus.svg" alt="+">
					</div>
					<p class="answer" v-html="$t('faq.questions[7].answer')"></p>
				</div>
				<div class="question">
					<div class="title">
						{{ $t('faq.questions[8].question') }}
						<img class="plus" src="../assets/img/icons/plus.svg" alt="+">
					</div>
					<p class="answer" v-html="$t('faq.questions[8].answer')"></p>
				</div>
				<div class="question">
					<div class="title">
						{{ $t('faq.questions[9].question') }}
						<img class="plus" src="../assets/img/icons/plus.svg" alt="+">
					</div>
					<p class="answer" v-html="$t('faq.questions[9].answer', { cleanshot_link: `https://getcleanshot.com?utm_source=pixelsnap&ref=pixelsnap` })"></p>
				</div>
			</section>
			<Newsletter />
		</div>
		<div class="menu-overlay" :class="langClass"></div>
	</div>
</template>

<script>
import store from '../store'
import NavBar from '../components/NavBar.vue'
import Newsletter from '../components/Newsletter.vue'

export default {
	name: 'FaqPage',
	metaInfo() {
		return this.localizedMeta('/faq', 'faq')
	},
	components: {
		NavBar,
		Newsletter
	},
	computed: {
		store() {
			return store
		}
	},
	mounted() {
		document.querySelectorAll('.title').forEach(element => {
			element.addEventListener('click', event => {
				event.target.parentElement.classList.toggle('-active')
				let answer = event.target.parentElement.querySelector('.answer')
				if (answer.style.maxHeight || answer.style.maxHeight === 'initial') {
					answer.style.maxHeight = null
				} else {
					answer.style.maxHeight = answer.scrollHeight + 'px'
					setTimeout(() => {
						answer.style.maxHeight = 'initial'
					}, 150)
				}
			})
		})
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.heading {

	margin-top: 180px;

	font-weight: $bold;
	font-size: 36px;
	color: $color-black;
	letter-spacing: -0.4px;
	text-align: center;
	line-height: 1.5;

	@media (max-width: 800px) {
		margin-top: 130px;
		font-size: 28px;
	}

}

.questions {

	max-width: 800px;

	margin: 0 auto;
	margin-top: 120px;

	@media (max-width: 800px) {
		margin-top: 70px;
	}

}

.question {

	padding: 0 20px;

	border-top: 2px solid lighten($color-silver, 13);

	&:last-of-type {
		margin-bottom: 0;
		border-bottom: 2px solid lighten($color-silver, 13);
	}

	@media (max-width: 500px) {
		padding: 0;
	}

}

.title {

	padding: 40px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;

	font-weight: $medium;
	font-size: 22px;
	color: $color-black;
	letter-spacing: -0.2px;
	line-height: 1.7;

	cursor: pointer;
	user-select: none;

	@media (max-width: 800px) {
		padding: 30px 0;
		font-size: 18px;
	}

	@media (max-width: 500px) {
		font-size: 18px;
	}

}

.plus {

	margin-left: 20px;

	transition: all 0.15s ease;
	pointer-events: none;

}

.-active .plus {

	transform: rotate(45deg);

}

.answer {

	max-height: 0;

	overflow: hidden;
	margin-top: 0;
	margin-bottom: 0;

	font-weight: $medium;
	font-size: 17px;
	color: $color-gray;
	line-height: 1.8;

	transition: max-height 0.15s ease;

	::v-deep a {

		color: $color-primary;

		&:hover {
			text-decoration: underline;
		}

	}

	::v-deep strong {

		font-weight: $bold;

	}

	@media (max-width: 800px) {
		font-size: 15px;
	}

}

.-active .answer {

	margin-bottom: 40px;

	@media (max-width: 800px) {
		margin-bottom: 30px;
	}

}

.newsletter {

	margin-top: 150px;

	@media (max-width: 1000px) {
		margin-top: 100px;
	}

}
</style>
