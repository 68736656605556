<template>
	<header class="header">
		<div class="col-1200">
			<a href="/buy" v-if="applyDiscount">
				<div class="ph" :class="{ '-show': showBanner, '-countdown': showCountdown }"><span class="bold -countdown" v-if="showCountdown">{{ countdown }}</span>Black Friday is here! Enjoy <span class="bold">30% discount</span>!</div>
			</a>
			<p class="tagline" :class="[{ '-ph': applyDiscount }, langClass]">{{ $t('header.tagline') }}</p>
			<div class="actions">
				<a class="buy-link" :href="localizedPath('/buy')">
					<ActionButton :label="$t('button.buy')" />
				</a>
				<ActionButton :label="$t('button.video')" :play="true" :outline="true" @click="showPlayer('play_header_button')" />
			</div>
			<div class="guarantee" @click="$emit('showMoneyBack')">{{ $t('moneyback.title') }}</div>
		</div>
	</header>
</template>

<script>
import ActionButton from './ActionButton.vue'
import { showDiscount, discountEndDate } from '../helpers/discount'

export default {
	name: 'Header',
	components: {
		ActionButton
	},
	data() {
		return {
			applyDiscount: showDiscount,
			showBanner: false,
			showCountdown: true,
			countdown: '',
			countDownDate: discountEndDate,
			countdownInterval: null
		}
	},
	methods: {
		pad(number, size) {
			let s = '000000000' + number
			return s.substr(s.length - size)
		},
		updateCountdown() {
			const now = new Date().getTime()
			const distance = this.countDownDate - now
			const days = Math.floor(distance / (1000 * 60 * 60 * 24))
			const hours = this.pad(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)), 2)
			const minutes = this.pad(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)), 2)
			const seconds = this.pad(Math.floor((distance % (1000 * 60)) / 1000), 2)
			if (days > 0) {
				this.countdown = `${days}d, ${hours}:${minutes}:${seconds}`
			} else {
				this.countdown = `${hours}:${minutes}:${seconds}`
			}
		},
		showPlayer(event) {
			this.$emit('showPlayer')
			window.sa_event(event)
		},
		getCookie(cname) {
			var name = cname + '='
			var decodedCookie = decodeURIComponent(document.cookie)
			var ca = decodedCookie.split(';')
			for (var i = 0; i < ca.length; i++) {
				var c = ca[i]
				while (c.charAt(0) === ' ') {
					c = c.substring(1)
				}
				if (c.indexOf(name) === 0) {
					return c.substring(name.length, c.length)
				}
			}
			return null
		}
	},
	mounted() {
		this.updateCountdown()
		this.countdownInterval = setInterval(this.updateCountdown, 1000)
		if (typeof window.__PRERENDER_INJECTED === 'undefined') {
			setTimeout(() => {
				this.showBanner = true
			}, 500)
		}
	},
	destroyed() {
		if (this.countdownInterval !== null) {
			clearInterval(this.countdownInterval)
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.header {

	padding-top: 60px;
	text-align: center;

}

.ph {

	max-width: 445px;

	display: inline-block;
	padding: 6px 15px;
	margin-top: 40px;

	font-weight: $medium;
	font-size: 14px;
	color: #ffffff;
	line-height: 24px;
	background: $gradient-dark;
	border-radius: 7px;
	box-shadow: 0 8px 14px 0 rgba($color-black, 0.2);
	opacity: 0;
	transform: scale(0.8);
	transition: all 0.3s ease;

	.bold {
		font-weight: $bold;
		color: #ffffff;
		&.-countdown {
			padding: 0 3px;
			text-align: center;
			font-variant-numeric: tabular-nums;
			box-sizing: border-box;
			display: inline-block;
			background: #000000;
			border-radius: 5px;
			margin-right: 10px;
		}
	}

	&.-show {
		opacity: 1;
		transform: scale(1);
	}

	&.-countdown {
		padding: 6px 10px 6px 6px;
	}

}

.tagline {

	max-width: 700px;

	margin: 0 auto;
	margin-top: 100px;
	text-align: center;

	color: #000000;
	font-weight: $bold;
	font-size: 44px;
	line-height: 1.38;
	letter-spacing: -0.73px;

	&.-ph {
		margin-top: 60px;
	}

	&.-ja {
		max-width: 780px;
	}

	&.-es {
		max-width: 800px;
	}

	&.-ru {
		max-width: 1000px;
	}

	@media (max-width: 1000px) {
		max-width: 600px;
		margin-top: 60px;
		font-size: 35px;
		&.-ph {
			margin-top: 40px;
		}
		&.-ja {
			max-width: 620px;
		}
		&.-es {
			max-width: 650px;
		}
		&.-ru {
			max-width: 700px;
		}
	}

	@media (max-width: 500px) {
		max-width: 300px;
		font-size: 28px;
	}

}

.actions {

	margin-top: 55px;
	margin-bottom: 30px;
	display: flex;
	justify-content: center;

	@media (max-width: 500px) {
		flex-direction: column;
		align-items: center;
		margin-top: 45px;
	}

}

.action {

	@media (max-width: 500px) {
		width: 180px;
		padding: 0;
		justify-content: center;
	}

}

.buy-link {

	margin-right: 30px;

	@media (max-width: 500px) {
		margin-right: 0;
		margin-bottom: 15px;
	}

}

.guarantee {

	margin-top: 0;
	margin-bottom: 60px;
	display: inline-block;
	padding: 10px;

	color: $color-gray;
	font-size: 16px;
	font-weight: $regular;
	line-height: 1.5;
	letter-spacing: -0.2px;

	cursor: pointer;
	transition: color 0.1s ease;

	&:hover {
		color: $color-black;
	}

}
</style>
