export default function loadExternalScript(src) {
	const loadedScripts = Array.from(document.querySelectorAll('script[data-vue-loaded]'))
	if (loadedScripts.some(script => script.src === src)) {
		return
	}
	const tag = document.createElement('script')
	tag.setAttribute('src', src)
	tag.setAttribute('data-vue-loaded', '')
	document.body.appendChild(tag)
}
