<template>
	<footer class="footer col-1200">
		<div class="content">
			<div class="sections">
				<ul class="section">
					<li class="title">{{ $t('footer.license') }}</li>
					<li class="item">
						<a class="link" href="https://licenses.maketheweb.io/" target="_blank" rel="noopener">{{ $t('menu.manager') }}</a>
					</li>
					<li class="item">
						<router-link class="link" :to="localizedPath('/buy')" @click.native="buyClick">{{ $t('buy.tab1') }}</router-link>
					</li>
					<li class="item">
						<a class="link" href="https://licenses.maketheweb.io/" target="_blank" rel="noopener">{{ $t('footer.seats') }}</a>
					</li>
				</ul>
				<ul class="section">
					<li class="title">{{ $t('footer.product') }}</li>
					<li class="item">
						<router-link class="link" :to="localizedPath('/changelog')">{{ $t('menu.changelog') }}</router-link>
					</li>
					<li class="item">
						<a class="link" href="https://licenses.maketheweb.io/download/pixelsnap2" target="_blank" rel="noopener">{{ $t('footer.download') }}</a>
					</li>
					<li class="item">
						<a class="link" href="/EULA.pdf" target="_blank">EULA</a>
					</li>
				</ul>
				<ul class="section">
					<li class="title">{{ $t('footer.support') }}</li>
					<li class="item">
						<a class="link" href="mailto:hello@mtw.team">{{ $t('menu.contact') }}</a>
					</li>
					<li class="item">
						<a class="link" href="https://pixelsnap-2.s3.eu-central-1.amazonaws.com/Presskit.zip" target="_blank" rel="noopener" @click="pressKitClick">Press kit</a>
					</li>
					<li class="item">
						<router-link class="link" :to="localizedPath('/faq')">{{ $t('menu.faq') }}</router-link>
					</li>
				</ul>
			</div>
			<div class="info">
				<div class="copyright">&copy; Copyright <a class="link" href="https://mtw.team" target="_blank" rel="noopener">MTW</a></div>
				<a class="twitter" href="https://twitter.com/PixelSnap_app" target="_blank" rel="noopener">
					<svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'logo'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
  <path d="M14 2.656a5.735 5.735 0 01-1.65.453 2.877 2.877 0 001.263-1.59 5.754 5.754 0 01-1.824.698 2.875 2.875 0 00-4.895 2.62 8.154 8.154 0 01-5.92-3.002 2.876 2.876 0 00.89 3.835 2.86 2.86 0 01-1.301-.36c-.032 1.331.922 2.576 2.303 2.853-.404.11-.847.135-1.297.05a2.874 2.874 0 002.684 1.994A5.776 5.776 0 010 11.397a8.131 8.131 0 004.403 1.29c5.333 0 8.346-4.504 8.164-8.544A5.849 5.849 0 0014 2.656z"/>
</svg>
					{{ $t('footer.twitter') }}
				</a>
				<LanguageDropdown />
			</div>
		</div>
	</footer>
</template>

<script>
import LanguageDropdown from './LanguageDropdown.vue'

export default {
	name: 'Footer',
	components: {
		LanguageDropdown
	},
	methods: {
		pressKitClick() {
			window.sa_event('presskit_download')
		},
		buyClick() {
			if (this.$route.path.includes('/buy')) {
				window.scrollTo(0, 0)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.footer {

	margin-top: 115px;

	@media (max-width: 1000px) {
		margin-top: 90px;
	}

}

.content {

	padding: 80px;
	padding-top: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	border-top: 1px solid $color-silver;

	@media (max-width: 1150px) {
		padding: 40px;
		padding-top: 0;
	}

	@media (max-width: 980px) {
		flex-direction: column;
		align-items: stretch;
	}

}

.sections {

	max-width: 600px;

	display: flex;
	justify-content: space-between;
	flex-grow: 1;
	flex-wrap: wrap;
	margin-right: 30px;

	@media (max-width: 980px) {
		max-width: none;
		justify-content: space-between;
		margin-right: 0;
	}

}

.section {

	padding: 0;
	margin: 0;
	margin-right: 40px;
	margin-top: 40px;

	text-align: left;
	list-style: none;

}

.title {

	margin-bottom: 28px;

	font-weight: $bold;
	font-size: 14px;
	line-height: 1.35;
	color: $color-gray;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 12px;

}

.item {

	&:first-of-type .link {
		margin-left: 0;
	}

	&:last-of-type .link {
		margin-right: 0;
		margin-bottom: 0;
	}

}

.item > .link {

	margin: 16px 0;
	display: flex;

	font-size: 14px;
	line-height: 1.4;
	color: $color-gray;
	transition: color 0.1s ease;

	&:hover {
		color: $color-primary;
	}

}

.info {

	text-align: right;
	margin-top: 40px;
	flex-shrink: 0;

	@media (max-width: 980px) {
		margin-top: 60px;
		text-align: center;
	}

	@media (max-width: 467px) {
		text-align: left;
	}

}

.copyright {

	font-size: 14px;
	line-height: 1.4;
	color: $color-black;

	.link {

		font-weight: $bold;
		color: $color-black;
		transition: color 0.1s ease;

		&:hover {
			color: $color-primary;
		}

	}

}

.twitter {

	display: block;
	margin-top: 16px;

	font-weight: $bold;
	font-size: 14px;
	line-height: 1.4;
	color: #1DA1F2;
	transition: color 0.1s ease;

	&:hover {
		color: darken(#1DA1F2, 15);
	}

	.logo {

		margin-right: 3px;
		margin-bottom: -1px;

		fill: currentColor;

	}

}

.language-dropdown {

	display: inline-flex;
	margin-top: 20px;

}
</style>
