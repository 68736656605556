<template>
	<input class="text-input" :class="`-${size}`" :value="value" @input="$emit('input', $event.target.value)" :type="type" :placeholder="placeholder" :disabled="disabled" :autofocus="autofocus" :required="required" ref="input">
</template>

<script>
export default {
	name: 'TextInput',
	props: {
		size: {
			type: String,
			default: 'large'
		},
		value: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: ''
		},
		type: {
			type: String,
			default: 'text'
		},
		disabled: {
			type: Boolean,
			default: false
		},
		autofocus: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		}
	},
	mounted() {
		if (this.autofocus) {
			this.$refs.input.focus()
		}
	},
	activated() {
		if (this.autofocus) {
			this.$refs.input.focus()
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.text-input {

	height: 60px;

	position: relative;
	padding: 0 25px;
	box-sizing: border-box;

	font-size: 16px;
	color: $color-black;
	line-height: 1.5;

	border: 1px solid #C7C7CA;
	box-shadow: 0 4px 7px -2px rgba(14,12,27,0.07);
	border-radius: 8px;
	outline: none;
	appearance: none;

	&.-standard {
		height: 52px;
		padding: 0 20px;
	}

	&::placeholder {
		color: $color-gray;
	}

	&:focus {
		padding: 0 24px;
		border: 2px solid $color-secondary;
		&.-standard {
			padding: 0 19px;
		}
	}

}
</style>
