<template>
	<section class="integration">
		<div class="col-1200">
			<div class="left">
				<img class="love-icon" src="../assets/img/icons/love.svg" alt="">
				<h2 class="title">{{ $t('integration.title') }}</h2>
				<p class="description">{{ $t('integration.description') }}</p>
			</div>
			<div class="right">
				<div class="col -left">
					<div class="box -top">
						<img class="logo" src="../assets/img/logo-sketch.svg" alt="">
						<p class="name">Sketch</p>
					</div>
					<div class="box -new">
						<img class="logo" src="../assets/img/affinity.svg" alt="">
						<p class="name">Affinity Suite</p>
					</div>
				</div>
				<div class="col">
					<div class="box -top">
						<img class="logo" src="../assets/img/adobexd.svg" alt="">
						<p class="name">Adobe XD</p>
					</div>
					<div class="box -new">
						<img class="logo" src="../assets/img/figma.svg" alt="">
						<p class="name">Figma</p>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'Integration'
}
</script>

<style lang="scss" scoped>

@import '@/assets/scss/variables.scss';

.integration {

	background-image: linear-gradient(72deg, #f1f1f3, #f8f8f9);

}

.col-1200 {

	display: flex;
	align-items: center;
	padding-top: 120px;
	padding-bottom: 90px;

	@media (max-width: 1000px) {
		flex-direction: column;
		text-align: center;
		padding-top: 70px;
		padding-bottom: 60px;
	}

}

.left {

	margin-right: 40px;
	margin-top: -20px;

	@media (max-width: 1000px) {
		margin-right: 0;
	}

}

.right {

	max-width: 500px;

	display: flex;
	flex-grow: 1;
	margin-left: auto;

	@media (max-width: 1000px) {
		width: 400px;
		margin-left: 0;
		margin-top: 60px;
	}

	@media (max-width: 475px) {
		width: 100%;
	}

}

.col {

	flex: 1;
	display: flex;
	flex-direction: column;

	&.-left {

		margin-top: -30px;
		margin-right: 30px;

		@media (max-width: 1000px) {
			margin-top: 0;
		}

	}

}

.love-icon {

	@media (max-width: 1000px) {
		display: none;
	}

}

.title {

	max-width: 450px;

	margin: 0;
	margin-top: 23px;

	font-size: 44px;
	font-weight: $bold;
	line-height: 1.45;
	letter-spacing: -0.4px;
	color: $color-black;

	@media (max-width: 1200px) {
		font-size: 36px;
	}

	@media (max-width: 1000px) {
		max-width: 400px;
		margin-bottom: 15px;
		font-size: 30px;
	}

}

.description {

	max-width: 400px;

	margin: 0;
	margin-top: 28px;

	font-size: 21px;
	font-weight: $medium;
	line-height: 1.71;
	letter-spacing: -0.2px;
	color: $color-gray;

	@media (max-width: 1200px) {
		font-size: 18px;
	}

	@media (max-width: 1000px) {
		max-width: 300px;
		margin: 0 auto;
		font-size: 16px;
	}

}

.name {

	text-align: center;
	margin: 0;
	margin-top: 5px;

	font-size: 20px;
	font-weight: $medium;
	line-height: 1.8;
	letter-spacing: -0.2px;
	color: $color-black;

	@media (max-width: 1200px) {
		font-size: 17px;
	}

	@media (max-width: 475px) {
		font-size: 15px;
	}

}

.logo {

	@media (max-width: 1200px) {
		zoom: 0.8;
	}

	@media (max-width: 475px) {
		zoom: 0.6;
	}

}

.box {

	height: 180px;

	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: relative;
	overflow: hidden;

	background: $color-white;
	box-shadow: 0 48px 100px 0 rgba(17, 12, 46, 0.05);
	border-radius: 16px;

	&:last-of-type {
		margin-bottom: 0;
	}

	&.-new::after {

		content: 'NEW';
		width: 100px;
		height: 25px;

		position: absolute;
		top: 7px;
		right: -30px;
		display: flex;
		justify-content: center;
		align-items: center;

		font-size: 11px;
		font-weight: $bold;
		color: $color-white;

		background: $gradient-primary;
		box-shadow: -2px 2px 4px 0 rgba(0,0,0,0.1);
		transform: rotate(45deg);

	}

	@media (max-width: 1200px) {
		height: 150px;
	}

	@media (max-width: 475px) {
		height: 120px;
	}

}

.soon {

	text-align: center;
	margin: 0;

	font-size: 16px;
	line-height: 1.5;
	color: $color-gray;

}
</style>
