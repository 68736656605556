import axios from 'axios'
import Vue from 'vue'
import Router from 'vue-router'
import { i18n, languages } from './i18n'

import HomePage from './views/HomePage.vue'
import BuyPage from './views/BuyPage.vue'
import CleanShotDiscountPage from './views/CleanShotDiscountPage.vue'
import FaqPage from './views/FaqPage.vue'
import ChangelogPage from './views/ChangelogPage.vue'

Vue.use(Router)

const router = new Router({
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else if ((from.path.includes('/buy') && to.path.includes('/buy/upgrade')) || (from.path.includes('/buy/upgrade') && to.path.includes('/buy'))) {
			return {}
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	},
	mode: 'history',
	base: '/',
	routes: [
		{
			path: '/:lang([a-zA-Z]{2})?',
			component: HomePage
		},
		{
			path: '/:lang([a-zA-Z]{2})?/buy',
			component: BuyPage
		},
		{
			path: '/:lang([a-zA-Z]{2})?/cleanshot-discount',
			component: CleanShotDiscountPage
		},
		{
			path: '/:lang([a-zA-Z]{2})?/faq',
			component: FaqPage
		},
		{
			path: '/:lang([a-zA-Z]{2})?/changelog',
			component: ChangelogPage
		}
	]
})

router.beforeEach((to, from, next) => {
	let language = to.params.lang
	if (!language) {
		language = 'en'
	}
	if (languages.hasOwnProperty(language)) {
		i18n.locale = language
		axios.defaults.headers.common['X-Language'] = language
	}
	next()
})

export default router
