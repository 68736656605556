import Vue from 'vue'
import VueI18n from 'vue-i18n'
import translations from './translations'

Vue.use(VueI18n)

const languages = {
	en: 'English',
	zh: '简体中文',
	es: 'Español',
	fr: 'Français',
	it: 'Italiano',
	ja: '日本語',
	ru: 'Русский'
}

const i18n = new VueI18n({
	locale: 'en',
	fallbackLocale: 'en',
	messages: translations
})

function localizedPath(path, language = i18n.locale) {
	if (language === 'en') {
		if (path.length === 0) {
			return '/'
		} else {
			return path
		}
	}
	return `/${language}${path}`
}

function langLinkTags(path) {
	let tags = []
	for (const language in languages) {
		tags.push({
			rel: 'alternate',
			hreflang: language,
			href: `https://getpixelsnap.com${localizedPath(path, language)}`
		})
	}
	tags.push({
		rel: 'alternate',
		hreflang: 'x-default',
		href: `https://getpixelsnap.com${localizedPath(path, 'en')}`
	})
	return tags
}

function localizedMeta(path, page) {
	return {
		htmlAttrs: {
			lang: i18n.locale
		},
		title: i18n.t(`title.${page}`),
		meta: [
			{
				property: 'og:title',
				content: i18n.t(`title.${page}`)
			},
			{
				property: 'og:url',
				content: `https://getpixelsnap.com${localizedPath(path)}`
			},
			...i18n.t(`meta`)
		],
		link: langLinkTags(path)
	}
}

function langClass() {
	return `-${i18n.locale}`
}

Vue.mixin({
	methods: {
		localizedPath,
		localizedMeta
	},
	computed: {
		langClass
	}
})

export { i18n, languages, localizedPath }
