<template>
	<div class="col-1200">
		<section class="buy">
			<div class="left">
				<p class="label">{{ $t('buyWidget.label') }}</p>
				<h2 class="title" :class="langClass">{{ $t('buyWidget.title') }}</h2>
				<p class="description">{{ $t('buyWidget.description') }}</p>
				<a :href="localizedPath('/buy')">
					<ActionButton :label="$t('button.buy')" size="large" />
				</a>
			</div>
			<div class="right">
				<div class="measure"><img class="img" src="../assets/img/measureanddots.svg"></div>
				<div class="photo"><img class="img" src="../assets/img/photo.jpg" srcset="../assets/img/photo@2x.jpg 2x"></div>
			</div>
		</section>
	</div>
</template>

<script>
import ActionButton from '../components/ActionButton.vue'

export default {
	name: 'Advertisement',
	components: {
		ActionButton
	}
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.buy {

	display: flex;
	justify-content: space-between;
	padding-bottom: 40px;

	border-radius: 16px;
	background: $color-black;
	background-image: linear-gradient(111deg, rgba(14, 12, 27, 0), rgba($color-primary, 0.3));

	@media (max-width: 1150px) {
		padding: 50px;
	}

	@media (max-width: 500px) {
		padding: 40px;
	}

}

.left {

	flex: 1;
	margin-left: 96px;

	@media (max-width: 1250px) {
		margin-left: 50px;
	}

	@media (max-width: 1150px) {
		margin-left: 0;
	}

}

.label {

	display: inline-block;
	padding: 10px 15px;
	margin-top: 95px;

	font-size: 12px;
	font-weight: $bold;
	color: $color-primary;
	letter-spacing: 2px;
	line-height: 1.7;

	text-transform: uppercase;
	background-image: linear-gradient(135deg, rgba(#FF844A, 0.1) 0%, rgba(#FF3B79, 0.1) 100%);
	border-radius: 8px;

	@media (max-width: 1150px) {
		margin-top: 0;
	}

	@media (max-width: 1000px) {
		font-size: 11px;
	}

	@media (max-width: 500px) {
		display: none;
	}

}

.title {

	margin-top: 12px;
	margin-bottom: 14px;

	font-weight: $bold;
	font-size: 48px;
	line-height: 1.5;
	letter-spacing: -0.4px;
	color: $color-white;

	&.-ja {
		font-size: 36px;
	}

	&.-ru {
		font-size: 36px;
	}

	&.-fr {
		font-size: 36px;
	}

	&.-es {
		font-size: 36px;
	}

	@media (max-width: 1000px) {
		font-size: 36px;
	}

	@media (max-width: 500px) {
		font-size: 28px;
		margin-top: 0;
		&.-ja {
			font-size: 28px;
		}
		&.-ru {
			font-size: 28px;
		}
		&.-fr {
			font-size: 28px;
		}
		&.-es {
			font-size: 28px;
		}
	}

}

.description {

	max-width: 550px;
	margin-top: 0;
	margin-bottom: 42px;

	color: rgba($color-white, 0.6);
	line-height: 1.6;
	letter-spacing: -0.1px;
	font-size: 16px;

}

.right {

	flex: 1;

	@media (max-width: 1150px) {
		display: none;
	}

}

.measure {

	max-width: 478px;

	float: right;

	.img {

		width: 100%;

		display: block;

	}

}

.photo {

	max-width: 390px;

	float: right;
	margin-right: 40px;

	border-radius: 16px;

	.img {

		width: 100%;
		border-radius: 16px;
		box-shadow: 0 40px 80px 0 rgba(0, 0, 0, 0.24);

	}

}
</style>
