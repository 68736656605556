<template>
	<section class="testimonials">
		<div class="col-1200">
			<h2 class="title">{{ $t('testimonials.title') }}</h2>
			<p class="description">{{ $t('testimonials.description') }}</p>
		</div>
		<Rating />
		<div class="reviews" ref="reviews">
			<div class="review" v-for="testimonial in testimonials" :key="testimonial.id">
				<img class="avatar" :src="`/img/avatars/${testimonial.avatar}`" alt="">
				<p class="content" @click.stop>{{ testimonial.content }}</p>
				<img class="quote" src="../assets/img/quote.svg" alt="">
				<p class="author" @click.stop>{{ testimonial.author }}, {{ testimonial.profession }}</p>
			</div>
		</div>
		<div class="arrows">
			<img class="arrow -left" src="../assets/img/arrow-left.svg" @click="moveBackward(true)" alt="<">
			<img class="arrow -right" src="../assets/img/arrow-right.svg" @click="moveForward(true)" alt=">">
		</div>
	</section>
</template>

<script>
import Rating from '../components/Rating.vue'

let observer = null
let interval = null
let center = 0

export default {
	name: 'Testimonials',
	components: {
		Rating
	},
	data() {
		return {
			isAnimating: false,
			isSwiping: false,
			swipeStartX: 0,
			swipeStartY: 0
		}
	},
	computed: {
		testimonials() {
			return [
				{
					avatar: 'paul_boag.jpg',
					content: this.$t('testimonials.reviews.boag'),
					author: 'Paul Boag',
					profession: 'Smashing Magazine'
				},
				{
					avatar: 'richard_blechinger.jpg',
					content: this.$t('testimonials.reviews.blechinger'),
					author: 'Richard Blechinger',
					profession: 'Freelancer developer'
				},
				{
					avatar: 'wojtek_witkowski.jpg',
					content: this.$t('testimonials.reviews.witkowski'),
					author: 'Wojtek Witkowski',
					profession: 'Rainbow'
				},
				{
					avatar: 'eddie_lobanovskiy.jpg',
					content: this.$t('testimonials.reviews.lobanovskiy'),
					author: 'Eddie Lobanovskiy',
					profession: 'Unfold Agency'
				},
				{
					avatar: 'hardik_pandya.jpg',
					content: this.$t('testimonials.reviews.pandya'),
					author: 'Hardik Pandya',
					profession: 'Google'
				},
				{
					avatar: 'kevin_smith.jpg',
					content: this.$t('testimonials.reviews.smith'),
					author: 'Kevin Smith',
					profession: 'Cisco'
				},
				{
					avatar: 'chris_coyier.jpg',
					content: this.$t('testimonials.reviews.coyier'),
					author: 'Chris Coyier',
					profession: 'CodePen'
				}
			]
		}
	},
	methods: {
		startLoop() {
			interval = setInterval(this.moveForward, 12000)
		},
		stopLoop() {
			if (interval !== null) {
				clearInterval(interval)
				interval = null
			}
		},
		moveForward(manual = false) {
			if (this.isAnimating) {
				return
			}
			this.isAnimating = true
			if (manual) {
				this.stopLoop()
			}
			let firstReview = this.$refs.reviews.querySelector('.review:nth-child(1)')
			let moveBy = firstReview.offsetWidth + parseInt(window.getComputedStyle(firstReview).marginRight)
			this.$refs.reviews.classList.add('-animate')
			this.$refs.reviews.style.transform = `translateX(-${moveBy}px)`
			this.$refs.reviews.querySelector(`.review:nth-child(${center})`).classList.remove('-active')
			this.$refs.reviews.querySelector(`.review:nth-child(${center + 1})`).classList.add('-active')
			setTimeout(() => {
				var fragment = document.createDocumentFragment()
				fragment.appendChild(this.$refs.reviews.querySelector('.review:nth-child(1)'))
				this.$refs.reviews.appendChild(fragment)
				this.$refs.reviews.classList.remove('-animate')
				this.$refs.reviews.style.transform = ''
				this.isAnimating = false
				if (manual) {
					this.startLoop()
				}
			}, 500)
		},
		moveBackward(manual = false) {
			if (this.isAnimating) {
				return
			}
			this.isAnimating = true
			if (manual) {
				this.stopLoop()
			}
			let firstReview = this.$refs.reviews.querySelector('.review:nth-child(1)')
			let moveBy = firstReview.offsetWidth + parseInt(window.getComputedStyle(firstReview).marginRight)
			this.$refs.reviews.classList.add('-animate')
			this.$refs.reviews.style.transform = `translateX(${moveBy}px)`
			this.$refs.reviews.querySelector(`.review:nth-child(${center})`).classList.remove('-active')
			this.$refs.reviews.querySelector(`.review:nth-child(${center - 1})`).classList.add('-active')
			setTimeout(() => {
				var fragment = document.createDocumentFragment()
				fragment.appendChild(this.$refs.reviews.querySelector(`.review:nth-child(${this.testimonials.length})`))
				this.$refs.reviews.insertBefore(fragment, this.$refs.reviews.querySelector('.review:nth-child(1)'))
				this.$refs.reviews.classList.remove('-animate')
				this.$refs.reviews.style.transform = ''
				this.isAnimating = false
				if (manual) {
					this.startLoop()
				}
			}, 500)
		},
		bindClickEvents() {
			let reviews = this.$refs.reviews.querySelectorAll('.review')
			for (var i = 0; i < reviews.length; i++) {
				reviews[i].addEventListener('click', this.clickEvent)
			}
		},
		clickEvent(event) {
			if (this.index(event.target) > center) {
				this.moveForward(true)
			} else if (this.index(event.target) < center) {
				this.moveBackward(true)
			}
		},
		unbindClickEvents() {
			let reviews = this.$refs.reviews.querySelectorAll('.review')
			for (var i = 0; i < reviews.length; i++) {
				reviews[i].removeEventListener('click', this.clickEvent)
			}
		},
		index(el) {
			if (!el) return -1
			var i = 0
			do {
				if (el.nodeType === 1) i++
				el = el.previousSibling
			} while (el)
			return i
		},
		intersectionCallback(entries) {
			if (entries.some(entry => entry.intersectionRatio > 0)) {
				if (interval == null) {
					this.startLoop()
				}
			} else {
				this.stopLoop()
			}
		},
		bindSwipeEvents() {
			let reviews = this.$refs.reviews
			reviews.addEventListener('touchstart', this.touchStartEvent)
			reviews.addEventListener('touchmove', this.touchMoveEvent)
			reviews.addEventListener('touchend', this.touchEndEvent)
		},
		unbindSwipeEvents() {
			let reviews = this.$refs.reviews
			reviews.removeEventListener('touchstart', this.touchStartEvent)
			reviews.removeEventListener('touchmove', this.touchMoveEvent)
			reviews.removeEventListener('touchend', this.touchEndEvent)
		},
		touchStartEvent(event) {
			if (event.touches.length > 1) {
				return
			}
			let touch = event.touches[0]
			this.swipeStartX = touch.clientX
			this.swipeStartY = touch.clientY
			this.isSwiping = true
		},
		touchMoveEvent(event) {
			if (this.isSwiping !== true) {
				return
			}
			let touch = event.touches[0]
			if (Math.abs(this.swipeStartY - touch.clientY) > 50) {
				this.isSwiping = false
				return
			}
			let distance = this.swipeStartX - touch.clientX
			if (distance > 50) {
				this.moveForward(true)
				this.isSwiping = false
			} else if (distance < -50) {
				this.moveBackward(true)
				this.isSwiping = false
			}
		},
		touchEndEvent() {
			this.isSwiping = false
		}
	},
	mounted() {
		center = Math.ceil(this.testimonials.length / 2)
		this.$refs.reviews.querySelector(`.review:nth-child(${center})`).classList.add('-active')
		observer = new IntersectionObserver(this.intersectionCallback)
		observer.observe(this.$refs.reviews)
		this.bindClickEvents()
		this.bindSwipeEvents()
	},
	beforeDestroy() {
		this.stopLoop()
		this.unbindClickEvents()
		this.unbindSwipeEvents()
		if (observer !== null) {
			observer.disconnect()
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.testimonials {

	padding-top: 122px;
	overflow: hidden;

	@media (max-width: 1000px) {
		padding-top: 60px;
	}

}

.title {

	text-align: center;
	margin: 0;

	font-size: 48px;
	font-weight: $bold;
	line-height: 1.5;
	letter-spacing: -0.4px;
	color: $color-black;

	@media (max-width: 1000px) {
		font-size: 36px;
	}

}

.description {

	text-align: center;
	margin-top: 25px;
	margin-bottom: 58px;

	font-size: 24px;
	font-weight: $medium;
	line-height: 1.5;
	letter-spacing: -0.2px;
	color: $color-gray;

	@media (max-width: 1000px) {
		font-size: 20px;
		margin-top: 10px;
	}

}

.rating {

	margin-bottom: 163px;

	@media (max-width: 1000px) {
		margin-bottom: 130px;
	}

}

.reviews {

	display: flex;
	justify-content: center;
	position: relative;

	&.-animate {
		transition: transform 0.5s ease;
	}

}

.review {

	text-align: center;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 40px;

	width: 800px;
	height: 396px;
	border-radius: 16px;
	box-shadow: 0 10px 50px 0 rgba(17, 12, 46, 0.1);
	opacity: 0.5;
	background: rgba($color-white, 0.6);
	transition: box-shadow 0.5s ease, opacity 0.5s ease;
	cursor: pointer;

	&.-active {

		background: $color-white;
		box-shadow: 0 48px 100px 0 rgba(17, 12, 46, 0.15);
		opacity: 1;
		cursor: initial;

	}

	&:last-of-type {

		margin-right: 0;

	}

	@media (max-width: 880px) {
		width: 91vw;
	}

}

.avatar {

	width: 104px;
	height: 104px;

	margin-top: -52px;

	border-radius: 100%;
	filter: grayscale(100%);
	transition: filter 0.5s ease;
	pointer-events: none;

	@media (max-width: 600px) {
		width: 52px;
		height: 52px;
		margin-top: -26px;
	}

}

.-active .avatar {

	filter: none;

}

.content {

	max-width: 650px;

	text-align: center;
	margin: auto 0;
	padding: 0 7%;

	font-size: 18px;
	line-height: 1.8;
	letter-spacing: -0.2px;
	color: $color-black;
	pointer-events: none;

	@media (max-width: 500px) {
		font-size: 16px;
	}

}

.-active .content {

	pointer-events: all;

}

.quote {

	margin-bottom: 15px;

	pointer-events: none;

	@media (max-width: 500px) {
		width: 24px;
		height: 18px;
	}

}

.author {

	text-align: center;
	margin: 0;
	margin-bottom: 40px;

	font-size: 16px;
	line-height: 1.5;
	color: $color-black;
	font-weight: $medium;

	pointer-events: none;

	@media (max-width: 500px) {
		font-size: 14px;
		margin-bottom: 25px;
	}

}

.-active .content {

	pointer-events: all;

}

.arrows {

	text-align: center;
	margin-top: 80px;
	margin-bottom: 90px;

	@media (max-width: 880px) {
		margin-top: 40px;
		margin-bottom: 60px;
	}

}

.arrow {

	padding: 10px;

	cursor: pointer;

	&.-left {
		margin-right: 8px;
	}

	&.-right {
		margin-left: 8px;
	}

}
</style>
