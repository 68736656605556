<template>
	<div class="player-modal" @click="$emit('close')" ref="modal">
		<img class="close" src="../assets/img/icons/close-player.svg" @dragstart.prevent>
		<div class="player-wrap">
			<div id="player"></div>
		</div>
	</div>
</template>

<script>
import loadExternalScript from '../helpers/loadExternalScript'

export default {
	name: 'Player',
	props: {
		active: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		responsivePlayer() {
			const player = document.querySelector('.player-wrap')
			const ratio = 16 / 9
			const maxWidth = window.innerWidth * 0.9
			const maxHeight = window.innerHeight * 0.85
			let newHeight = maxWidth / ratio
			if (newHeight <= maxHeight) {
				player.style.width = `${maxWidth}px`
				player.style.height = `${newHeight}px`
			} else {
				let newWidth = maxHeight * ratio
				player.style.width = `${newWidth}px`
				player.style.height = `${maxHeight}px`
			}
		}
	},
	watch: {
		active: function(isActive) {
			if (isActive) {
				document.querySelector('body').classList.add('-modal')
				this.$refs.modal.classList.add('-show')
				setTimeout(() => {
					this.$refs.modal.classList.add('-active')
				}, 0)
				setTimeout(() => {
					if (typeof window.ytPlayer !== 'undefined') {
						window.ytPlayer.playVideo()
					}
				}, 200)
			} else {
				if (typeof window.ytPlayer !== 'undefined') {
					window.ytPlayer.pauseVideo()
				}
				this.$refs.modal.classList.remove('-active')
				setTimeout(() => {
					this.$refs.modal.classList.remove('-show')
				}, 200)
				document.querySelector('body').classList.remove('-modal')
			}
		}
	},
	mounted() {
		if (typeof window.__PRERENDER_INJECTED === 'undefined') {
			loadExternalScript('https://www.youtube.com/iframe_api')
		}
		window.onYouTubeIframeAPIReady = () => {
			// eslint-disable-next-line
			window.ytPlayer = new YT.Player('player', {
				width: '100%',
				height: '100%',
				videoId: 'cB-2U-3B3dw',
				playerVars: {
					rel: 0,
					modestbranding: 1,
				},
				events: {
					'onReady': this.responsivePlayer
				}
			})
		}
		if (typeof YT !== 'undefined') {
			window.onYouTubeIframeAPIReady()
		}
		window.addEventListener('resize', this.responsivePlayer)
	},
	destroyed() {
		window.removeEventListener('resize', this.responsivePlayer)
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.player-modal {

	width: 100%;
	height: 100%;

	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	display: none;
	flex-direction: column;
    justify-content: center;
	align-items: center;
	box-sizing: border-box;

	background-color: rgba($color-black, 0.9);
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.2s ease;

	&.-show {
		display: flex;
	}

	&.-active {

		opacity: 1;
		pointer-events: all;

	}

}

.player-wrap {

	overflow: hidden;
	position: relative;

}

.close {

	position: absolute;
	right: 30px;
	top: 30px;

	cursor: pointer;
	transition: all 0.1s ease;

	&:hover {
		transform: scale(0.9);
	}

}
</style>
