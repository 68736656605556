<template>
	<section class="video-section">
		<div class="col-1200">
			<div class="video" @click="showPlayer('play_thumbnail')" ref="container">
				<img class="dots -left" src="../assets/img/icons/dots-square.svg" alt="">
				<div class="screen-container">
					<video class="screen" autoplay loop muted playsinline ref="video">
						<source src="../assets/video/retina.mp4" type="video/mp4">
					</video>
				</div>
				<div class="play">
					<img src="../assets/img/icons/play.svg" alt="Play">
				</div>
				<img class="dots -right" src="../assets/img/icons/dots-square.svg" alt="">
			</div>
		</div>
	</section>
</template>

<script>
let observer = null

export default {
	name: 'Video',
	methods: {
		intersectionCallback(entries) {
			if (entries.some(entry => entry.intersectionRatio > 0)) {
				this.play()
			} else {
				this.pause()
			}
		},
		play() {
			this.$refs.video.play()
		},
		pause() {
			this.$refs.video.pause()
		},
		showPlayer(event) {
			this.$emit('showPlayer')
			window.sa_event(event)
		}
	},
	mounted() {
		observer = new IntersectionObserver(this.intersectionCallback)
		observer.observe(this.$refs.container)
		const aspect = 1360 / 764
		const width = this.$refs.container.offsetWidth
		const height = this.$refs.container.offsetWidth / aspect
		this.$refs.video.width = width
		this.$refs.video.height = height
		this.$refs.video.addEventListener('canplay', () => {
			this.$refs.video.removeAttribute('width')
			this.$refs.video.removeAttribute('height')
		})
	},
	destroyed() {
		if (observer !== null) {
			observer.disconnect()
		}
	}
}
</script>

<style lang="scss" scoped>
.video {

	max-width: 800px;
	margin: 0 auto;
	position: relative;

	cursor: pointer;

	&:hover .play {
		transform: scale(1.2);
	}

}

.screen-container {

	overflow: hidden;
	position: relative;

	border-radius: 16px;
	background: #0e0d19;
	box-shadow: 0 80px 100px -24px rgba(0,0,0,0.45);
	background-image: url('../assets/img/poster@2x.png');
	background-size: 100%;

	pointer-events: none;

}

.screen {

	max-width: 100%;

	display: block;

	cursor: pointer;

	@media (max-width: 500px) {
		border-radius: 10px;
	}

}

.dots {

	width: 80px;
	height: 80px;

	position: absolute;

	&.-left {
		left: -120px;
		top: 50%;
		margin-top: -40px;
	}

	&.-right {
		top: 0;
		right: -120px;
	}

	@media (max-width: 1000px) {
		display: none;
	}

}

.play {

	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -40px;
	margin-left: -40px;

	transition: all 0.2s ease-out;
	cursor: pointer;
	pointer-events: none;

}
</style>
