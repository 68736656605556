<template>
	<div id="app">
		<router-view/>
		<Footer />
	</div>
</template>

<script>
import { languages, localizedPath } from './i18n'
import Footer from './components/Footer.vue'

export default {
	name: 'App',
	components: {
		Footer
	},
	methods: {
		setCookie(cname, cvalue, exdays) {
			var d = new Date()
			d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
			var expires = 'expires=' + d.toUTCString()
			document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
		},
		getCookie(cname) {
			var name = cname + '='
			var decodedCookie = decodeURIComponent(document.cookie)
			var ca = decodedCookie.split(';')
			for (var i = 0; i < ca.length; i++) {
				var c = ca[i]
				while (c.charAt(0) === ' ') {
					c = c.substring(1)
				}
				if (c.indexOf(name) === 0) {
					return c.substring(name.length, c.length)
				}
			}
			return null
		},
		parseRef() {
			const params = new URL(window.location.href).searchParams
			if (!params.has('ref')) {
				if (document.referrer.length > 0) {
					const url = new URL(document.referrer)
					const host = url.host.replace('www.', '')
					if (host !== window.location.host) {
						if (host.indexOf('google.') > -1) {
							if (this.getCookie('ref') === null) {
								this.setCookie('ref', host, 30)
							}
						} else {
							this.setCookie('ref', host, 30)
						}
					}
					return
				} else {
					return
				}
			}
			this.setCookie('ref', params.get('ref'), 30)
		},
		detectLanguage() {
			if (typeof window.__PRERENDER_INJECTED !== 'undefined') {
				return
			}
			const browserLanguage = navigator.language.substring(0, 2)
			const preferredLanguage = this.getCookie('preferredLanguage')
			const currentLanguage = this.$i18n.locale
			// User has set language manually before
			if (preferredLanguage !== null) {
				if (currentLanguage !== preferredLanguage) {
					this.switchToLanguage(preferredLanguage)
				}
				return
			}
			// User has NOT set his language manually
			if (currentLanguage === 'en' && currentLanguage !== browserLanguage) {
				if (languages.hasOwnProperty(browserLanguage)) {
					this.switchToLanguage(browserLanguage)
				}
			}
		},
		switchToLanguage(language) {
			let path = this.$route.fullPath
			if (this.$route.params.lang !== undefined) {
				path = path.replace(`/${this.$route.params.lang}`, '')
			}
			this.$router.replace(localizedPath(path, language))
		}
	},
	created() {
		this.detectLanguage()
	},
	beforeMount() {
		this.parseRef()
	}
}
</script>

<style lang="scss">
@import '@/assets/scss/fonts.scss';

body {

	margin: 0;

	&.-modal {

		overflow: hidden;

	}

}

a {

	text-decoration: none;

}

.col-1200 {

	max-width: 1200px;

	padding: 0 8%;
	margin: 0 auto;

}

#app {

	overflow: hidden;

}

@keyframes shake {

	10%, 90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%, 80% {
		transform: translate3d(2px, 0, 0);
	}

	30%, 50%, 70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%, 60% {
		transform: translate3d(4px, 0, 0);
	}

}

.-shake {
	animation: shake 0.8s cubic-bezier(.36,.07,.19,.97) both;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	perspective: 1000px;
}
</style>
