/* eslint-disable no-irregular-whitespace */
export default {
	en: {
		title: {
			home: `PixelSnap 2 for Mac`,
			buy: `PixelSnap 2 – Buy`,
			upgrade: `PixelSnap 2 – Upgrade from PixelSnap 1`,
			lost: `PixelSnap 2 – Lost your license?`,
			faq: `PixelSnap 2 – Frequently Asked Question`,
			changelog: `PixelSnap 2 – Changelog`,
			cleanshot: `PixelSnap 2 – Discount for CleanShot X users`,
		},
		meta: [
			{
				name: `description`,
				content: `PixelSnap - The fastest tool for measuring anything on your screen for macOS. Measure stuff like a PRO!`
			},
			{
				property: `og:description`,
				content: `The fastest tool for measuring anything on your screen.`
			}
		],
		menu: {
			changelog: `Changelog`,
			manager: `License Manager`,
			faq: `FAQ`,
			contact: `Contact us`
		},
		button: {
			menu: `Menu`,
			buy: `Buy now`,
			nav_buy: `Buy now`,
			buy2: `Buy PixelSnap 2`,
			video: `Watch the video`,
			upgrade: `Get discount`,
			lost: `Find my license`,
			newsletter: `Subscribe`
		},
		input: {
			email: `Your e-mail`
		},
		header: {
			tagline: `The fastest tool for measuring anything on your screen`
		},
		moneyback: {
			title: `30-Day Money-Back Guarantee`,
			content: `We back every sale with a 30-day money back guarantee. If&nbsp;you're somehow not happy with your purchase, just <a class="link" href="mailto:hello@mtw.team">let&nbsp;us&nbsp;know</a>.`
		},
		introduction: {
			label: `New version. Better experience.`,
			title: `PixelSnap 2<br/>is up to <span class="gradient-fill">2x faster</span>`,
			content: `Completely rewritten from scratch, the new version provides a truly magical experience. Measuring things has never been faster or more enjoyable!`,
			changes: `Check out 30+ changes`,
			version1: `Version 1`,
			version2: `Version 2`,
			explanation: `Time required to detect and measure an object`
		},
		changesModal: {
			features: {
				title: `New Features`,
				list1: [
					`Snappable guides`,
					`Hold distance on the screen`,
					`Multiple object measurement`,
					`CleanShot integration`,
					`Adobe XD integration`,
					`Screenshot tool for taking screenshots of measurements`,
					`Customize all shortcuts`,
					`Added onboarding tutorial`,
					`Touchbar support`
				],
				list2: [
					`Select object without snapping (with right drag)`,
					`Move rectangle by pressing space`,
					`Find dimensions by pressing dock icon`,
					`Mojave support`,
					`Downscale retina screenshots to 1x`,
					`Hide the icon from the menubar`,
					`Show/Hide units`,
					`Show/Hide cursor`,
					`Auto contrast`
				]
			},
			enhancements: {
				title: `Enhancements`,
				list1: [
					`Completely rewritten from scratch`,
					`Much faster measurements`,
					`UI & UX improvements`,
					`Improved Sketch Integration`,
					`Improved Retina support`
				],
				list2: [
					`Cancel selecting an object by pressing esc`,
					`Improved activation process`,
					`Smaller menubar icon`
				]
			},
			fixes: {
				title: `Bug fixes`,
				list1: [
					`Fixed bug with big screen resolution`,
					`Fixed bug with sending screenshots via Apple Mail.app`,
					`Fixed bug with moving cursor by arrows`,
					`Fixed bug with selecting small objects`
				],
				list2: [
					`Fixed bug with retina screenshots`,
					`Fixed blurry cursor on Mojave`,
					`Fixed moving/resizing out of screen bounds`,
					`Fixed memory leaks`,
					`Other minor bug fixes`
				]
			}
		},
		features: {
			heading: `Works everywhere. In&nbsp;every&nbsp;app.`,
			description: `PixelSnap works on the whole screen, across all apps, and you can <span class="bold">measure anything.</span>`,
			distance: {
				title: `Measure distance`,
				description: `Quickly get the distance between any elements on your screen`,
				highlight1: `Hold dimensions on the screen`,
				highlight2: `Copy to clipboard`
			},
			objects: {
				title: `Measure objects`,
				description: `Instantly find the boundaries of any object by simply dragging an area around it`,
				highlight1: `Magically snaps to the object`,
				highlight2: `Measure multiple objects at once`
			},
			guides: {
				title: `Add snappable guides`,
				description: `Check alignment by adding horizontal and vertical guides on the screen`,
				highlight1: `Displays distance between guides`,
				highlight2: `Snaps to screen elements`
			},
			screenshots: {
				title: `Take screenshots`,
				description: `Export your measurements as an image and easily collaborate with others`,
				highlight1: `Save selected area`,
				highlight2: `Integrated with CleanShot`
			},
			cleanshot: {
				title: `Take better screenshots with CleanShot X`,
				description: `Supercharge your workflow with the most convenient screenshot tool`,
				more: `Learn more`
			},
			crosshair: {
				title: `Crosshair mode`,
				description: `Instantly check alignment of all the items on your screen`
			},
			tolerance: {
				title: `Tolerance mode`,
				description: `Adjust tolerance to work with shadows and low contrast elements`
			}
		},
		darkFeatures: {
			shortcuts: {
				title: `Keyboard shortcuts`,
				description: `Set a global hotkey and adjust all other shortcuts to your needs.`
			},
			appearance: {
				title: `Change appearance`,
				description: `Choose between light and dark mode and adjust the colors.`
			},
			lightweight: {
				title: `Lightweight`,
				description: `Our app is incredibly fast and offers high performance!`
			},
			retina: {
				title: `Retina ready`,
				description: `The app supports Retina and works with multiple displays.`
			},
			touchbar: {
				title: `We support your Touch Bar`,
				description: `Accessing crucial actions has never been easier.`
			}
		},
		integration: {
			title: `Integrates with your favorite tools`,
			description: `PixelSnap shows real dimensions regardless of the displayed zoom level.`,
			soon: `Coming soon`
		},
		testimonials: {
			title: `Feedback`,
			description: `Our users love PixelSnap. Here's what they are saying:`,
			reviews: {
				boag: `A tool that measures screen elements seems too basic to improve on. However, PixelSnap 2 adds features I didn’t even know I needed. An incredible aid to my workflow and definitely worth the upgrade.`,
				blechinger: `Wonderful tool for quickly taking measure of just about anything. I especially love the functionality of snapping a rectangle around an object of your screen. Super handy!`,
				witkowski: `PixelSnap is the missing ingredient in the toolkit of any UI engineer or designer. I use it multiple times each day and can't imagine a Mac without it. Speed up your workflow with this high quality app, you won't regret it!`,
				lobanovskiy: `Super clever idea and very much needed tool in the world of UI designer. Honestly, it exceeded my expectation, flawless in every way.`,
				pandya: `I've been working primarily on web products for the last 3 years where being able to reliably measure things on the screen is such an underrated need. PixelSnap does that so well and is now low-key my favorite utility app.`,
				smith: `PixelSnap has been such a great tool for quickly spot checking size & space when working closely with a development team. I really appreciate its ability to maintain scale when zooming on an object in Sketch, and being able to grab individual items into a screenshot after snapping.`,
				coyier: `PixelSnap is one of those apps that, once you see it, you're like OMG that's the best idea ever. Instant purchase for me.`
			}
		},
		rating: {
			info: `According to <a class="link" href="https://www.producthunt.com/posts/pixelsnap/reviews" target="_blank">Product Hunt</a>`
		},
		buyWidget: {
			label: `Take measuring to the next level!`,
			title: `Get PixelSnap 2`,
			description: `Don't hesitate, we have a 30-day money-back guarantee.`
		},
		newsletter: {
			title: `Be the first to know`,
			description: `We'll inform you about new apps and deals, but no spam, we promise.`
		},
		buy: {
			title: `One-Time Purchase`,
			description: `macOS {macos_version} or later required`,
			tab1: `Buy a new license`,
			tab1_mobile: `Buy`,
			tab2: `Upgrade from PixelSnap 1`,
			tab2_mobile: `Upgrade`,
			variants: {
				1: `1 Mac`,
				2: `2 Macs`,
				5: `5 Macs`,
				10: `10 Macs`,
				15: `15 Macs`
			},
			save: `save {amount}`,
			info: `By downloading or using the app, you are agreeing to our <a class="link" href="/EULA.pdf" target="_blank">EULA</a>. Prices in USD. VAT may apply.`,
			more_devices: `Need more than 10 devices?`
		},
		upgrade: {
			discount50: `Get a 50% discount`,
			discount50_description: `if you bought before <span class="bold">1st March 2019</span>`,
			free: `Get a free upgrade`,
			free_description: `if you bought after <span class="bold">1st March 2019</span>`
		},
		faq: {
			title: `Frequently Asked Questions`,
			questions: [
				{
					question: `Where can I find my license?`,
					answer: `After a successful purchase, you get an email from Paddle with the receipt and your unique license key. Make sure to also check your Spam folder. If you still can't find it, go to the <a href="https://licenses.maketheweb.io/" target="_blank" rel="noopener">License Manager</a> and follow the instructions.`
				},
				{
					question: `Where can I get an invoice for my purchase?`,
					answer: `You can generate an invoice yourself using the <strong>Generate</strong> button at the bottom of your email with your receipt which you receive after a successful purchase.`
				},
				{
					question: `How can I re-download the app?`,
					answer: `Just go to the <a href="https://licenses.maketheweb.io/download/pixelsnap2" target="_blank" rel="noopener">download page</a> and follow the instructions.`
				},
				{
					question: `Is there a trial version?`,
					answer: `We don't provide a trial, but we do have a <strong>30-day money-back guarantee</strong> so feel free to give the app a try. If it doesn't meet your expectations, just <a href="mailto:hello@mtw.team">let us know</a> and you'll get a full refund.`
				},
				{
					question: `Does PixelSnap work with every design software?`,
					answer: `Yes, PixelSnap works on the whole screen, across all apps on your Mac! Moreover, some apps like <strong>Sketch</strong> or <strong>Adobe XD</strong> have full integration with our app, meaning that PixelSnap will show real dimensions regardless of the displayed zoom level.`
				},
				{
					question: `Is there a student discount?`,
					answer: `We are committed to helping students and that’s why we offer a 30% educational discount. In order to get the discount, just <a href="mailto:hello@mtw.team">contact us</a> from your edu-issued email address.`
				},
				{
					question: `Does PixelSnap work on Windows?`,
					answer: `There’s no Windows version and at the moment there are no plans on releasing one.`
				},
				{
					question: `How to transfer my license to a different Mac?`,
					answer: `All you need to do is activate the app on your new Mac with your license key. The old machine will be automatically deactivated. If you have a license for multiple Macs, you can manage the active devices using the <a href="https://licenses.maketheweb.io/" target="_blank" rel="noopener">License Manager</a>.`
				},
				{
					question: `How to add more devices to my license?`,
					answer: `You can add more seats to your license using the <a href="https://licenses.maketheweb.io/" target="_blank" rel="noopener">License Manager</a>.`
				},
				{
					question: `Is CleanShot required to take screenshots?`,
					answer: `No, you don’t have to have <a href="{cleanshot_link}" target="_blank">CleanShot</a> in order to take screenshots with PixelSnap, but the integration does give you all the benefits such as Quick Access Overlay or built-in annotating. More information on all the features is available on the <a href="{cleanshot_link}" target="_blank">CleanShot website</a>.`
				}
			]
		},
		discounts: {
			info: `Are you a student or CleanShot X user?`,
			action: `Get a discount!`,
			cleanshot: {
				title: `For <a class="cleanshot-link" href="https://getcleanshot.com?ref=pixelsnap" target="_blank">CleanShot X</a> users`,
				description: `You already use <a class="cleanshot-link" href="https://getcleanshot.com?ref=pixelsnap" target="_blank">Cleanshot</a> and now it's time to upgrade your measurement skills! We offer a <span class="bold">20% discount</span> for <a class="cleanshot-link" href="https://getcleanshot.com?ref=pixelsnap" target="_blank">CleanShot X</a> users.`
			},
			student: {
				title: `Student discount`,
				description: `We are committed to helping students and that’s why we offer a <span class="bold">30% educational discount</span>. Contact us from your edu-issued email address to get a discount.`
			},
			setapp: {
				title: `Download with Setapp`,
				description: `PixelSnap is also available with Setapp, the subscription service for Mac apps. Pay a monthly fee and get access to 180+ apps!`
			}
		},
		cleanshot: {
			title: `Are you a CleanShot X user?`,
			description: `Enter the email address used to buy <span class="bold">CleanShot X</span> and we'll give you a <span class="bold">20% discount</span>!`
		},
		footer: {
			twitter: `Follow us on Twitter`,
			support: `Support`,
			download: `Download`,
			seats: `Buy additional seats`,
			product: `Product`,
			license: `License`
		}
	},
	ja: {
		title: {
			home: `PixelSnap 2 Mac用測定ツール`,
			buy: `PixelSnap 2 – 購入する`,
			upgrade: `PixelSnap 2 – PixelSnap1からのアップグレード`,
			lost: `PixelSnap 2 – ライセンスを紛失した場合`,
			faq: `PixelSnap 2 – よくある質問`,
			changelog: `PixelSnap 2 – アプリ更新履歴`,
			cleanshot: `PixelSnap 2 – CleanShot Xのユーザー様割引`,
		},
		meta: [
			{
				name: `description`,
				content: `PixelSnapは、画面上のオブジェクトのサイズや距離、間隔を瞬時に測定するmacOS用ツールです。プロ並みに素早くオブジェクトを計測しましょう！`
			},
			{
				property: `og:description`,
				content: `画面上のオブジェクトのサイズや距離、間隔を瞬時に測定するツール。`
			},
			{
				property: `keywords`,
				content: `ピクセル定規, デスクトップ定規, 定規ツール`
			}
		],
		menu: {
			changelog: `アプリ更新履歴`,
			manager: `ライセンスマネージャー`,
			faq: `よくある質問`,
			contact: `お問い合わせ`
		},
		button: {
			menu: `メニュー`,
			buy: `今すぐ購入`,
			nav_buy: `今すぐ購入`,
			buy2: `今すぐ購入`,
			video: `動画を観る`,
			upgrade: `アップグレードする`,
			lost: `ライセンスを再取得する`,
			newsletter: `登録する`
		},
		input: {
			email: `メールアドレスを入力`
		},
		header: {
			tagline: `画面上のオブジェクトのサイズや距離、間隔を瞬時に測定するツール`
		},
		moneyback: {
			title: `30日間の返金保証制度`,
			content: `本アプリに満足いただけなかった場合、ご購入日から30日以内であれば購入代金を全額返金いたします。 返金をご希望の場合は、 <a class="link" href="mailto:hello@mtw.team">こちらからお知らせください</a>。`
		},
		introduction: {
			label: `新たなバージョンが登場。より快適な使い心地に。`,
			title: `PixelSnap2では<br/><span class="gradient-fill">動作速度が2倍まで向上しました</span>`,
			content: `一からコードを書き直した新バージョンのPixelSnap2は、これまでにない快適な使い心地に。画面上のオブジェクトのサイズや距離、間隔を今までになく速く、楽しく測れるようになりました！`,
			changes: `30件以上の更新履歴を見る`,
			version1: `バージョン1`,
			version2: `バージョン2`,
			explanation: `前回のバージョンでは、オブジェクトの検知・測定に時間がかかりました`
		},

		changesModal: {
			features: {
				title: `新機能`,
				list1: [
					`スナップガイド機能`,
					`測定結果を固定表示`,
					`複数のオブジェクトの同時測定`,
					`CleanShotとの統合`,
					`Adobe XDとの統合`,
					`測定対象のスクリーンショット撮影ツール`,
					`全ショートカットのカスタマイズ`,
					`初めての使い方ガイドの追加`,
					`タッチバーのサポート`
				],
				list2: [
					`スナップせずにオブジェクト選択（マウスの右ドラッグを使用）`,
					`スペースを押して選択範囲の移動`,
					`ドックのアイコンで距離を測定`,
					`MacOS「Mojave（モハベ）」 の対応`,
					`Retinaのスクリーンショットを等倍まで縮小`,
					`メニューバーアイコンの非表示`,
					`測定サイズの単位表示または非表示`,
					`カーソルの表示または非表示`,
					`自動コントラスト調整`
				]
			},
			enhancements: {
				title: `向上点`,
				list1: [
					`コードの完全書き直し`,
					`計測速度`,
					`UIとUX`,
					`Sketchとの統合性`,
					`Retinaディスプレイの対応を改善`
				],
				list2: [
					`「etc」ボタンを使用したオブジェクト選択のキャンセル`,
					`起動プロセスの向上`,
					`メニューバーアイコンの縮小化`
				]
			},
			fixes: {
				title: `バグの修正点`,
				list1: [
					`大きな画面上での解像度`,
					`AppleのMail.appを用いたスクリーンショットの送信`,
					`アロー（矢印）のカーソル操作`,
					`小さなオブジェクトの選択`
				],
				list2: [
					`Retinaのスクリーンショット`,
					`Mojave（モハベ）上のカーソルのぼやけ`,
					`選択範囲の移動またはリサイズ`,
					`メモリリーク`,
					`その他の小さなバグ`
				]
			}
		},
		features: {
			heading: `画面上のどこでも。あらゆるアプリで使用可能。`,
			description: `PixelSnapは画面全体や様々なアプリ上で使え、どんなものでも測定可能です。</span>`,
			distance: {
				title: `オブジェクト間の距離を測定`,
				description: `画面上のオブジェクトの間隔を一瞬で測定します。`,
				highlight1: `画面上で複数の測定結果を表示`,
				highlight2: `クリップボードへのコピー`
			},
			objects: {
				title: `オブジェクトの測定`,
				description: `オブジェクトの周りにドラッグするだけで、すぐに対象の基準線を検知します。`,
				highlight1: `一瞬でオブジェクトにスナップ`,
				highlight2: `一度に複数のオブジェクトを計測`
			},
			guides: {
				title: `スナップガイドを利用しよう`,
				description: `縦や横のガイド線を用いてオブジェクトの整列を確認しましょう。`,
				highlight1: `スナップガイド線同士の間隔を表示`,
				highlight2: `画面上のエレメントにスナップ`
			},
			screenshots: {
				title: `スクリーンショットを撮影しよう`,
				description: `測定したオブジェクトを画像としてエクスポートすれば、共同作業が手軽に。`,
				highlight1: `選択エリアの保存`,
				highlight2: `CleanShotとの統合`
			},
			cleanshot: {
				title: `CleanShotでさらに正確なスクリーンショットを撮影しよう`,
				description: `最も便利なスクリーンショットツールでワークフローをスムーズに`,
				more: `詳細を見る`
			},
			crosshair: {
				title: `クロスヘアモード`,
				description: `画面上に表示される全てのアイテムの配置位置を一瞬で確認できます。`
			},
			tolerance: {
				title: `測定感度の設定`,
				description: `測定感度を調整すれば、コントラスト度合いの低いエレメントや影を含めた測定が可能です。`
			}
		},
		darkFeatures: {
			shortcuts: {
				title: `キーボードショートカット`,
				description: `グローバルホットキーを設定して、あなたのニーズに合わせショートカットを調整しましょう。`
			},
			appearance: {
				title: `外観モードの設定`,
				description: `ライトモードまたはダークモードから選んで、好みのカラーに設定しましょう。`
			},
			lightweight: {
				title: `動作の軽さ`,
				description: `PixelSnapは動作が非常に早く、パフォーマンス性の高いアプリです！`
			},
			retina: {
				title: `Retinaに対応`,
				description: `Retinaに対応。複数のディスプレイにも対応可能です。`
			},
			touchbar: {
				title: `タッチバーに対応`,
				description: `Macに欠かせないタッチバー機能からのアクセスが可能に。操作がこれまでになく手軽になりました。`
			}
		},
		integration: {
			title: `定番ツールとのインテグレーションにも対応`,
			description: `PixelSnapは、表示されるズームの程度に関わらず、正確な測定結果を表示します。`,
			soon: `近日統合予定`
		},
		testimonials: {
			title: `フィードバック`,
			description: `PixelSnapは数多くのユーザーに愛用されています。ユーザーのフィードバックはこちら：`,
			reviews: {
				boag: `画面上のオブジェクト測定ツールと聞くとシンプル過ぎるように思いますが、	PixelSnap2は必要だと思いもしなかったツールです。ワークフローの円滑化に理想的なツールで、アップグレードの価値があると思います。`,
				blechinger: `どんなものでも一瞬で測定するツールです。画面上のオブジェクト周辺を検知するスナップ機能が特にお気に入り。本当に重宝しています！`,
				witkowski: `PixelSnapは、UIエンジニアやデザイナーに欠かせないツールキットです。私は1日に何度も使用していて、Mac上での作業に手放せないアプリです。このアプリを活用すれば、ワークフローをさらに高速化できます。必ず買ってよかったと思いますよ！`,
				lobanovskiy: `まさに世界中のUIデザイナーに必須のツールです。正直に言って、僕の期待をはるかに上回りました。素晴らしいアイデアから生まれたアプリで、今では毎日のように色々な場面で活用しています。`,
				pandya: `私はこの3年間、ウェブ製品に特化した仕事に取り組んできましたが、オブジェクトを正確に計測できるツールはもっと評価されるべきだと感じます。PixelSnapはまさにそのツールで、今ではお気に入りのユーティリティアプリです。`,
				smith: `開発チームと密に仕事に取り組む際、サイズや間隔を一瞬で測定できるので、長年重宝しているツールです。 Sketchでのオブジェクトの拡大時にも正確に測定でき、スナップ後にスクリーンショット内で各アイテムを検知できるのが、特に嬉しい機能です。`,
				coyier: `PixelSnapを一目見て、秀逸なアイデアから生まれたアプリだと分かり、即購入しました。`
			}
		},
		rating: {
			info: `<a class="link" href="https://www.producthunt.com/posts/pixelsnap/reviews" target="_blank">Product Hunt</a>による評価`
		},
		buyWidget: {
			label: `画面上の測定をこれまでになく円滑に！`,
			title: `PixelSnap2をダウンロード`,
			description: `30日間の返金保証がありますので、この機会にぜひご購入ください。`
		},
		newsletter: {
			title: `最新ニュースをいち早くお届け`,
			description: `新しいアプリやお得な情報をお知らせします。迷惑メールやスパム行為は一切行っておりませんので、ご安心ください。`
		},
		buy: {
			title: `一度きりの購入で使い放題`,
			description: `MacOS {macos_version}またはそれより新しいバージョンが必要です`,
			tab1: `新たにライセンスを購入`,
			tab1_mobile: `購入する`,
			tab2: `PixelSnap1からのアップグレード`,
			tab2_mobile: `旧版アップグレード`,
			variants: {
				1: `MAC 1台`,
				2: `MAC 2台`,
				5: `MAC 5台`,
				10: `MAC 10台`,
				15: `MAC 15台`
			},
			save: `単独購入より{amount}お得`,
			info: `本アプリをダウンロードまたは使用することにより、お客様は<a class="link" href="/EULA.pdf" target="_blank">ソフトウェア利用許諾契約</a>の条件に同意したものとします。価格は米ドル（USD）で表示されます。また、VAT（付加価値税）が適応される場合があります。`,
			more_devices: `10端末より多い端末が必要ですか?`
		},
		upgrade: {
			discount50: `50%割引`,
			discount50_description: `<span class="bold">2019年3月1日より前</span>にご購入の場合`,
			free: `無料アップグレード`,
			free_description: `<span class="bold">2019年3月1日より後</span>にご購入の場合`
		},
		faq: {
			title: `よくある質問`,
			questions: [
				{
					question: `ライセンスはどこから確認できますか？`,
					answer: `購入に成功した後、Paddleから領収書および固有のライセンスキーの記載されたメールが送られます。ご確認いただき、スパムフォルダーもチェックいただくようお願いします。それでもメールが見つからない場合、<a href="https://licenses.maketheweb.io/" target="_blank" rel="noopener">ライセンスマネージャー</a>にアクセスし、そちらの指示に従ってください。`
				},
				{
					question: `請求書はどこからダウンロードできますか？`,
					answer: `購入完了後に送信されるメールの下部に <strong>「作成する」</strong>ボタンが表示されており、そこから請求書をダウンロード可能です。`
				},
				{
					question: `アプリの再ダウンロード方法を教えてください。`,
					answer: `<a href="https://licenses.maketheweb.io/download/pixelsnap2" target="_blank" rel="noopener">ダウンロードページ</a>にアクセスして、そちらの指示に従ってください。`
				},
				{
					question: `トライアル版はありますか？`,
					answer: `トライアルは提供していませんが、<strong>30日間の返金保証</strong>がありますので、ぜひアプリを気軽にお試しください。万が一満足いただけなかった場合、ご購入日から30日以内に<a href="mailto:hello@mtw.team">こちらからご連絡</a>いただければ、全額返金いたします。`
				},
				{
					question: `PixelSnapはデザイン系ソフトウェアにも対応していますか？`,
					answer: `はい、PixelSnapは画面全体で使えるほか、Macの様々なアプリでもお使いいただけます！さらに、<strong>Sketch</strong>や<strong>Adobe XD</strong>の完全統合が可能です。PixelSnapはズームレベルに関わらず、正確な距離を測定・表示できます。`
				},
				{
					question: `学生割引はありますか？`,
					answer: `学生のサポートに取り組んでおり、対象の方に30%割引を提供しています。割引を適応するには、教育機関により発行される学生用メールアドレスを用いて<a href="mailto:hello@mtw.team">こちらからお問い合わせください。</a>`
				},
				{
					question: `PixelSnap1利用ユーザーへの割引はありますか？`,
					answer: `<strong>2019年3月1日以後</strong>にPixelSnapの旧バージョンを購入されたお客様は、無料アップグレードの対象となります。こちらの日付より前に購入された場合は、50%割引が適応されます。<a href="{buy_link}">購入ページ</a>からライセンスのアップグレードが可能です。`
				},
				{
					question: `WindowsでPixelSnapを利用できますか？`,
					answer: `現在、Windows版は取り扱っておらず、リリース予定もございません。`
				},
				{
					question: `別のMacにライセンスを移行できますか？`,
					answer: `ライセンスキーを使い、新しいMac上でアプリを有効化していただくだけで大丈夫です。古い端末は自動的に無効化されます。もし複数のMac用のライセンスをお持ちの場合は、<a href="https://licenses.maketheweb.io/" target="_blank" rel="noopener">ライセンスマネージャー</a>を使って有効な端末を管理することができます。`
				},
				{
					question: `どうしたら自分のライセンスにより多くの端末を追加できますか?`,
					answer: `<a href="https://licenses.maketheweb.io/" target="_blank" rel="noopener">ライセンスマネージャー</a>を使ってより多くのシートをライセンスに追加することができます。`
				},
				{
					question: `スクリーンショットの撮影には、CleanShotが必要ですか？`,
					answer: `いいえ、PixelSnap上でのスクリーンショット撮影にCleanShotをダウンロードする必要はありません。ただし、統合することでクイックアクセスオーバーレイや内蔵の注釈など、便利な機能をご利用いただけます。さらに詳しい機能については、<a href="{cleanshot_link}" target="_blank">CleanShotのウェブサイト</a>をご覧ください。`
				}
			]
		},
		discounts: {
			info: `あなたは学生、またはCleanShot Xのユーザー様ではありませんか？`,
			action: `ぜひ割引をご利用ください！`,
			cleanshot: {
				title: `<a class="cleanshot-link" href="https://getcleanshot.com?ref=pixelsnap" target="_blank">CleanShot X</a> のユーザー様へ`,
				description: `すでに<a class="cleanshot-link" href="https://getcleanshot.com?ref=pixelsnap" target="_blank">Cleanshot</a>をご利用いただいていますね、測定技能をアップグレードする時期ですよ。私たちは<a class="cleanshot-link" href="https://getcleanshot.com?ref=pixelsnap" target="_blank">CleanShot X</a>のユーザー様に<span class="bold">20% 割引</span>をさせていただきます。`
			},
			student: {
				title: `学生割引`,
				description: `私たちは学生支援に力を入れているので、30%学割をさせていただきます。学校指定のメールアドレスからご連絡いただければ割引が可能です。`
			},
			setapp: {
				title: `Setappでダウンロードできます`,
				description: `Mac用アプリの定額制サービスであるSetappからもPixelSnapはご利用が可能です。月額料金をお支払いいただければ、180以上のアプリがご利用いただけます。`
			}
		},
		cleanshot: {
			title: `あなたはCleanShot Xのユーザー様ですか？`,
			description: `<span class="bold">CleanShot X</span>の購入時にご利用になったメールアドレスを入力していただければ、<span class="bold">20% 割引</span>をいたします。`
		},
		footer: {
			twitter: `Twitterでフォロー`,
			support: `サポート`,
			download: `ダウンロード`,
			seats: `追加のシートを購入`,
			product: `製品`,
			license: `ライセンス`
		}
	},
	zh: {
		title: {
			home: `Mac版PixelSnap 2`,
			buy: `PixelSnap 2  - 购买`,
			upgrade: `PixelSnap 2  - 从PixelSnap 1升级`,
			lost: `PixelSnap 2  - 丢失了您的许可证？`,
			faq: `PixelSnap 2  - 常见问题`,
			changelog: `PixelSnap 2  - 更改日志`,
			cleanshot: `PixelSnap 2 – CleanShot X用户专属折扣`,
		},
		meta: [
			{
				name: `description`,
				content: `PixelSnap  - 一个让您可以迅速地测量屏幕上任何内容的macOS软件。专业地测量专业的效率！`
			},
			{
				property: `og:description`,
				content: `一个让您可以迅速地测量屏幕上任何内容的软件。`
			}
		],
		menu: {
			changelog: `更改日志`,
			manager: `许可证管理器`,
			faq: `常问问题`,
			contact: `联系我们`
		},
		button: {
			menu: `Menu`,
			buy: `立即购买`,
			nav_buy: `立即购买`,
			buy2: `购买PixelSnap 2`,
			video: `观看视频`,
			upgrade: `获得折扣`,
			lost: `查找我的许可证`,
			newsletter: `订阅`
		},
		input: {
			email: `您的电子邮箱`
		},
		header: {
			tagline: `一个让您可以迅速地测量屏幕上任何内容的软件。`
		},
		moneyback: {
			title: `30天退款保证`,
			content: `我们的每笔销售都拥有30天的退款保证的支持。如果您对产品不满意，只需<a class="link" href="mailto:hello@mtw.team">告诉我们</a>.`
		},
		introduction: {
			label: `新的版本。更好的体验。`,
			title: `PixelSnap 2<br/>的速度<span class="gradient-fill">快了高达2倍</span>`,
			content: `全新编写的新版本，提供了真正神奇的体验。测量东西从未感到如此的快速便捷且愉快！`,
			changes: `查看30多处更改`,
			version1: `版本1`,
			version2: `版本2`,
			explanation: `检测和测量物体所需的时间`
		},
		changesModal: {
			features: {
				title: `新功能`,
				list1: [
					`吸附性的贴齐参考线`,
					`保持屏幕上的距离`,
					`多个物体的测量`,
					`CleanShot整合`,
					`Adobe XD整合`,
					`用于截取测量结果的截图的屏幕截图工具`,
					`自定义所有快捷方式`,
					`添加了入门教程`,
					`Touchbar支持`
				],
				list2: [
					`无需吸附捕捉就能选取物体（使用右键拖动）`,
					`按空格键移动矩形`,
					`按Dock(底座)图标查找尺寸`,
					`Mojave支持`,
					`将retina屏幕截图缩小到1倍`,
					`隐藏菜单栏中的图标`,
					`显示/隐藏单位`,
					`显示/隐藏光标`,
					`自动对比度`
				]
			},
			enhancements: {
				title: `优化与改良`,
				list1: [
					`全新编写`,
					`测量速度大幅提升`,
					`UI和UX改进`,
					`更好的Sketch整合`,
					`更好的Retina屏支持`
				],
				list2: [
					`按Esc取消选择物体`,
					`更好的激活进程`,
					`较小的菜单栏图标`
				]
			},
			fixes: {
				title: `错误修复`,
				list1: [
					`修复了大屏幕分辨率的问题`,
					`修复了通过Apple Mail.app发送屏幕截图的问题`,
					`修复了移动箭头光标的问题`,
					`修复了有关选取小物体的问题`
				],
				list2: [
					`修复了retina屏幕截图的错误`,
					`修复了Mojave上光标模糊的问题`,
					`修复了移动/调整大小超出屏幕边界的问题`,
					`修复了内存泄漏问题`,
					`其他小错误修复`
				]
			}
		},
		features: {
			heading: `任何应用内都可以使用。`,
			description: `无论任何应用任何屏幕角落，PixelSnap都能轻松驾驭、完美运行，让您测量<span class="bold">任何物体。</span> `,
			distance: {
				title: `测量距离`,
				description: `快速地获取屏幕上任何元素或物体之间的距离`,
				highlight1: `保持屏幕上的尺寸`,
				highlight2: `复制到剪贴板`
			},
			objects: {
				title: `测量物体`,
				description: `只需在周围的区域拖动，便会立即自动地找到任何物体的边界`,
				highlight1: `神奇般地吸附与捕捉物体`,
				highlight2: `一次测量多个物体`
			},
			guides: {
				title: `添加贴齐参考线`,
				description: `通过屏幕上添加的水平和垂直参考线来检查对齐性`,
				highlight1: `显示参考线之间的距离`,
				highlight2: `有吸附性地贴齐到屏幕上的元素`
			},
			screenshots: {
				title: `截取屏幕截图`,
				description: `将您的测量结果导出为图像，并轻松地与他人合作`,
				highlight1: `保存所选区域`,
				highlight2: `拥有与CleanShot的整合`
			},
			cleanshot: {
				title: `使用CleanShot更好地截取屏幕截图`,
				description: `让最便捷的截屏工具成为您工作流程的助力小伙伴`,
				more: `了解详细信息`
			},
			crosshair: {
				title: `十字准线模式`,
				description: `瞬间校准屏幕上所有物体的对齐性`
			},
			tolerance: {
				title: `容差模式`,
				description: `调整容差以配合阴影和低对比度的元素和物体`
			}
		},
		darkFeatures: {
			shortcuts: {
				title: `快捷键`,
				description: `设置一个主热键，并根据需求调整所有其他的快捷方式。`
			},
			appearance: {
				title: `更改外观`,
				description: `在明暗模式之间进行替换、调整颜色。`
			},
			lightweight: {
				title: `轻量`,
				description: `我们高性能的应用程序运行速度快如雷迅如电，无需担忧卡顿！`
			},
			retina: {
				title: `兼容Retina屏幕`,
				description: `该应用程序支持Retina并可通用于多个显示屏。`
			},
			touchbar: {
				title: `我们支持您的Touch Bar`,
				description: `进行关键的测量操作从未如此的简单`
			}
		},
		integration: {
			title: `与您喜爱的工具整合`,
			description: `无论显示的缩放级别如何，PixelSnap都会显示实际的尺寸。`,
			soon: `即将推出`
		},
		testimonials: {
			title: `反馈`,
			description: `我们的用户喜爱PixelSnap。这些是他们的评语：`,
			reviews: {
				boag: `一个用来测量屏幕上的物体和元素的软件看似简单基本，似乎没有什么可以改进的。然而，PixelSnap 2增添了甚至是我自己都不知道我需要用到的功能。它为我的工作流程提供了不可思议的帮助，这是个绝对值得的升级。`,
				blechinger: `这美妙的工具能够快速地测量几乎任何的东西。我特别喜欢以矩形捕捉屏幕上的物体的吸附性贴齐功能。十分方便！`,
				witkowski: `PixelSnap是任何UI工程师或设计师梦寐以求的工具包组件。我每天很多次地都会使用它，我无法想象拥有一个没有它的Mac会是什么样子。使用这款高品质的应用加快您的工作流程，您肯定不会后悔！`,
				lobanovskiy: `是个超级聪明的点子，而且对于UI设计师来说这是个非常必要的工具。老实说，它远远超越了我的期望，各方面都完美无缺。`,
				pandya: `在过去的3年里，我一直从事网页产品的工作，在这类的工作中，能够可靠地测量屏幕上物体实在是一个被低估的需求。PixelSnap在这一点上做得太好了，它现在是我最喜欢的低调实用的工具。`,
				smith: `PixelSnap是一款非常出色的工具，可以在与开发团队密切合作时迅速地现场检测查找空间与大小。我特别高兴的是它在Sketch中缩放物体时保持比例的能力，并且还能够在贴齐后将单个物体转换成屏幕截图。`,
				coyier: `PixelSnap是一款你体验后就会立马对它感到爱不释手，觉得是有史以来最好的点子的应用程序。购买它我毫不犹豫。`
			}
		},
		rating: {
			info: ` <a class="link" href="https://www.producthunt.com/posts/pixelsnap/reviews" target="_blank">Product Hunt</a>评价 `
		},
		buyWidget: {
			label: `将测量提升到一个新的境界！`,
			title: `获取PixelSnap 2`,
			description: `无需犹豫，我们提供30天的退款保证。`
		},
		newsletter: {
			title: `抢先尝鲜`,
			description: `我们会告知您有关新应用和优惠的信息，并承诺没有垃圾信息。`
		},
		buy: {
			title: `一次性购买`,
			description: `需要macOS {macos_version}或更高的版本 —{version}版`,
			tab1: `购买新许可证`,
			tab1_mobile: `购买`,
			tab2: `从PixelSnap 1升级`,
			tab2_mobile: `升级`,
			variants: {
				1: `1台Mac`,
				2: `2台Mac`,
				5: `5台Mac`,
				10: `10台Mac`,
				15: `15台Mac`
			},
			save: `节省{amount}`,
			info: `下载或使用应用，即表示您同意我们的<a class="link" href="/EULA.pdf" target="_blank">EULA</a>。价格单位为美元。增值税可能适用。`,
			more_devices: `需要使用 10 台以上的设备？`
		},
		upgrade: {
			discount50: `享受五折优惠`,
			discount50_description: `如果您在<span class="bold">2019年3月1日之前</span>购买`,
			free: `获得免费升级`,
			free_description: `如果您在<span class="bold">2019年3月1日</span>之后购买`
		},
		faq: {
			title: `常见问题`,
			questions: [
				{
					question: `我在哪里可以找到我的许可证？`,
					answer: `成功购买后，您会收到来自 Paddle 的电子邮件，里面有收据和您唯一的许可密钥。 请确保您还检查您的垃圾邮件文件夹。如果您仍然找不到邮件，请转到<a href="https://licenses.maketheweb.io/" target="_blank" rel="noopener">许可证管理器</a>并按照说明进行操作。`
				},
				{
					question: `我在哪里可以获取我的消费的发票？`,
					answer: `您可以在成功购买后收到的电子邮件的底部使用“<strong>生成</strong>”按钮以您所收到的收据自行生成发票。`
				},
				{
					question: `如何重新下载应用？`,
					answer: `只需转到<a href="https://licenses.maketheweb.io/download/pixelsnap2" target="_blank" rel="noopener">下载页面</a>，按照说明进行操作即可。`
				},
				{
					question: `是否有试用版？`,
					answer: `我们不提供试用版，但我们有<strong>30天退款的保证</strong>，所以请尽情试用应用。 如果没有达到您的期望，只需<a href="mailto:hello@mtw.team">告诉我们</a>，您便能获得全额退款。`
				},
				{
					question: `PixelSnap是否适用于所有的设计软件？`,
					answer: `是的，PixelSnap适用于整个屏幕，适用于Mac上的所有应用！此外，<strong>Sketch</strong>或<strong>Adobe XD</strong>等应用拥有与我们的应用完整的整合，这意味着无论显示的缩放级别如何，PixelSnap都将显示实际尺寸。`
				},
				{
					question: `是否有学生优惠？`,
					answer: `我们致力于帮助学生，因此我们提供七折的教育优惠。如需获得此优惠，请使用您的教育网电子邮件地址<a href="mailto:hello@mtw.team">联系我们</a>。`
				},
				{
					question: `PixelSnap 1版本的用户是否有优惠？`,
					answer: `在<strong>2019年3月1日</strong>之后购买上一版PixelSnap的客户可以免费升级。如果您在此日期之前购买，将获得五折优惠。您可以在<a href="{buy_link}">购买页面</a>上升级您的许可。`
				},
				{
					question: `PixelSnap是否可在Windows上运行？`,
					answer: `尚无Windows版本，目前还没有发布该版本的计划。`
				},
				{
					question: `如何将我的许可证转移到另一台Mac？`,
					answer: `您需要做的就是使用许可密钥在新的苹果机 (Mac) 上激活该应用。 旧机器将自动停用。 如果您拥有多台苹果机 (Mac) 的许可证，您可以使用<a href="https://licenses.maketheweb.io/" target="_blank" rel="noopener">许可证管理器</a>来管理活动设备。`
				},
				{
					question: `如何在我的许可证中添加更多设备？`,
					answer: `您可以使用<a href="https://licenses.maketheweb.io/" target="_blank" rel="noopener">许可证管理器</a>为许可证添加更多席位。`
				},
				{
					question: `截取屏幕截图是否需要CleanShot？`,
					answer: `不用，用PixelSnap来进行截图操作是不必使用<a href="{cleanshot_link}" target="_blank">CleanShot</a>的，但整合功能为您提供“快速访问覆盖”或内置注释等诸多特别功能。如需了解有关全部功能的更多信息，请访问<a href="{cleanshot_link}" target="_blank">CleanShot网站</a>。`
				}
			]
		},
		discounts: {
			info: `您是学生还是CleanShot X用户？`,
			action: `获取折扣!`,
			cleanshot: {
				title: `若是<a class="cleanshot-link" href="https://getcleanshot.com?ref=pixelsnap" target="_blank">CleanShot X</a>用户`,
				description: `若您已是<a class="cleanshot-link" href="https://getcleanshot.com?ref=pixelsnap" target="_blank">CleanShot</a>用户，那么是时候提升您的测量技能了！我们为<a class="cleanshot-link" href="https://getcleanshot.com?ref=pixelsnap" target="_blank">CleanShot X</a>用户提供高达<span class="bold">20%的优惠折扣</span>。`
			},
			student: {
				title: `学生优惠`,
				description: `我们致力于帮助学生，因此我们提供30%的折扣的作为教育优惠。如需获得此优惠，请使用您的教育网电子邮件地址联系我们。`
			},
			setapp: {
				title: `通过Setapp下载`,
				description: `PixelSnap也能通过Setapp下载。Setapp是一款提供各式各样的Mac软件的订阅服务。只需每月付款，就能使用高达180多个各种不同的应用程序！`
			}
		},
		cleanshot: {
			title: `请问您是不是CleanShot X用户？`,
			description: `输入用来购买<span class="bold">CleanShot X</span>的电子邮件地址，我们会给予您<span class="bold">20%的折扣</span>！`
		},
		footer: {
			twitter: `在推特 (Twitter) 上关注我们`,
			support: `支持`,
			download: `下载`,
			seats: `购买额外的席位`,
			product: `产品`,
			license: `许可证`
		}
	},
	es: {
		title: {
			home: `PixelSnap 2 para Mac`,
			buy: `PixelSnap 2 - Comprar`,
			upgrade: `PixelSnap 2 - Actualizar desde PixelSnap 1`,
			lost: `PixelSnap 2 - ¿Has perdido la licencia?`,
			faq: `PixelSnap 2 - Preguntas frecuentes`,
			changelog: `PixelSnap 2 - Registro de cambios`,
			cleanshot: `PixelSnap 2 - Descuento para usuarios de CleanShot X`,
		},
		meta: [
			{
				name: `description`,
				content: `PixelSnap: la herramienta para macOS más rápida para medir cualquier cosa en tu pantalla. ¡Mide como un PROFESIONAL!`
			},
			{
				property: `og:description`,
				content: `La herramienta más rápida para medir cualquier cosa en tu pantalla.`
			}
		],
		menu: {
			changelog: `Registro de cambios`,
			manager: `Administrador de licencias`,
			faq: `Preguntas frecuentes`,
			contact: `Contactar con nosotros`
		},
		button: {
			menu: `Menu`,
			buy: `Comprar ahora`,
			nav_buy: `Comprar`,
			buy2: `Comprar PixelSnap 2`,
			video: `Ver el vídeo`,
			upgrade: `Obtener descuentos`,
			lost: `Encontrar mi licencia`,
			newsletter: `Suscribirse`
		},
		input: {
			email: `Tu correo electrónico`
		},
		header: {
			tagline: `La herramienta más rápida para medir cualquier cosa en tu pantalla`
		},
		moneyback: {
			title: `Garantía de reembolso de 30 días`,
			content: `Avalamos cada venta con una garantía de devolución de dinero de 30 días. Si por alguna razón no estás satisfecho con tu compra, <a class="link" href="mailto:hello@mtw.team">háznoslo saber</a>.`
		},
		introduction: {
			label: `Nueva versión. Mejor experiencia.`,
			title: `PixelSnap 2 es hasta<br/><span class="gradient-fill">2 veces más rápido</span>`,
			content: `Completamente reescrita desde cero, la nueva versión ofrece una experiencia verdaderamente mágica. ¡Hacer mediciones nunca ha sido tan rápido ni tan divertido!`,
			changes: `Echa un vistazo a las más de 30 nuevas mejoras`,
			version1: `Versión 1`,
			version2: `Versión 2`,
			explanation: `Tiempo necesario para detectar y medir un objeto`
		},
		changesModal: {
			features: {
				title: `Nuevas funciones`,
				list1: [
					`Guías ajustables`,
					`Mantener la distancia en la pantalla`,
					`Medición de múltiples objetos`,
					`Integración con CleanShot`,
					`Integración con Adobe XD`,
					`Herramienta de captura de pantalla para tomar capturas de pantalla de mediciones`,
					`Personalización de todos los accesos directos`,
					`Tutorial de incorporación añadido`,
					`Compatibilidad con barra táctil`
				],
				list2: [
					`Seleccionar objeto sin ajustar (con arrastre derecho)`,
					`Mover rectángulo al presionar espacio`,
					`Averiguar las dimensiones al presionar el icono de anclado`,
					`Compatibilidad con Mojave`,
					`Reducir las capturas de pantalla de retina a x1`,
					`Ocultar el icono de la barra de menú`,
					`Mostrar/ocultar unidades`,
					`Mostrar/ocultar cursor`,
					`Contraste automático`
				]
			},
			enhancements: {
				title: `Mejoras`,
				list1: [
					`Completamente reescrito desde cero`,
					`Mediciones mucho más rápidas`,
					`Mejoras de la interfaz y la experiencia del usuario`,
					`Integración mejorada de bosquejos`,
					`Compatibilidad con retina mejorada`
				],
				list2: [
					`Cancelar la selección de un objeto presionando Esc`,
					`Proceso de activación mejorado`,
					`Icono de barra de menú más pequeño`
				]
			},
			fixes: {
				title: `Correcciones de errores`,
				list1: [
					`Error con resoluciones de pantalla grandes solucionado`,
					`Error al enviar capturas de pantalla a través de la aplicación Apple Mail solucionado`,
					`Error al mover el cursor con las flechas solucionado`,
					`Error al seleccionar objetos pequeños solucionado`
				],
				list2: [
					`Error con las capturas de pantalla de retina solucionado`,
					`Cursor borroso en Mojave corregido`,
					`Movimiento/cambio de tamaño fuera de los límites de la pantalla corregido`,
					`Pérdidas de memoria corregidas`,
					`Otras correcciones de errores menores`
				]
			}
		},
		features: {
			heading: `Funciona en todas partes. En todas las aplicaciones.`,
			description: `PixelSnap funciona en toda la pantalla, en todas las aplicaciones, y puede <span class="bold">medir cualquier cosa.</span> `,
			distance: {
				title: `Mide distancias`,
				description: `Obtén rápidamente la distancia entre cualquier elemento de tu pantalla`,
				highlight1: `Mantén las dimensiones en la pantalla`,
				highlight2: `Copia al portapapeles`
			},
			objects: {
				title: `Mide objetos`,
				description: `Averigüa instantáneamente los límites de cualquier objeto tan solo arrastrando un área a su alrededor`,
				highlight1: `Se ajusta mágicamente al objeto`,
				highlight2: `Mide varios objetos a la vez`
			},
			guides: {
				title: `Añade guías ajustables`,
				description: `Comprueba la alineación añadiendo guías horizontales y verticales en la pantalla`,
				highlight1: `Muestra la distancia entre guías`,
				highlight2: `Se ajusta a los elementos de pantalla`
			},
			screenshots: {
				title: `Toma capturas de pantalla`,
				description: `Exporta tus medidas como una imagen y colabora fácilmente con otros`,
				highlight1: `Guarda el área seleccionada`,
				highlight2: `Integración con CleanShot`
			},
			cleanshot: {
				title: `Toma mejores capturas de pantalla con CleanShot X`,
				description: `Mejora tu flujo de trabajo con la herramienta de captura de pantalla más práctica`,
				more: `Más información`
			},
			crosshair: {
				title: `Modo de punto de mira`,
				description: `Comprueba instantáneamente la alineación de todos los elementos de tu pantalla`
			},
			tolerance: {
				title: `Modo de tolerancia`,
				description: `Ajusta la tolerancia para trabajar con sombras y elementos de bajo contraste`
			}
		},
		darkFeatures: {
			shortcuts: {
				title: `Métodos abreviados de teclado`,
				description: `Establece una tecla de acceso rápido global y ajusta todos los demás accesos directos según tus necesidades.`
			},
			appearance: {
				title: `Cambia la apariencia`,
				description: `Elige entre los modos claro y oscuro y ajusta los colores.`
			},
			lightweight: {
				title: `Ligera`,
				description: `¡Nuestra aplicación es increíblemente rápida y ofrece un alto rendimiento!`
			},
			retina: {
				title: `Preparada para Retina`,
				description: `La aplicación es compatible con Retina y funciona con múltiples pantallas.`
			},
			touchbar: {
				title: `Compatible con Touch Bar`,
				description: `Acceder a acciones cruciales nunca ha sido tan fácil.`
			}
		},
		integration: {
			title: `Se integra con tus herramientas favoritas`,
			description: `PixelSnap muestra dimensiones reales independientemente del nivel de aumento mostrado.`,
			soon: `Próximamente`
		},
		testimonials: {
			title: `Comentarios`,
			description: `Nuestros usuarios adoran PixelSnap. Esto es lo que dicen:`,
			reviews: {
				boag: `Una herramienta que mide elementos de pantalla podría parecer demasiado básica como para mejorarla. Sin embargo, PixelSnap 2 añade características que ni siquiera yo sabía que necesitaba. Una ayuda increíble para mi flujo de trabajo y, definitivamente, vale la pena la actualización.`,
				blechinger: `Es una herramienta maravillosa para medir rápidamente casi cualquier cosa. Especialmente me encanta la funcionalidad de ajustar un rectángulo alrededor de un objeto en la pantalla. ¡Super práctica!`,
				witkowski: `PixelSnap es el ingrediente que falta en la caja de herramientas de cualquier ingeniero o diseñador de UI. La uso varias veces al día y no puedo imaginarme un Mac sin ella. Acelera tu flujo de trabajo con esta aplicación de alta calidad, ¡no te arrepentirás!`,
				lobanovskiy: `Una idea muy inteligente y una herramienta muy necesaria en el mundo del diseñador de interfaces de usuario. Sinceramente, ha superado mis expectativas, es impecable en todos los sentidos.`,
				pandya: `He estado trabajando principalmente en productos web durante los últimos 3 años, donde poder medir de manera fiable las cosas en la pantalla es una necesidad que no se valora lo suficiente. PixelSnap lo hace muy bien y ahora es sencillamente mi aplicación favorita.`,
				smith: `PixelSnap es una gran herramienta para detectar rápidamente el tamaño y el espacio cuando se trabaja en estrecha colaboración con un equipo de desarrollo. Realmente aprecio su capacidad de mantener la escala al aumentar un objeto en Sketch y poder tomar elementos individuales en una captura de pantalla después de ajustar.`,
				coyier: `PixelSnap es una de esas aplicaciones que, una vez que la ves, te parece que es una idea increíble. Compra instantánea.`
			}
		},
		rating: {
			info: `Según <a class="link" href="https://www.producthunt.com/posts/pixelsnap/reviews" target="_blank">Product Hunt</a> `
		},
		buyWidget: {
			label: `¡Lleva la medición al siguiente nivel!`,
			title: `Consigue PixelSnap 2`,
			description: `No lo dudes, tenemos una garantía de devolución de dinero de 30 días.`
		},
		newsletter: {
			title: `Sé el primero en conocerla`,
			description: `Te informaremos sobre nuevas aplicaciones y ofertas, pero nada de enviar correo basura, lo prometemos.`
		},
		buy: {
			title: `Compra única`,
			description: `Es necesario macOS {macos_version} o posterior`,
			tab1: `Comprar una licencia nueva`,
			tab1_mobile: `Comprar`,
			tab2: `Actualizar desde PixelSnap 1`,
			tab2_mobile: `Actualizar`,
			variants: {
				1: `1 Mac`,
				2: `2 Macs`,
				5: `5 Macs`,
				10: `10 Macs`,
				15: `15 Macs`
			},
			save: `ahorro de {amount}`,
			info: `Al descargar o usar la aplicación, aceptas nuestro <a class="link" href="/EULA.pdf" target="_blank">ALUF</a>. Todos los precios expresados en USD. Puede que sea necesario añadir el IVA.`,
			more_devices: `¿Necesita más de 10 dispositivos?`
		},
		upgrade: {
			discount50: `Obtén un descuento del 50 %`,
			discount50_description: `si has comprado antes del <span class="bold">1 de marzo de 2019</span> `,
			free: `Obtén una actualización gratuita`,
			free_description: `si has comprado después del <span class="bold">1 de marzo de 2019 </span> `
		},
		faq: {
			title: `Preguntas frecuentes`,
			questions: [
				{
					question: `¿Dónde puedo encontrar mi licencia?`,
					answer: `Después de una compra exitosa, recibirá un correo electrónico de Paddle con el recibo y su clave de licencia única. Asegúrese de revisar también su carpeta de correo no deseado. Si aún no puede encontrarlo, vaya al <a href="https://licenses.maketheweb.io/" target="_blank" rel="noopener"> Administrador de licencias </a> y siga las instrucciones.`
				},
				{
					question: `¿Dónde puedo obtener la factura de mi compra?`,
					answer: `Puedes generar una factura tú mismo utilizando el botón <strong>Generar</strong> en la parte inferior del correo electrónico con tu recibo que recibirás después de hacer una compra correcta.`
				},
				{
					question: `¿Cómo puedo volver a descargar la aplicación?`,
					answer: `Simplemente vaya a la <a href="https://licenses.maketheweb.io/download/pixelsnap2" target="_blank" rel="noopener"> página de descarga </a> y siga las instrucciones.`
				},
				{
					question: `¿Existe una versión de prueba?`,
					answer: `No proporcionamos una versión de prueba, pero tenemos una <strong>garantía de devolución de dinero de 30 días</strong> , así que no dudes en probar la aplicación. Si no cumple con tus expectativas, <a href="mailto:hello@mtw.team">háznoslo saber</a> y obtendrás un reembolso completo.`
				},
				{
					question: `¿PixelSnap funciona con todos los programas de diseño?`,
					answer: `Sí, PixelSnap funciona en toda la pantalla y en todas las aplicaciones de tu Mac. Además, algunas aplicaciones como <strong>Sketch</strong> o <strong>Adobe XD</strong> tienen una integración completa con nuestra aplicación, lo que significa que PixelSnap mostrará dimensiones reales independientemente del nivel de aumento mostrado.`
				},
				{
					question: `¿Hay descuento para estudiantes?`,
					answer: `Estamos comprometidos con los estudiantes y es por eso que ofrecemos un descuento educativo del 30%. Para obtener el descuento, <a href="mailto:hello@mtw.team">ponte en contacto con nosotros</a> desde tu dirección de correo electrónico de la entidad educativa.`
				},
				{
					question: `¿Hay algún descuento para los usuarios de PixelSnap 1?`,
					answer: `Los clientes que compraron la versión anterior de PixelSnap <strong>después del 1 de marzo de 2019</strong> son aptos para una actualización gratuita. Si realizó la compra antes de esta fecha, obtendrá un descuento del 50 %. Puede actualizar su licencia en la <a href="{buy_link}">página Comprar</a>.`
				},
				{
					question: `¿PixelSnap funciona en Windows?`,
					answer: `No hay una versión para Windows y por el momento no hay planes de publicar una.`
				},
				{
					question: `¿Cómo transferir mi licencia a un Mac diferente?`,
					answer: `Todo lo que necesita hacer es activar la aplicación en su nuevo Mac con su clave de licencia. La máquina antigua se desactivará automáticamente. Si tiene una licencia para varios Mac, puede administrar los dispositivos activos mediante el <a href="https://licenses.maketheweb.io/" target="_blank" rel="noopener"> Administrador de licencias </a>.`
				},
				{
					question: `¿Cómo agregar más dispositivos a mi licencia?`,
					answer: `Puede agregar más puestos a su licencia mediante el <a href="https://licenses.maketheweb.io/" target="_blank" rel="noopener"> «Administrador de licencias» </a>.`
				},
				{
					question: `¿Es necesario CleanShot para tomar capturas de pantalla?`,
					answer: `No, no es necesario que tengas <a href="{cleanshot_link}" target="_blank">CleanShot</a> para tomar capturas de pantalla con PixelSnap, pero la integración te proporciona todos los beneficios, como la Superposición de acceso rápido o la anotación incorporada. Hay disponible más información sobre todas las características en el <a href="{cleanshot_link}" target="_blank">sitio web de CleanShot</a>.`
				}
			]
		},
		discounts: {
			info: `¿Eres estudiante o usuario/a de CleanShot X?`,
			action: `¡Consigue un descuento!`,
			cleanshot: {
				title: `Para usuarios de <a class="cleanshot-link" href="https://getcleanshot.com?ref=pixelsnap" target="_blank">CleanShot X</a>`,
				description: `¡Ya utilizas <a class="cleanshot-link" href="https://getcleanshot.com?ref=pixelsnap" target="_blank">Cleanshot</a> y ahora es el momento de mejorar tus habilidades de medición! Ofrecemos un <span class="bold">20 % de descuento</span> para los usuarios de <a class="cleanshot-link" href="https://getcleanshot.com?ref=pixelsnap" target="_blank">CleanShot X</a>.`
			},
			student: {
				title: `Descuento estudiantil`,
				description: `Estamos comprometidos a ayudar a los estudiantes y por eso ofrecemos un descuento educativo del 30 %. Contacta con nosotros desde una dirección de correo electrónico emitida por tu institución educativa para obtener un descuento.`
			},
			setapp: {
				title: `Descargar con Setapp`,
				description: `PixelSnap también está disponible con Setapp, el servicio de suscripción para aplicaciones de Mac. ¡Paga una tarifa mensual y consigue acceso a más de 180 aplicaciones!`
			}
		},
		cleanshot: {
			title: `¿Eres usuario/a de CleanShot X?`,
			description: `¡Introduce la dirección de correo electrónico utilizada para comprar <span class="bold">CleanShot X</span> y te daremos un <span class="bold">20 % de descuento</span>!`
		},
		footer: {
			twitter: `Síganos en Twitter`,
			support: `Apoyo`,
			download: `Descargar`,
			seats: `Comprar asientos adicionales`,
			product: `Producto`,
			license: `Licencia`
		}
	},
	ru: {
		title: {
			home: `PixelSnap 2 для Mac`,
			buy: `PixelSnap 2 – Купить`,
			upgrade: `PixelSnap 2 – Обновление с PixelSnap 1`,
			lost: `PixelSnap 2 – Потеряли лицензию?`,
			faq: `PixelSnap 2 – Часто задаваемые вопросы`,
			changelog: `PixelSnap 2 – История изменений`,
			cleanshot: `PixelSnap 2 — скидка для пользователей CleanShot X`,
		},
		meta: [
			{
				name: `description`,
				content: `PixelSnap – это самый быстрый инструмент для измерения чего-либо на вашем экране для MacOS. Измеряйте как профессионалы!`
			},
			{
				property: `og:description`,
				content: `Самый быстрый инструмент для измерения чего-либо на вашем экране.`
			}
		],
		menu: {
			changelog: `История изменений`,
			manager: `Менеджер лицензий`,
			faq: `Часто задаваемые вопросы`,
			contact: `Обратная связь`
		},
		button: {
			menu: `Menu`,
			buy: `Купить`,
			nav_buy: `Купить`,
			buy2: `Купить PixelSnap 2`,
			video: `Смотреть видео`,
			upgrade: `Получить скидку`,
			lost: `Найти лицензию`,
			newsletter: `Подписаться`
		},
		input: {
			email: `Ваш адрес электронной почты`
		},
		header: {
			tagline: `Самый быстрый инструмент для измерения чего бы то ни было на вашем экране`
		},
		moneyback: {
			title: `30-дневная гарантия возврата денег`,
			content: `Для каждой покупки мы предоставляем 30-дневную гарантию возврата денег. Если вы не довольны своей покупкой, просто <a class="link" href="mailto:hello@mtw.team">сообщите нам</a>.`
		},
		introduction: {
			label: `Новая версия. Улучшенный интерфейс.`,
			title: `PixelSnap 2 работает<br/><span class="gradient-fill">в 2 раза быстрее</span>`,
			content: `Новая версия, полностью переписанная с нуля, предлагает Вам действительно волшебные возможности. Измерение ещё никогда не было таким быстрым и приятным!`,
			changes: `Посмотреть 30+ изменений`,
			version1: `Версия 1`,
			version2: `Версия 2`,
			explanation: `Время, необходимое для обнаружения и измерения объекта`
		},
		changesModal: {
			features: {
				title: `Новые возможности`,
				list1: [
					`Привязываемые направляющие`,
					`Удержание расстояния на экране`,
					`Измерение нескольких объектов`,
					`Интеграция с CleanShot`,
					`Интеграция с Adobe XD`,
					`Инструмент скриншотов для съемки скриншотов измерений`,
					`Настройка всех ярлыков`,
					`Добавлено руководство`,
					`Поддержка Touchbar`
				],
				list2: [
					`Выбор объектов без привязки (с перетаскиванием правой кнопкой)`,
					`Перемещение прямоугольника нажатием на пробел`,
					`Поиск размеров нажатием на значок в доке`,
					`Поддержка Mojave`,
					`Уменьшение скриншоты Retina до 1x`,
					`Скрытие значка с панели меню`,
					`Отображение/скрытие единиц`,
					`Отображение/скрытие курсора`,
					`Автоконтраст`
				]
			},
			enhancements: {
				title: `Усовершенствования`,
				list1: [
					`Полностью переписано с нуля`,
					`Измеряет намного быстрее`,
					`Улучшения интерфейса`,
					`Улучшенная интеграция со Sketch`,
					`Улучшенная поддержка Retina`
				],
				list2: [
					`Отмена выбора объекта нажатием «esc»`,
					`Улучшенный процесс активации`,
					`Меньший значок панели меню`
				]
			},
			fixes: {
				title: `Исправлены ошибки`,
				list1: [
					`Исправлена ошибка с большим разрешением экрана`,
					`Исправлена ошибка с отправкой скриншотов через Apple Mail.app`,
					`Исправлена ошибка с перемещением курсора по стрелкам`,
					`Исправлена ошибка с выделением мелких объектов`
				],
				list2: [
					`Исправлена ошибка со скриншотами Retina`,
					`Исправлен размытый курсор на Mojave`,
					`Исправлено перемещение/изменение размера за пределами экрана`,
					`Исправлена утечка памяти`,
					`Исправлены другие мелкие ошибки`
				]
			}
		},
		features: {
			heading: `Работает где угодно. В любом приложении.`,
			description: `PixelSnap работает на всем экране, во всех приложениях – вы сможете <span class="bold">измерить что угодно.</span>`,
			distance: {
				title: `Измеряйте расстояния`,
				description: `Быстро узнайте расстояние между любыми элементами на вашем экране`,
				highlight1: `Удержание результатов измерений на экране`,
				highlight2: `Копирование в буфер обмена`
			},
			objects: {
				title: `Измеряйте объекты`,
				description: `Мгновенно узнайте границы любого объекта, просто выделив его`,
				highlight1: `Волшебная привязка к объекту`,
				highlight2: `Измерение нескольких объектов одновременно`
			},
			guides: {
				title: `Добавляйте привязываемые направляющие`,
				description: `Проверьте выравнивание, добавив горизонтальные и вертикальные направляющие на экране`,
				highlight1: `Отображение расстояния между направляющими`,
				highlight2: `Привязывается к элементам экрана`
			},
			screenshots: {
				title: `Делайте скриншоты`,
				description: `Экспортируйте свои измерения в виде изображения и легко делитесь ими с другими`,
				highlight1: `Сохранение выбранной области`,
				highlight2: `Интеграция с CleanShot`
			},
			cleanshot: {
				title: `Делайте лучшие скриншоты с CleanShot X`,
				description: `Сделайте рабочий процесс намного лучше с помощью самого удобного инструмента для создания скриншотов`,
				more: `Подробнее`
			},
			crosshair: {
				title: `Режим прицела`,
				description: `Быстро проверьте выравнивание всех элементов на вашем экране`
			},
			tolerance: {
				title: `Режим погрешности`,
				description: `Отрегулируйте погрешность для работы с тенями и низкоконтрастными элементами`
			}
		},
		darkFeatures: {
			shortcuts: {
				title: `Сочетания клавиш`,
				description: `Установите глобальную горячую клавишу и настройте все другие сочетания клавиш в соответствии со своими потребностями.`
			},
			appearance: {
				title: `Изменение внешнего вида`,
				description: `Выберите между светлым и темным режимом и отрегулируйте цвета.`
			},
			lightweight: {
				title: `Малый вес`,
				description: `Наше приложение невероятно быстрое и предлагает высокую производительность!`
			},
			retina: {
				title: `Поддержка Retina`,
				description: `Приложение поддерживает Retina и работает с несколькими дисплеями.`
			},
			touchbar: {
				title: `Мы поддерживаем ваш Touch Bar`,
				description: `Доступ к важным действиям — проще простого.`
			}
		},
		integration: {
			title: `Интеграция с вашими любимыми инструментами`,
			description: `PixelSnap показывает реальные размеры независимо от отображаемого уровня масштабирования.`,
			soon: `Скоро`
		},
		testimonials: {
			title: `Отзывы`,
			description: `Наши пользователи любят PixelSnap. Вот что они говорят:`,
			reviews: {
				boag: `Инструмент, который измеряет элементы экрана, кажется слишком базовым и требующим улучшения. Тем не менее, в PixelSnap 2 добавлены функции, которые, как оказались, мне очень нужны. Невероятная помощь в моем рабочем процессе и определенно стоит обновления.`,
				blechinger: `Прекрасный инструмент для быстрого измерения практически всего. Мне особенно нравится функция привязки прямоугольника к объекту на экране. Невероятно удобно!`,
				witkowski: `PixelSnap — недостающий компонент в наборе любого инженера или дизайнера UI. Я пользуюсь им по несколько раз каждый день и не могу представить мой Mac без него. Ускорьте работу с этим высококачественным приложением, вы не пожалеете об этом!`,
				lobanovskiy: `Невероятно умная идея и очень необходимый инструмент в мире дизайнера UI. Честно говоря, это превзошло все мои ожидания, безупречно во всех отношениях.`,
				pandya: `В течение последних 3 лет я работаю в основном над веб-продуктами и возможность надежного измерения объектов на экране является такой недооцененной потребностью. PixelSnap отлично справляется с этой задачей, поэтому оно стало моей любимой утилитой.`,
				smith: `PixelSnap было отличным инструментом для быстрого измерения размера и расстояни при тесном сотрудничестве с командой разработчиков. Мне очень нравится, что приложение позволяет сохранять масштаб при масштабировании объекта в Sketch и захватывать отдельные элементы в скриншот после привязки.`,
				coyier: `PixelSnap — это одно из тех приложений, которое уже после первого использования становится маст-хэвом. Мгновенная покупка для меня.`
			}
		},
		rating: {
			info: `В соответствии с <a class="link" href="https://www.producthunt.com/posts/pixelsnap/reviews" target="_blank">Product Hunt</a> `
		},
		buyWidget: {
			label: `Вознесите измерения на новый уровень!`,
			title: `Получить PixelSnap 2`,
			description: `Не беспокойтесь, мы предоставляем 30-дневную гарантию возврата денег.`
		},
		newsletter: {
			title: `Узнайте первым`,
			description: `Мы будем информировать вас о новых приложениях и предложениях, но без спама, обещаем.`
		},
		buy: {
			title: `Единовременная покупка`,
			description: `macOS {macos_version} или более поздняя версия`,
			tab1: `Купить новую лицензию`,
			tab1_mobile: `Купить`,
			tab2: `Обновление с PixelSnap 1`,
			tab2_mobile: `Обновить`,
			variants: {
				1: `1 Mac`,
				2: `2 Mac`,
				5: `5 Mac`,
				10: `10 Mac`,
				15: `15 Mac`
			},
			save: `экономия {amount}`,
			info: `Загружая или используя приложение, вы соглашаетесь с нашим <a class="link" href="/EULA.pdf" target="_blank">лицензионным соглашением</a>. Цены в долл. США. Может применяться НДС`,
			more_devices: `Нужно больше 10 устройств?`
		},
		upgrade: {
			discount50: `Получи скидку 50%`,
			discount50_description: `при покупке до <span class="bold">1 марта 2019 года</span> `,
			free: `Получите бесплатное обновление`,
			free_description: `при покупке после<span class="bold"> 1 марта 2019 года </span> `
		},
		faq: {
			title: `Часто задаваемые вопросы`,
			questions: [
				{
					question: `Где я могу найти мою лицензию?`,
					answer: `После успешной покупки вы получаете электронное письмо от Paddle с квитанцией и вашим уникальным лицензионным ключом. Обязательно проверьте также папку "Спам". Если вы никак не можете найти его, перейдите в <a href="https://licenses.maketheweb.io/" target="_blank" rel="noopener">Менеджер лицензий</a> и следуйте инструкциям.`
				},
				{
					question: `Где я могу получить счет-фактуру для моей покупки?`,
					answer: `Вы можете самостоятельно сгенерировать счет-фактуру, используя кнопку «Создать» в нижней части электронного письма с квитанцией, которое вы получили после оформления покупки.`
				},
				{
					question: `Как я могу повторно загрузить приложение?`,
					answer: `Просто перейдите на <a href="https://licenses.maketheweb.io/download/pixelsnap2" target="_blank" rel="noopener">страницу загрузки</a> и следуйте инструкциям.`
				},
				{
					question: `Есть ли пробная версия?`,
					answer: `Мы не предоставляем пробную версию, но у нас есть <strong>30-дневная гарантия возврата денег</strong> , поэтому не беспокойтесь и пробуйте наше приложение. Если оно не соответствует вашим ожиданиям, просто <a href="mailto:hello@mtw.team">сообщите нам</a>, и вы получите полный возврат средств.`
				},
				{
					question: `Работает ли PixelSnap с любой дизайнерской программой?`,
					answer: `Да, PixelSnap работает во весь экран во всех приложениях на вашем Mac! Более того, некоторые приложения, такие как <strong>Sketch</strong> или <strong>Adobe XD</strong> , полностью интегрированы с нашим приложением, что означает, что PixelSnap будет показывать реальные размеры независимо от отображаемого уровня масштабирования.`
				},
				{
					question: `Есть ли скидки для студентов?`,
					answer: `Мы стремимся помочь студентам, поэтому предлагаем 30%-ную скидку на обучение. Чтобы получить скидку, просто <a href="mailto:hello@mtw.team">свяжитесь с нами</a> по электронной почте.`
				},
				{
					question: `Есть ли скидка для пользователей PixelSnap 1?`,
					answer: `Клиенты, которые купили предыдущую версию PixelSnap <strong>после 1 марта 2019 года</strong>, имеют право на бесплатное обновление. Если вы сделали покупку до этой даты, вы получите скидку 50%. Вы можете обновить свою лицензию на <a href="{buy_link}">странице покупки</a>.`
				},
				{
					question: `Работает ли PixelSnap в Windows?`,
					answer: `Версии для Windows нет, и в настоящее время мы не планируем ее выпускать.`
				},
				{
					question: `Как перенести мою лицензию на другой Mac?`,
					answer: `Всё, что вам нужно сделать, это активировать приложение на вашем новом Mac с помощью лицензионного ключа. Старый компьютер будет автоматически деактивирован. Если у вас есть лицензия на несколько Mac, вы можете управлять активными устройствами с помощью <a href="https://licenses.maketheweb.io/" target="_blank" rel="noopener">Менеджера лицензий</a>.`
				},
				{
					question: `Как добавить дополнительные устройства в мою лицензию?`,
					answer: `Вы можете добавить дополнительные устройства в лицензию, используя <a href="https://licenses.maketheweb.io/" target="_blank" rel="noopener">Менеджер лицензий</a>.`
				},
				{
					question: `Требуется ли CleanShot для съемки скриншотов?`,
					answer: `Нет, вам не обязательно иметь <a href="{cleanshot_link}" target="_blank">CleanShot</a>, чтобы делать скриншоты с PixelSnap, но интеграция дает вам множество преимуществ, таких как наложение быстрого доступа или встроенные аннотации. Более подробная информация обо всех функциях доступна на <a href="{cleanshot_link}" target="_blank">сайте CleanShot</a>.`
				}
			]
		},
		discounts: {
			info: `Вы студент или пользователь CleanShot X?`,
			action: `Получите скидку!`,
			cleanshot: {
				title: `Для пользователей <a class="cleanshot-link" href="https://getcleanshot.com?ref=pixelsnap" target="_blank">CleanShot X</a>`,
				description: `Вы уже используете <a class="cleanshot-link" href="https://getcleanshot.com?ref=pixelsnap" target="_blank">Cleanshot</a>, но теперь пора улучшить свои навыки измерения! Мы предлагаем <span class="bold">скидку 20%</span> пользователям <a class="cleanshot-link" href="https://getcleanshot.com?ref=pixelsnap" target="_blank">CleanShot X</a>.`
			},
			student: {
				title: `Студенческая скидка`,
				description: `Мы стремимся помочь студентам и именно поэтому предлагаем 30% скидку для учащихся. Для получения этой скидку свяжитесь с нами со своего адреса электронной почты, предоставленного учебным заведением.`
			},
			setapp: {
				title: `Загрузка с помощью Setapp`,
				description: `PixelSnap также доступно в рамках Setapp — сервиса подписки на приложения для Mac. Платите ежемесячную абонентскую плату и получите доступ к более чем 180 приложениям!`
			}
		},
		cleanshot: {
			title: `Вы пользователь CleanShot X?`,
			description: `Введите адрес электронной почты, использованный при покупке <span class="bold">CleanShot X</span>, и мы предоставим вам <span class="bold">скидку 20%</span>!`
		},
		footer: {
			twitter: `Мы в социальных сетях (Twitter)`,
			support: `Поддержка`,
			download: `Скачать`,
			seats: `Купить дополнительные устройства`,
			product: `Продукт`,
			license: `Лицензия`
		}
	},
	it: {
		title: {
			home: `PixelSnap 2 per Mac`,
			buy: `PixelSnap 2 - Acquista`,
			upgrade: `PixelSnap 2 - Aggiornamento da PixelSnap 1`,
			lost: `PixelSnap 2 - Hai perso la licenza?`,
			faq: `PixelSnap 2 - Domande frequenti`,
			changelog: `PixelSnap 2 - Log delle modifiche`,
			cleanshot: `PixelSnap 2 - Sconto per gli utenti di CleanShot X`,
		},
		meta: [
			{
				name: `description`,
				content: `PixelSnap - Lo strumento più veloce per misurare qualsiasi cosa sullo schermo per macOS. Misura tutto ciò che vuoi come un professionista!`
			},
			{
				property: `og:description`,
				content: `Lo strumento più veloce per misurare qualsiasi cosa sullo schermo.`
			}
		],
		menu: {
			changelog: `Changelog`,
			manager: `Gestore di Licenze`,
			faq: `Domande frequenti`,
			contact: `Contattaci`
		},
		button: {
			menu: `Menu`,
			buy: `Acquista ora`,
			nav_buy: `Acquista`,
			buy2: `Acquista PixelSnap 2`,
			video: `Guarda il video`,
			upgrade: `Ottieni uno sconto`,
			lost: `Trova la mia licenza`,
			newsletter: `Iscriviti`
		},
		input: {
			email: `La tua e-mail`
		},
		header: {
			tagline: `Il modo più veloce per misurare ogni elemento sul tuo schermo`
		},
		moneyback: {
			title: `Garanzia di rimborso per i primi 30 giorni`,
			content: `Ogni vendita è supportata da una garanzia di rimborso di 30 giorni. Se per qualche ragione non sei soddisfatto del tuo acquisto, <a class="link" href="mailto:hello@mtw.team">contattaci</a>.`
		},
		introduction: {
			label: `Una nuova versione. Un esperienza ancora migliore.`,
			title: `PixelSnap 2 è fino<br/>a <span class="gradient-fill">2 volte più veloce</span>`,
			content: `Completamente riscritta, la nuova versione offre un'esperienza davvero magica. Misurare oggetti non è mai stato così veloce e divertente!`,
			changes: `Scopri le oltre 30 novità`,
			version1: `Versione 1`,
			version2: `Versione 2`,
			explanation: `Tempo di rilevazione e misurazione di un elemento`
		},
		changesModal: {
			features: {
				title: `Nuove funzioni`,
				list1: [
					`Guide scattabili`,
					`Mantieni la distanza sullo schermo`,
					`Misura oggetti multipli`,
					`Integrazione CleanShot`,
					`Integrazione con Adobe XD`,
					`Strumento per catturare screenshot delle misurazioni`,
					`Personalizza tutte le scorciatoie`,
					`Aggiunto tutorial di introduzione`,
					`Supporto per Touchbar`
				],
				list2: [
					`Seleziona l'oggetto senza scattare (con trascinamento a destra)`,
					`Sposta il rettangolo premendo Spazio`,
					`Trova le dimensioni premendo l'icona nel vassoio`,
					`Supporto per Mojave`,
					`Ridimensiona gli screenshot della retina a 1x`,
					`Nascondi l'icona dalla barra dei menu`,
					`Mostra/Nascondi unità`,
					`Mostra/Nascondi cursore`,
					`Contrasto automatico`
				]
			},
			enhancements: {
				title: `Potenziamenti`,
				list1: [
					`Completamente riscritto da zero`,
					`Misure molto più veloci`,
					`Miglioramenti UI e UX`,
					`Integrazione dello schizzo migliorata`,
					`Supporto retina migliorato`
				],
				list2: [
					`Annulla la selezione di un oggetto premendo Esc`,
					`Processo di attivazione migliorato`,
					`Icona della barra dei menu più piccola`
				]
			},
			fixes: {
				title: `Correzioni di bug`,
				list1: [
					`Bug risolto con risoluzione del grande schermo`,
					`Bug risolto con l'invio di schermate tramite Apple Mail.app`,
					`Bug risolto con cursore mobile tramite frecce`,
					`Bug risolto con selezione di piccoli oggetti`
				],
				list2: [
					`Bug risolto con screenshot retina`,
					`Risolto cursore sfocato su Mojave`,
					`Risolto lo spostamento/ridimensionamento dei limiti dello schermo`,
					`Risolti problemi di memoria`,
					`Altre correzioni di bug minori`
				]
			}
		},
		features: {
			heading: `Funziona ovunque. In ogni app.`,
			description: `PixelSnap funziona su ogni elemento presente sul tuo schermo, in ogni app, e ti permette di <span class="bold">misurare qualsiasi cosa</span>. `,
			distance: {
				title: `Misura la distanza`,
				description: `Ottieni rapidamente la distanza tra tutti gli elementi sullo schermo`,
				highlight1: `Mantieni le misurazioni sullo schermo`,
				highlight2: `Copia negli appunti`
			},
			objects: {
				title: `Misura gli oggetti`,
				description: `Trova al volo i confini di qualsiasi oggetto semplicemente selezionando l'area che lo circonda`,
				highlight1: `Evidenzia magicamente l'oggetto`,
				highlight2: `Misura più oggetti contemporaneamente`
			},
			guides: {
				title: `Aggiungi guide magnetiche`,
				description: `Utilizza guide magnetiche per verificare l'allineamento degli oggetti sullo schermo`,
				highlight1: `Visualizza la distanza tra le guide`,
				highlight2: `Ancora le guide agli elementi sullo schermo`
			},
			screenshots: {
				title: `Cattura screenshot`,
				description: `Esporta le tue misure come un'immagine e collabora facilmente con gli altri`,
				highlight1: `Salva l'area selezionata`,
				highlight2: `Integrato con CleanShot`
			},
			cleanshot: {
				title: `Cattura screenshot migliori con CleanShot X`,
				description: `Potenzia il tuo workflow con lo strumento di screenshot più conveniente`,
				more: `Scopri di più`
			},
			crosshair: {
				title: `Modalità mirino`,
				description: `Controlla velocemente l'allineamento di tutti gli elementi sullo schermo`
			},
			tolerance: {
				title: `Modalità tolleranza`,
				description: `Regola la tolleranza per lavorare con le ombre e gli elementi a basso contrasto`
			}
		},
		darkFeatures: {
			shortcuts: {
				title: `Scelte rapide da tastiera`,
				description: `Imposta una scorciatoia da tastiera e configura tutti gli altri alle tue esigenze.`
			},
			appearance: {
				title: `Modifica l'aspetto`,
				description: `Scegli tra modalità chiara e scura e regola i colori.`
			},
			lightweight: {
				title: `Leggera`,
				description: `La nostra app è incredibilmente veloce e offre prestazioni elevate!`
			},
			retina: {
				title: `Pronto per Retina`,
				description: `L'app supporta Retina e funziona con più display.`
			},
			touchbar: {
				title: `Supportiamo la tua Touch Bar`,
				description: `Effettuare azioni importanti non è mai stato così facile.`
			}
		},
		integration: {
			title: `Si integra con i tuoi strumenti preferiti`,
			description: `PixelSnap mostra le dimensioni reali indipendentemente dal livello di zoom visualizzato.`,
			soon: `Disponibile a breve`
		},
		testimonials: {
			title: `Feedback`,
			description: `I nostri utenti adorano PixelSnap. Ecco cosa stanno dicendo:`,
			reviews: {
				boag: `Uno strumento che misura gli elementi dello schermo sembra troppo semplice per essere migliorato. Tuttavia, PixelSnap 2 aggiunge funzionalità di cui non sapevo nemmeno di aver bisogno. Un incredibile aiuto per il mio workflow e sicuramente vale la pena aggiornarlo.`,
				blechinger: `Strumento meraviglioso per misurare rapidamente qualsiasi cosa. In particolare, adoro la funzionalità che permette di catturare un rettangolo attorno a un oggetto del tuo schermo. Super utile!`,
				witkowski: `PixelSnap è la freccia mancante nella faretra di qualsiasi ingegnere o UI designer. Lo uso più volte al giorno e non riesco a immaginare un Mac senza di esso. Accelera il tuo flusso di lavoro con questa app di alta qualità, non te ne pentirai!`,
				lobanovskiy: `Un'idea super intelligente e uno strumento necessario per un designer di interfacce. Onestamente, ha superato le mie aspettative: è impeccabile sotto ogni aspetto.`,
				pandya: `Ho lavorato su molti prodotti Web negli ultimi tre anni e misurare in modo affidabile gli oggetti sullo schermo è un'esigenza spesso sottovalutata. PixelSnap lo fa così bene ed è ora la mia app preferita per questo tipo di compiti.`,
				smith: `PixelSnap è un ottimo strumento per individuare rapidamente dimensioni e spazio quando si lavora a stretto contatto con un team di sviluppo. Apprezzo molto la sua capacità di mantenere le proporzioni durante lo zoom su un oggetto in Sketch e di essere in grado di catturare singoli elementi in uno screenshot dopo aver catturato.`,
				coyier: `PixelSnap è una di quelle app che rivoluzionano completamente il tuo modo di lavorare: è la miglior idea di sempre. Acquistalo immediatamente.`
			}
		},
		rating: {
			info: `Secondo <a class="link" href="https://www.producthunt.com/posts/pixelsnap/reviews" target="_blank">Product Hunt</a> `
		},
		buyWidget: {
			label: `Misura qualsiasi cosa come non hai mai fatto prima!`,
			title: `Ottieni PixelSnap 2`,
			description: `Non esitare, abbiamo una garanzia di rimborso di 30 giorni.`
		},
		newsletter: {
			title: `Resta sul pezzo`,
			description: `Ti informeremo su nuove app e offerte, ma ti promettiamo che non invieremo spam.`
		},
		buy: {
			title: `Acquisto una tantum`,
			description: `È richiesta la versione {macos_version} di macOS o successive`,
			tab1: `Acquista una nuova Licenza`,
			tab1_mobile: `Acquista`,
			tab2: `Aggiornamento da PixelSnap 1`,
			tab2_mobile: `Aggiorna`,
			variants: {
				1: `1 Mac`,
				2: `2 Mac`,
				5: `5 Mac`,
				10: `10 Mac`,
				15: `15 Mac`
			},
			save: `risparmia {amount}`,
			info: `Scaricando o utilizzando l'app, accetti il nostro <a class="link" href="/EULA.pdf" target="_blank">EULA</a>. Prezzi in USD. È possibile che si applichi l'IVA.`,
			more_devices: `Hai bisogno di più di 10 dispositivi?`
		},
		upgrade: {
			discount50: `Ottieni uno sconto del 50%`,
			discount50_description: `se effettui l'acquisto <span class="bold">prima del 1° marzo 2019</span> `,
			free: `Ottieni un aggiornamento gratuito`,
			free_description: `se effettui l'acquisto dopo il <span class="bold">1° marzo 2019</span> `
		},
		faq: {
			title: `Domande Frequenti`,
			questions: [
				{
					question: `Dove posso trovare la mia licenza?`,
					answer: `Dopo un acquisto riuscito, ricevi un&#x27;email da Paddle con la ricevuta e la tua chiave di licenza univoca. Assicurati di controllare anche la cartella Spam. Se ancora non riesci a trovarla, vai su <a href="https://licenses.maketheweb.io/" target="_blank" rel="noopener"> Gestore di Licenze </a> e segui le istruzioni.`
				},
				{
					question: `Dove posso ottenere una fattura per il mio acquisto?`,
					answer: `Puoi generare una fattura tu stesso utilizzando il pulsante <strong>Genera</strong> nella parte inferiore della tua e-mail con la ricevuta che ricevi dopo un acquisto andato a buon fine.`
				},
				{
					question: `Come posso riscaricare l'app?`,
					answer: `Vai alla <a href="https://licenses.maketheweb.io/download/pixelsnap2" target="_blank" rel="noopener"> pagina di download </a> e segui le istruzioni.`
				},
				{
					question: `Esiste una versione di prova?`,
					answer: `Non forniamo una versione di prova, ma abbiamo una <strong>garanzia di rimborso di 30 giorni</strong>, quindi sentiti libero di provare l'app. Se non soddisfa le tue aspettative, <a href="mailto:hello@mtw.team">faccelo sapere</a> e otterrai un rimborso completo.`
				},
				{
					question: `PixelSnap funziona con tutti i software di progettazione?`,
					answer: `Sì, PixelSnap funziona su tutto lo schermo, su tutte le app del tuo Mac! Inoltre, alcune app come <strong>Sketch</strong> o <strong>Adobe XD</strong> sono completamente integrate con la nostra app, il che significa che PixelSnap mostrerà dimensioni reali indipendentemente dal livello di zoom visualizzato.`
				},
				{
					question: `Fornite uno sconto per studenti?`,
					answer: `Ci impegniamo ad aiutare gli studenti ed è per questo che offriamo uno sconto del 30%. Per ottenere lo sconto, <a href="mailto:hello@mtw.team">contattaci</a> dal tuo indirizzo e-mail studente.`
				},
				{
					question: `Gli utenti di PixelSnap 1 ottengono uno sconto?`,
					answer: `I clienti che hanno acquistato la versione precedente di PixelSnap <strong>dopo il 1° marzo 2019</strong> possono aggiornare l'app gratuitamente. Se hai effettuato l'acquisto prima di questa data, riceverai uno sconto del 50%. Puoi aggiornare la tua licenza nella <a href="{buy_link}">pagina Acquista</a>.`
				},
				{
					question: `PixelSnap funziona su Windows?`,
					answer: `Non esiste una versione per Windows e al momento non è previsto il rilascio di tale versione.`
				},
				{
					question: `Come posso trasferire la mia licenza su un altro Mac?`,
					answer: `Tutto quello che devi fare è attivare l&#x27;app sul tuo nuovo Mac con la tua chiave di licenza. Il tuo vecchio computer verrà automaticamente disattivato. Se disponi di una licenza per più Mac, puoi gestire i dispositivi attivi utilizzando <a href="https://licenses.maketheweb.io/" target="_blank" rel="noopener"> Gestore di Licenze </a>.`
				},
				{
					question: `Come aggiungere più dispositivi alla mia licenza?`,
					answer: `Puoi aggiungere più postazioni alla tua licenza utilizzando <a href="https://licenses.maketheweb.io/" target="_blank" rel="noopener"> Gestore di Licenze</a>.`
				},
				{
					question: `È necessario CleanShot per catturare screenshot?`,
					answer: `No, non è necessario disporre di <a href="{cleanshot_link}" target="_blank">CleanShot</a> per acquisire screenshot con PixelSnap, ma l'integrazione offre numerosi vantaggi come la sovrapposizione di accesso rapido o l'annotazione integrata. Maggiori informazioni su tutte le funzionalità sono disponibili sul <a href="{cleanshot_link}" target="_blank">sito web di CleanShot</a>.`
				}
			]
		},
		discounts: {
			info: `Sei uno studente o un utente di CleanShot X?`,
			action: `Approfitta dello sconto!`,
			cleanshot: {
				title: `Per gli utenti di <a class="cleanshot-link" href="https://getcleanshot.com?ref=pixelsnap" target="_blank">CleanShot X</a>`,
				description: `Sei già un utente di <a class="cleanshot-link" href="https://getcleanshot.com?ref=pixelsnap" target="_blank">Cleanshot</a> e adesso è il momento di aggiornare le tue capacità di misurazione alla versione successiva! Offriamo uno <span class="bold">sconto del 20%</span> agli utenti di <a class="cleanshot-link" href="https://getcleanshot.com?ref=pixelsnap" target="_blank">CleanShot X</a>.`
			},
			student: {
				title: `Sconto per gli studenti`,
				description: `Ci impegniamo ad aiutare gli studenti, ed è per questo che offriamo uno sconto del 30% per l'uso didattico. Contattaci da un indirizzo e-mail emesso da un ente scolastico o accademico per ottenere uno sconto.`
			},
			setapp: {
				title: `Scarica con Setapp`,
				description: `PixelSnap è disponibile anche con Setapp, il servizio di abbonamento di app per Mac. Paga una tariffa mensile e accedi a oltre 180 app!`
			}
		},
		cleanshot: {
			title: `Sei un utente di CleanShot X?`,
			description: `Inserisci l'indirizzo e-mail che hai utilizzato per acquistare <span class="bold">CleanShot X</span> e ti offriremo uno <span class="bold">sconto del 20%</span>!`
		},
		footer: {
			twitter: `Seguici su Twitter`,
			support: `Assistenza`,
			download: `Scarica`,
			seats: `Acquista postazioni aggiuntive`,
			product: `Prodotto`,
			license: `Licenza`
		}
	},
	fr: {
		title: {
			home: `PixelSnap 2 pour Mac`,
			buy: `PixelSnap 2 – Acheter`,
			upgrade: `PixelSnap 2 – Mise à niveau à partir de PixelSnap 1`,
			lost: `PixelSnap 2 – Licence perdue ?`,
			faq: `PixelSnap 2 – Foire aux questions`,
			changelog: `PixelSnap 2 – Journal des modifications`,
			cleanshot: `PixelSnap 2 – remise pour les utilisateurs de CleanShot X`,
		},
		meta: [
			{
				name: `description`,
				content: `PixelSnap : l’outil le plus rapide pour mesurer quoi que ce soit sur votre écran pour macOS. Mesurez comme un PRO !`
			},
			{
				property: `og:description`,
				content: `L’outil le plus rapide pour mesurer quoi que ce soit sur votre écran.`
			}
		],
		menu: {
			changelog: `Journal de modifications`,
			manager: `Gestionnaire de licence`,
			faq: `FAQ`,
			contact: `Nous contacter`
		},
		button: {
			menu: `Menu`,
			buy: `Acheter maintenant`,
			nav_buy: `Acheter`,
			buy2: `Acheter PixelSnap 2`,
			video: `Voir la vidéo`,
			upgrade: `Obtenir une réduction`,
			lost: `Trouver ma licence`,
			newsletter: `S’abonner`
		},
		input: {
			email: `Votre e-mail`
		},
		header: {
			tagline: `L’outil le plus rapide pour mesurer quoi que ce soit sur votre écran`
		},
		moneyback: {
			title: `Garantie de remboursement de 30 jours`,
			content: `Nous couvrons chaque vente avec une garantie de remboursement de 30 jours. Si vous n’êtes pas satisfait de votre achat, <a class="link" href="mailto:hello@mtw.team">faites-le nous savoir</a>.`
		},
		introduction: {
			label: `Nouvelle version. Meilleure expérience.`,
			title: `PixelSnap 2 est<br/> jusqu’à <span class="gradient-fill">2 fois plus rapide</span>`,
			content: `Entièrement réécrite de zéro, la nouvelle version offre une expérience véritablement magique. Mesurer des choses n’a jamais été aussi rapide et agréable !`,
			changes: `Découvrez plus de 30 changements`,
			version1: `Version 1`,
			version2: `Version 2`,
			explanation: `Temps nécessaire pour détecter et mesurer un objet`
		},
		changesModal: {
			features: {
				title: `Nouvelles fonctionnalités`,
				list1: [
					`Guides saisissables`,
					`Maintenir la distance sur l’écran`,
					`Mesure d’objets multiples`,
					`Intégration CleanShot`,
					`Intégration Adobe XD`,
					`Outil de capture d’écran pour prendre des captures d’écran des mesures`,
					`Personnaliser tous les raccourcis`,
					`Ajout du didacticiel d’intégration`,
					`Support de la Touch Bar`
				],
				list2: [
					`Sélectionner un objet sans accroche (avec glisser droit)`,
					`Déplacer un rectangle en appuyant sur espace`,
					`Rechercher des dimensions en appuyant sur l’icône du dock`,
					`Prise en charge de Mojave`,
					`Réduire les captures d’écran Retina à 1x`,
					`Masquer l’icône de la barre de menu`,
					`Afficher/masquer les unités`,
					`Afficher/masquer le curseur`,
					`Contraste automatique`
				]
			},
			enhancements: {
				title: `Améliorations`,
				list1: [
					`Complètement réécrite à partir de zéro`,
					`Des mesures beaucoup plus rapides`,
					`Améliorations de l’interface et de l’expérience utilisateur`,
					`Intégration Sketch améliorée`,
					`Prise en charge Retina améliorée`
				],
				list2: [
					`Annuler la sélection d’un objet en appuyant sur esc`,
					`Processus d’activation amélioré`,
					`Icône de la barre de menu plus petite`
				]
			},
			fixes: {
				title: `Correction de bogues`,
				list1: [
					`Correction d’un bogue avec une grande résolution d’écran`,
					`Correction d’un bogue avec l’envoi de captures d’écran via Apple Mail.app`,
					`Correction d’un bogue avec le déplacement du curseur par les flèches`,
					`Correction d’un bogue lors de la sélection de petits objets`
				],
				list2: [
					`Correction d’un bogue avec les captures d’écran Retina`,
					`Correction du curseur flou sur Mojave`,
					`Correction du déplacement/redimensionnement en dehors des limites de l’écran`,
					`Correction de fuites de mémoire`,
					`Autres corrections de bogues mineurs`
				]
			}
		},
		features: {
			heading: `Fonctionne partout. Dans chaque application.`,
			description: `PixelSnap fonctionne sur tout l’écran, dans toutes les applications, et vous pouvez <span class="bold">tout mesurer.</span> `,
			distance: {
				title: `Mesurer une distance`,
				description: `Obtenez rapidement la distance entre tous les éléments sur votre écran`,
				highlight1: `Conservez les dimensions à l’écran`,
				highlight2: `Copiez dans le presse-papiers`
			},
			objects: {
				title: `Mesurez des objets`,
				description: `Trouvez instantanément les bords d’un objet en faisant simplement glisser une zone autour de lui`,
				highlight1: `Se fixe comme par magie à l’objet`,
				highlight2: `Mesurez plusieurs objets à la fois`
			},
			guides: {
				title: `Ajoutez des guides saisissables`,
				description: `Vérifiez l’alignement en ajoutant des guides horizontaux et verticaux à l’écran`,
				highlight1: `Affiche la distance entre les guides`,
				highlight2: `S’accroche aux éléments de l’écran`
			},
			screenshots: {
				title: `Prenez des captures d’écran`,
				description: `Exportez vos mesures sous forme d’image et collaborez facilement avec d’autres`,
				highlight1: `Enregistrez la zone sélectionnée`,
				highlight2: `Intégré à CleanShot`
			},
			cleanshot: {
				title: `Prenez de meilleures captures d’écran avec CleanShot X`,
				description: `Boostez votre flux de travail avec l’outil de capture d’écran le plus pratique.`,
				more: `En savoir plus`
			},
			crosshair: {
				title: `Mode réticule`,
				description: `Vérifiez instantanément l’alignement de tous les éléments sur votre écran`
			},
			tolerance: {
				title: `Mode tolérance`,
				description: `Ajustez la tolérance pour travailler avec des ombres et des éléments à faible contraste`
			}
		},
		darkFeatures: {
			shortcuts: {
				title: `Raccourcis clavier`,
				description: `Définissez un raccourci clavier global et ajustez tous les autres raccourcis à vos besoins.`
			},
			appearance: {
				title: `Changez l’apparence`,
				description: `Choisissez entre les modes clair et sombre et ajustez les couleurs.`
			},
			lightweight: {
				title: `Ultra léger`,
				description: `Notre application est incroyablement rapide et offre des performances élevées !`
			},
			retina: {
				title: `Optimisé pour Retina`,
				description: `L’application prend en charge Retina et fonctionne avec plusieurs écrans.`
			},
			touchbar: {
				title: `Nous prenons en charge votre Touch Bar`,
				description: `Accéder à des actions cruciales n’a jamais été aussi simple.`
			}
		},
		integration: {
			title: `S’intègre avec vos outils préférés`,
			description: `PixelSnap affiche les dimensions réelles quel que soit le niveau de zoom affiché.`,
			soon: `Bientôt disponible`
		},
		testimonials: {
			title: `Commentaires`,
			description: `Nos utilisateurs aiment PixelSnap. Voici ce qu’ils disent :`,
			reviews: {
				boag: `Un outil qui mesure les éléments de l’écran semble trop fondamental pour être amélioré. Cependant, PixelSnap 2 ajoute des fonctionnalités auxquelles je ne pensais même pas avoir besoin.
 Une aide incroyable à mon flux de travail et qui vaut vraiment la mise à niveau.`,
				blechinger: `Un outil formidable pour mesurer rapidement à peu près n’importe quoi. J’aime particulièrement la fonctionnalité permettant d’accrocher un rectangle autour d’un objet de votre écran. Super pratique !`,
				witkowski: `PixelSnap est l’ingrédient manquant dans la boîte à outils de tout ingénieur ou concepteur d’interface utilisateur. Je l’utilise plusieurs fois par jour et je ne peux pas imaginer un Mac sans elle. Accélérez votre flux de travail avec cette application de haute qualité, vous ne le regretterez pas !`,
				lobanovskiy: `Super idée géniale et outil indispensable dans le monde du concepteur d’interface utilisateur. Honnêtement, cela a dépassé mes attentes, sans faille à tous points de vue.`,
				pandya: `Je travaille principalement sur des produits Web depuis 3 ans, où pouvoir mesurer de manière fiable des éléments à l’écran est un besoin tellement sous-estimé. PixelSnap le fait si bien et est maintenant mon utilitaire minimaliste préféré.`,
				smith: `PixelSnap a été un excellent outil pour repérer rapidement la taille et l’espace lorsque vous travaillez en étroite collaboration avec une équipe de développement. J’apprécie vraiment sa capacité à maintenir l’échelle lorsque vous zoomez sur un objet dans Sketch et à pouvoir capturer des éléments individuels dans une capture d’écran après la capture.`,
				coyier: `PixelSnap est une de ces applications qui vous fait vous exclamer, une fois que vous la voyez : « c’est la meilleure idée jamais vue ! ». Achat instantané pour moi.`
			}
		},
		rating: {
			info: `Selon <a class="link" href="https://www.producthunt.com/posts/pixelsnap/reviews" target="_blank">Product Hunt</a> `
		},
		buyWidget: {
			label: `Fait passer la mesure à un niveau supérieur !`,
			title: `Obtenez PixelSnap 2`,
			description: `N’hésitez pas, nous avons une garantie de remboursement de 30 jours.`
		},
		newsletter: {
			title: `Soyez le premier à être averti`,
			description: `Nous vous informerons sur les nouvelles applications et les offres, mais pas de spam, c’est promis.`
		},
		buy: {
			title: `Achat ponctuel`,
			description: `macOS {macos_version} ou ultérieure requise`,
			tab1: `Acheter une autre Licence`,
			tab1_mobile: `Acheter`,
			tab2: `Mettre à niveau à partir de PixelSnap 1`,
			tab2_mobile: `Mettre à jour`,
			variants: {
				1: `1 Mac`,
				2: `2 Mac`,
				5: `5 Macs`,
				10: `10 Macs`,
				15: `15 Macs`
			},
			save: `économisez {amount}`,
			info: `En téléchargeant ou en utilisant l’application, vous acceptez notre <a class="link" href="/EULA.pdf" target="_blank">CLUF</a>. Prix en dollars américains. La TVA peut être appliquée.`,
			more_devices: `Besoin de plus de 10 appareils ?`
		},
		upgrade: {
			discount50: `Obtenez 50 % de réduction`,
			discount50_description: `si vous avez acheté avant le <span class="bold">1er mars 2019</span> `,
			free: `Obtenez une mise à jour gratuite`,
			free_description: `si vous avez acheté après le <span class="bold">1er mars 2019</span> `
		},
		faq: {
			title: `Foire aux questions`,
			questions: [
				{
					question: `Où puis-je trouver ma licence ?`,
					answer: `Après un achat réussi, vous recevez un e-mail de Paddle avec le reçu et votre clé de licence unique. Assurez-vous de vérifier également votre dossier Spam. Si vous ne le trouvez toujours pas, accédez au <a href="https://licenses.maketheweb.io/" target="_blank" rel="noopener"> Gestionnaire de licences </a> et suivez les instructions.`
				},
				{
					question: `Où obtenir la facture de mon achat ?`,
					answer: `Vous pouvez générer une facture vous-même à l’aide du bouton <strong>Générer</strong> situé au bas de votre e-mail, accompagné du reçu que vous recevez après un achat réussi.`
				},
				{
					question: `Comment puis-je télécharger à nouveau l’application ?`,
					answer: `Accédez simplement à la <a href="https://licenses.maketheweb.io/download/pixelsnap2" target="_blank" rel="noopener"> page de téléchargement </a> et suivez les instructions.`
				},
				{
					question: `Existe-t-il une version d’essai ?`,
					answer: `Nous ne fournissons pas d’essai, mais nous avons une <strong>garantie de remboursement de 30 jours</strong> , alors n’hésitez pas à essayer l’application. Si elle ne répond pas à vos attentes, <a href="mailto:hello@mtw.team">faites-le nous savoir</a> et vous obtiendrez un remboursement complet.`
				},
				{
					question: `PixelSnap fonctionne-t-il avec tous les logiciels de conception ?`,
					answer: `Oui, PixelSnap fonctionne sur tout l’écran, dans toutes les applications de votre Mac ! De plus, certaines applications telles que <strong>Sketch</strong> ou <strong>Adobe XD</strong> sont entièrement intégrées à notre application, ce qui signifie que PixelSnap affichera les dimensions réelles quel que soit le niveau de zoom affiché.`
				},
				{
					question: `Existe-t-il un tarif étudiant ?`,
					answer: `Nous nous sommes engagés à aider les étudiants et c’est pourquoi nous offrons une réduction de 30 % pour l’éducation. Pour bénéficier de cette réduction, il vous suffit de <a href="mailto:hello@mtw.team">nous contacter</a> à partir de votre e-mail en .edu.`
				},
				{
					question: `Existe-t-il une réduction pour les utilisateurs de PixelSnap 1 ?`,
					answer: `Les clients ayant acheté la version précédente de PixelSnap <strong>après le 1er mars 2019</strong> ont droit à une mise à jour gratuite. Si vous avez effectué votre achat avant cette date, vous bénéficierez d’une réduction de 50 %. Vous pouvez mettre à niveau votre licence sur la <a href="{buy_link}">page Acheter</a>.`
				},
				{
					question: `PixelSnap fonctionne-t-il sur Windows ?`,
					answer: `Il n’existe pas de version Windows et pour le moment, il n’est pas prévu d’en publier une.`
				},
				{
					question: `Comment transférer ma licence sur un autre Mac ?`,
					answer: `Tout ce que vous avez à faire c’est d’activer l’application sur votre nouveau Mac avec votre clé de licence. L’ancienne machine sera automatiquement désactivée. Si vous disposez d’une licence pour plusieurs Mac, vous pouvez gérer les appareils actifs à l’aide du <a href="https://licenses.maketheweb.io/" target="_blank" rel="noopener"> gestionnaire de licences </a>.`
				},
				{
					question: `Comment ajouter plus d’appareils à ma licence ?`,
					answer: `Vous pouvez ajouter d’autres postes à votre licence à l’aide du <a href="https://licenses.maketheweb.io/" target="_blank" rel="noopener"> gestionnaire de licences </a>.`
				},
				{
					question: `CleanShot est-il nécessaire pour prendre des captures d’écran ?`,
					answer: `Non, vous n’avez pas besoin de <a href="{cleanshot_link}" target="_blank">CleanShot</a> pour effectuer des captures d’écran avec PixelSnap, mais l’intégration vous offre tous les avantages,  tels que l’accès rapide en superposition ou l’annotation intégrée. Plus d’informations sur toutes les fonctionnalités sont disponibles sur le <a href="{cleanshot_link}" target="_blank">site Web de CleanShot</a>.`
				}
			]
		},
		discounts: {
			info: `Êtes-vous étudiant ou utilisateur de CleanShot X ?`,
			action: `Obtenez une réduction !`,
			cleanshot: {
				title: `Pour les utilisateurs de <a class="cleanshot-link" href="https://getcleanshot.com?ref=pixelsnap" target="_blank">CleanShot X</a>`,
				description: `Vous utilisez déjà <a class="cleanshot-link" href="https://getcleanshot.com?ref=pixelsnap" target="_blank">Cleanshot</a>, maintenant il est temps d'améliorer aussi vos possibilités de mesure ! Nous offrons une <span class="bold">réduction de 20 %</span> aux utilisateurs de <a class="cleanshot-link" href="https://getcleanshot.com?ref=pixelsnap" target="_blank">CleanShot X</a>.`
			},
			student: {
				title: `Réduction pour les étudiants`,
				description: `Nous nous engageons à aider les étudiants, c'est pourquoi nous leur offrons une réduction de 30 %. Contactez-nous depuis votre adresse e-mail de votre établissement afin d'obtenir une remise.`
			},
			setapp: {
				title: `Télécharger avec Setapp`,
				description: `PixelSnap est également disponible avec Setapp, le service d'abonnement pour les applications Mac. Payez une fois par mois et accédez à plus de 180 applications !`
			}
		},
		cleanshot: {
			title: `Vous utilisez CleanShot X ?`,
			description: `Entrez l'adresse e-mail que vous avez utilisée pour acheter <span class="bold">CleanShot X</span> et nous vous offrirons une <span class="bold">réduction de 20 %</span> !`
		},
		footer: {
			twitter: `Suivez-nous sur Twitter`,
			support: `Assistance`,
			download: `Télécharger`,
			seats: `Acheter des postes supplémentaires`,
			product: `Produit`,
			license: `Licence`
		}
	}
}
