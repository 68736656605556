<template>
	<div class="modal">
		<div class="discounts" v-if="!applyDiscount">{{ $t('discounts.info') }} <span class="link" @click="discountClick">{{ $t('discounts.action') }}</span></div>
		<div class="deal" :class="{ '-countdown': showCountdown }" v-if="discountBanner"><span class="bold -countdown" v-if="showCountdown">{{ countdown }}</span>Black Friday is here! Enjoy <span class="bold">30% discount</span>!</div>
		<div class="variants">
			<div class="variant" v-for="variant in variants" :key="variant.name" :class="{ '-active': variant.active }" @click="makeActive(variant)">
				<div class="name">{{ variant.name }}</div>
				<div class="price">${{ variant.price }}</div>
				<div class="save" :class="{ '-discount': applyDiscount }">{{ variant.save }}</div>
			</div>
			<div class="variant -inactive -more-devices">
				<div class="name -more-devices">{{ $t('buy.more_devices') }}</div>
				<a class="link -more-devices" href="mailto:hello@mtw.team?subject=PixelSnap 2 bulk license">{{ $t('menu.contact') }}</a>
			</div>
		</div>
		<div class="variants-select-wraper">
			<div class="variants-select-label">
				<span class="mobile-name">{{ activeVariant.name }}</span>
				<span class="mobile-save" :class="[ { '-discount': applyDiscount }, langClass ]" v-if="activeVariant.save !== '-'">({{activeVariant.save }})</span>
				<span class="mobile-price">${{ activeVariant.price }}</span>
				<img src="../assets/img/arrow.svg" alt="">
			</div>
			<select class="variants-select" @change="selectActive($event.target.value)">
				<option v-for="variant in mobileVariants" :key="variant.name" :value="variant.name" :selected="variant.active">{{ variant.display }}</option>
			</select>
		</div>
		<ActionButton class="buy" size="large" :label="$t('button.buy2')" @click="openCheckout" />
		<p class="info" v-html="$t('buy.info')"></p>
	</div>
</template>

<script>
import ActionButton from './ActionButton.vue'
import { showDiscount, discountEndDate } from '../helpers/discount'

export default {
	name: 'BuyModal',
	metaInfo() {
		return this.localizedMeta('/buy', 'buy')
	},
	components: {
		ActionButton
	},
	data() {
		return {
			showCountdown: true,
			countdown: '',
			countDownDate: discountEndDate,
			countdownInterval: null,
			discountCoupon: this.$route.query.coupon,
			discountType: this.$route.query.type,
			discountAmount: parseInt(this.$route.query.amount),
			applyDiscount: this.discountType !== undefined,
			discountBanner: false,
			variants: [
				{
					count: 1,
					name: this.$t('buy.variants.1'),
					price: 39,
					save: '-',
					active: true,
					paddleUrl: 'https://create-checkout.paddle.com/checkout/custom/eyJ0IjoiUGl4ZWxTbmFwIDIiLCJpIjoiaHR0cHM6XC9cL3BhZGRsZS5zMy5hbWF6b25hd3MuY29tXC91c2VyXC80MDg4M1wvd00xVjNPeFJUNE9wUHFrUXRqRmZfcUdDR1hQM1NUeXZBVzRKcVBhZlNfQXBwaWNvbi5wbmciLCJyIjpudWxsLCJjbSI6IiIsInJlIjoxLCJwIjo2MzkzMzgsImNjIjp7IlVTRCI6IjM5In0sInkiOiIiLCJxIjowLCJxMiI6IjEiLCJkIjoxLCJhIjpbXSwidiI6IjQwODgzIiwicyI6ImViMzYyOGE3MzJmY2Y3OTkwMzg5MmIxMzFlZjQ0Y2EwMTEzNWE4NTFkODBkNjlhYzY1NzQyZTBkZWNhZWI4NWRhYThkMmI5MjdkZTJkYTY2ZjI0ZTY2MGI2MDUxOTRhNzE1MjlkMWIzMTFmNTg1YTI5NThlYzA4YTgzM2RjNjM5In0='
				},
				{
					count: 2,
					name: this.$t('buy.variants.2'),
					price: 69,
					save: this.$t('buy.save', { amount: '$9' }),
					active: false,
					paddleUrl: 'https://create-checkout.paddle.com/checkout/custom/eyJ0IjoiUGl4ZWxTbmFwIDIiLCJpIjoiaHR0cHM6XC9cL3BhZGRsZS5zMy5hbWF6b25hd3MuY29tXC91c2VyXC80MDg4M1wvd00xVjNPeFJUNE9wUHFrUXRqRmZfcUdDR1hQM1NUeXZBVzRKcVBhZlNfQXBwaWNvbi5wbmciLCJyIjpudWxsLCJjbSI6IiIsInJlIjoxLCJwIjo2MzkzMzgsImNjIjp7IlVTRCI6IjM0LjUifSwieSI6IiIsInEiOjAsInEyIjoiMiIsImQiOjEsImEiOltdLCJ2IjoiNDA4ODMiLCJzIjoiODFmNjY1MGNiZTYwOGI5YmZhODU4MjgxZWNlMDc4YTllODRkY2FlM2Q3NzJmYzk2Y2ZhMGQxMWZiZjZlMDZjZjgyNTRkYzNiM2ZjMDhhOGYyM2QyYjZlMjUxMzU4MzhiN2ExZjdhMzY5YTkwMzljYWI4NzE4MDRhNGIxNTRiZDMifQ=='
				},
				{
					count: 5,
					name: this.$t('buy.variants.5'),
					price: 159,
					save: this.$t('buy.save', { amount: '$36' }),
					active: false,
					paddleUrl: 'https://create-checkout.paddle.com/checkout/custom/eyJ0IjoiUGl4ZWxTbmFwIDIiLCJpIjoiaHR0cHM6XC9cL3BhZGRsZS5zMy5hbWF6b25hd3MuY29tXC91c2VyXC80MDg4M1wvd00xVjNPeFJUNE9wUHFrUXRqRmZfcUdDR1hQM1NUeXZBVzRKcVBhZlNfQXBwaWNvbi5wbmciLCJyIjpudWxsLCJjbSI6IiIsInJlIjoxLCJwIjo2MzkzMzgsImNjIjp7IlVTRCI6IjMxLjgifSwieSI6IiIsInEiOjAsInEyIjoiNSIsImQiOjEsImEiOltdLCJ2IjoiNDA4ODMiLCJzIjoiMjUxMWViZmUxZjE4NDg1NGQxNzM4MWVhNjQ3NDVhNjVmMDQwZjBiMmY3OGY3ZGY5YTNmZDg0NTIyY2Q4NjJkZTYzOTVhMTU0ZTU1YWU3Y2VkZTlhOTFlZjFiMzU4YjQzMjMyZjJlNTE4Mjk0NzlhZjk2NmQ2MzQwNGZmNDIwYWIifQ=='
				},
				{
					count: 10,
					name: this.$t('buy.variants.10'),
					price: 309,
					save: this.$t('buy.save', { amount: '$81' }),
					active: false,
					paddleUrl: 'https://create-checkout.paddle.com/checkout/custom/eyJ0IjoiUGl4ZWxTbmFwIDIiLCJpIjoiaHR0cHM6XC9cL3BhZGRsZS5zMy5hbWF6b25hd3MuY29tXC91c2VyXC80MDg4M1wvd00xVjNPeFJUNE9wUHFrUXRqRmZfcUdDR1hQM1NUeXZBVzRKcVBhZlNfQXBwaWNvbi5wbmciLCJyIjpudWxsLCJjbSI6IiIsInJlIjoxLCJwIjo2MzkzMzgsImNjIjp7IlVTRCI6IjMwLjkifSwieSI6IiIsInEiOjAsInEyIjoiMTAiLCJkIjoxLCJhIjpbXSwidiI6IjQwODgzIiwicyI6IjNhYzQyZWQ3Y2RmMzQzZTNhMjlmZjA4ZTU5YmVjZTVlMjYyYmIwODZhZTM5ZDM1ZDE4MWZmMjNhMmUyZjljNDdjNzhmM2IzOWI4NzU5YWIxYzhkOWRlMTk3OGM1YTdmODVhOWI3YTU1ODRiNmIzNGE4MThhOTQxNzM5NTg0MTExIn0='
				}
			]
		}
	},
	computed: {
		mobileVariants() {
			return this.variants.map(variant => {
				variant.display = `${variant.name} - $${variant.price}`
				return variant
			})
		},
		activeVariant() {
			return this.variants.find(variant => variant.active)
		}
	},
	methods: {
		discountClick() {
			document.querySelector('#discounts').scrollIntoView({ behavior: 'smooth' })
		},
		makeActive(variant) {
			this.variants.forEach(element => {
				element.active = false
			})
			variant.active = true
		},
		selectActive(name) {
			const variant = this.variants.find(variant => variant.name === name)
			this.makeActive(variant)
		},
		openCheckout() {
			const ref = this.getCookie('ref')
			let passthrough = {
				lang: this.$i18n.locale
			}
			if (ref !== null) {
				passthrough.ref = ref
			}
			window.Paddle.Checkout.open({
				override: this.activeVariant.paddleUrl,
				passthrough: JSON.stringify(passthrough),
				coupon: this.discountCoupon
			})
		},
		getCookie(cname) {
			var name = cname + '='
			var decodedCookie = decodeURIComponent(document.cookie)
			var ca = decodedCookie.split(';')
			for (var i = 0; i < ca.length; i++) {
				var c = ca[i]
				while (c.charAt(0) === ' ') {
					c = c.substring(1)
				}
				if (c.indexOf(name) === 0) {
					return c.substring(name.length, c.length)
				}
			}
			return null
		},
		pad(number, size) {
			let s = '000000000' + number
			return s.substr(s.length - size)
		},
		updateCountdown() {
			const now = new Date().getTime()
			const distance = this.countDownDate - now
			const days = Math.floor(distance / (1000 * 60 * 60 * 24))
			const hours = this.pad(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)), 2)
			const minutes = this.pad(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)), 2)
			const seconds = this.pad(Math.floor((distance % (1000 * 60)) / 1000), 2)
			if (days > 0) {
				this.countdown = `${days}d, ${hours}:${minutes}:${seconds}`
			} else {
				this.countdown = `${hours}:${minutes}:${seconds}`
			}
		},
		calculateDiscount() {
			if (!this.discountType || !this.discountAmount) {
				return
			}
			this.applyDiscount = true
			if (this.discountType === 'percentage') {
				this.variants.forEach(variant => {
					variant.save = `$${variant.price}`
					variant.price = variant.price - (variant.price * this.discountAmount) / 100
				})
			} else if (this.discountType === 'flat') {
				this.variants.forEach(variant => {
					variant.save = `$${variant.price}`
					variant.price = Math.max(0, variant.price - this.discountAmount)
				})
			}
		},
		enableBlackFridayDiscount() {
			if (this.discountType) {
				// There's already a custom discount applied from URL parameters
				return
			}
			this.discountBanner = true
			this.applyDiscount = true
			this.variants.forEach((variant) => {
				variant.save = `$${variant.price}`
				variant.price = variant.price - (variant.price * 30) / 100
			})
			this.discountCoupon = 'BF2024'
		}
	},
	mounted() {
		this.calculateDiscount()
		if (showDiscount) {
			this.updateCountdown()
			this.countdownInterval = setInterval(this.updateCountdown, 1000)
			this.enableBlackFridayDiscount()
		}
	},
	destroyed() {
		if (this.countdownInterval !== null) {
			clearInterval(this.countdownInterval)
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.modal {

	text-align: center;
	padding-top: 35px;

	@media (max-width: 700px) {
		flex-direction: column;
	}

	@media (max-width: 800px) {
		padding: 50px 40px;
	}

	@media (max-width: 700px) {
		padding: 45px 30px;
	}

}

.discounts {

	margin-top: -5px;
	margin-bottom: 30px;

	font-size: 14px;
	color: $color-gray;
	line-height: 1.4;

}

.deal {

	max-width: 420px;

	display: inline-block;
	padding: 6px 15px;
	margin-bottom: 35px;

	font-weight: $medium;
	font-size: 14px;
	color: #ffffff;
	line-height: 24px;

	background: $gradient-dark;
	border-radius: 7px;
	box-shadow: 0 8px 14px 0 rgba($color-black, 0.2);

	.bold {
		font-weight: $bold;
		color: #ffffff;
		&.-countdown {
			padding: 0 3px;
			text-align: center;
			font-variant-numeric: tabular-nums;
			box-sizing: border-box;
			display: inline-block;
			background: #000000;
			border-radius: 5px;
			margin-right: 10px;
		}
	}

	&.-countdown {
		padding: 6px 10px 6px 6px;
	}

}

.variants {

	display: flex;
	justify-content: space-between;

	@media (max-width: 700px) {
		display: none;
	}

}

.variant {

	width: calc(20% - 16px);

	text-align: center;
	margin: 0 8px;
	padding: 20px 0;

	background: #f4f4f6;
	border: 2px solid #f4f4f6;
	border-radius: 8px;
	cursor: pointer;
	transition: all 0.1s ease;
	user-select: none;

	&:first-of-type {
		margin-left: 0;
	}

	&:last-of-type {
		margin-right: 0;
	}

	&.-active {
		background: $color-white;
		border: 2px solid $color-primary;
	}

	&.-inactive {
		cursor: default;
	}

	&:hover:not(.-inactive) {
		transform: translateY(-3px);
	}

}

.name {

	font-weight: $medium;
	font-size: 14px;
	color: $color-gray;
	line-height: 1.7;
	text-transform: uppercase;

	&.-more-devices {
		padding: 0 15px;
		margin-bottom: 13px;
		text-transform: none;
	}

}

.price {

	margin-top: 10px;

	font-weight: $bold;
	font-size: 24px;
	color: $color-black;
	letter-spacing: -0.2px;
	line-height: 1.6;

}

.save {

	margin-top: 10px;
	padding: 0 5px;

	font-weight: $bold;
	font-size: 14px;
	color: $color-primary;
	letter-spacing: -0.17px;
	line-height: 1.7;

	&.-discount {
		font-weight: $medium;
		font-size: 18px;
		color: $color-black;
		position: relative;
		display: inline-block;
		padding: 0 2px;
		&:before {
			content: '';
			width: 100%;
			height: 3px;
			background: $color-primary;
			opacity: 0.9;
			position: absolute;
			left: 0;
			top: 50%;
			transform: rotate(-20deg) translate(1px, -2px);
			border-radius: 3px;
		}
	}

}

.buy-link {

	display: none;

	&.-show {
		display: inline;
	}

}

.buy {

	margin-top: 50px;

	@media (max-width: 700px) {
		width: 300px;
		margin-top: 25px;
	}

	@media (max-width: 430px) {
		width: 100%;
	}

}

.info {

	margin-top: 45px;
	margin-bottom: -10px;

	font-size: 14px;
	color: $color-gray;
	line-height: 1.4;

}

::v-deep .link {

	position: relative;

	text-decoration: none;
	font-weight: $medium;
	color: $color-primary;
	cursor: pointer;

	&:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 2px;
		bottom: -2px;
		left: 0;
		z-index: 2;
		background-color: $color-primary;
		opacity: 0;
		transition: opacity 0.1s ease;
	}

	&:hover:after {
		opacity: 0.8;
	}

	&.-more-devices {
		font-size: 14px;
	}

}

.variants-select-wraper {

	max-width: 300px;

	margin: 0 auto;
	position: relative;
	display: none;
	user-select: none;

	@media (max-width: 700px) {
		display: block;
	}

}

.variants-select-label {

	height: 60px;

	position: relative;
	padding: 0 20px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;

	font-size: 16px;
	color: $color-black;
	line-height: 1.5;

	border: 1px solid #C7C7CA;
	box-shadow: 0 4px 7px -2px rgba(14,12,27,0.07);
	border-radius: 8px;
	cursor: pointer;

	@media (max-width: 400px) {
		padding: 0 15px;
	}

}

.mobile-name {

	font-weight: $bold;
	font-size: 15px;
	color: $color-black;
	text-transform: uppercase;

}

.mobile-save {

	margin-left: 10px;
	margin-right: auto;

	font-weight: $medium;
	font-size: 12px;
	color: $color-gray;

	@media (max-width: 415px) {
		&.-ru {
			display: none;
		}
	}

	@media (max-width: 390px) {
		&.-it {
			display: none;
		}
	}

	@media (max-width: 350px) {
		display: none;
	}

	&.-discount {
		position: relative;
		padding: 0 2px;
		&:before {
			content: '';
			width: 100%;
			height: 2px;
			background: $color-gray;
			opacity: 0.9;
			position: absolute;
			left: 0;
			top: 50%;
			transform: rotate(-20deg) translate(0px, -1px);
			border-radius: 3px;
		}
	}

}

.mobile-price {

	margin-right: 15px;
	margin-left: auto;

	font-weight: $bold;
	font-size: 18px;
	color: $color-black;
	letter-spacing: -0.15px;

	@media (max-width: 400px) {
		font-size: 16px;
		margin-right: 10px;
	}

}

.variants-select {

	width: 100%;
	height: 100%;

	position: absolute;
	left: 0;
	top: 0;

	opacity: 0;
	cursor: pointer;

}
</style>
