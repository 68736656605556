<template>
	<section class="newsletter">
		<div class="col-1200" ref="content">
			<div class="heading -animation" :class="doneClass">{{ $t('newsletter.title') }}</div>
			<p class="description -animation" :class="doneClass">{{ $t('newsletter.description') }}</p>
			<form class="form -animation" :class="doneClass" @submit.prevent="submit">
				<TextInput size="standard" :placeholder="$t('input.email')" type="email" :required="true" v-model.trim="email" :disabled="loading" />
				<ActionButton :label="$t('button.newsletter')" :loading="loading" />
			</form>
			<div class="done" :class="{ '-show': done }">
				<img class="icon" src="../assets/img/icons/love.svg" alt="">
				<div class="heading">{{ done_title }}</div>
				<div class="description">{{ done_message }}</div>
			</div>
		</div>
	</section>
</template>

<script>
import axios from 'axios'
import ActionButton from './ActionButton.vue'
import TextInput from './TextInput.vue'

export default {
	name: 'Newsletter',
	components: {
		ActionButton,
		TextInput
	},
	data() {
		return {
			email: '',
			loading: false,
			done: false,
			done_title: '',
			done_message: ''
		}
	},
	computed: {
		doneClass() {
			return {
				'-done': this.done
			}
		}
	},
	methods: {
		submit() {
			if (!this.email) {
				this.shake()
				return
			}
			this.loading = true
			axios.post('/api/newsletter', {
				email: this.email.replace(/\s+/g, '').toLowerCase()
			}).then((response) => {
				this.done_title = response.data.title
				this.done_message = response.data.message
				this.done = true
			}).catch((error) => {
				this.shake()
				console.error(error)
			}).then(() => {
				this.loading = false
			})
		},
		shake() {
			this.$refs.content.classList.add('-shake')
			setTimeout(() => {
				this.$refs.content.classList.remove('-shake')
			}, 800)
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.newsletter {

	text-align: center;

}

.col-1200 {

	position: relative;

}

.-animation {

	transition: all 0.15s ease;

}

.-done {

	opacity: 0;
	pointer-events: none;

}

.heading {

	font-weight: $bold;
	font-size: 38px;
	color: $color-black;
	letter-spacing: -0.34px;
	line-height: 1.4;

	@media (max-width: 1000px) {
		font-size: 36px;
	}

	@media (max-width: 500px) {
		font-size: 29px;
	}

}

.description {

	max-width: 680px;

	margin-top: 15px;
	margin-left: auto;
	margin-right: auto;

	font-weight: $medium;
	font-size: 20px;
	color: $color-gray;
	letter-spacing: -0.17px;
	line-height: 1.7;

	@media (max-width: 1000px) {
		font-size: 18px;
		margin-top: 10px;
	}

	@media (max-width: 500px) {
		max-width: 300px;
		margin: 0 auto;
		margin-top: 10px;
		font-size: 16px;
	}

}

.form {

	max-width: 500px;

	display: flex;
	margin: 0 auto;
	margin-top: 50px;

	@media (max-width: 500px) {
		flex-direction: column;
		align-items: center;
	}

}

.text-input {

	flex-grow: 1;
	margin-right: 20px;

	@media (max-width: 500px) {
		width: 300px;
		margin-right: 0;
		margin-bottom: 20px;
	}

	@media (max-width: 360px) {
		width: 100%;
	}

}

.action {

	@media (max-width: 500px) {
		width: 300px;
	}

	@media (max-width: 360px) {
		width: 100%;
	}

}

.done {

	width: 100%;
	height: 100%;

	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;

	pointer-events: none;
	opacity: 0;
	transform: scale(1.05);
	transition: all 0.15s ease 0.15s;

	&.-show {
		pointer-events: all;
		opacity: 1;
		transform: scale(1);
	}

}

.icon {

	width: 60px;
	height: 60px;

	margin-bottom: 30px;

}
</style>
