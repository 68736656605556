<template>
	<div class="buy-page" :class="langClass">
		<NavBar @buyClick="buyClick"/>
		<div class="background">
			<div class="col-1200">
				<header class="header">
					<h1 class="title">
						<span>{{ $t('buy.title') }}</span>
					</h1>
					<p class="requirements">{{ $t('buy.description', { macos_version: store.macos_version }) }}</p>
				</header>
				<section class="content">
					<img class="dots -left" src="../assets/img/icons/dots-square.svg" alt="">
					<BuyModal></BuyModal>
					<img class="dots -right" src="../assets/img/icons/dots-square.svg" alt="">
				</section>
			</div>
		</div>
		<MoneyBack />
		<Rating />
		<Discounts />
		<Newsletter />
		<div class="menu-overlay" :class="langClass"></div>
	</div>
</template>

<script>
import store from '../store'
import NavBar from '../components/NavBar.vue'
import MoneyBack from '../components/MoneyBack.vue'
import Discounts from '../components/Discounts.vue'
import Rating from '../components/Rating.vue'
import Newsletter from '../components/Newsletter.vue'
import BuyModal from '../components/BuyModal.vue'

export default {
	name: 'BuyPage',
	components: {
		NavBar,
		MoneyBack,
		Discounts,
		Rating,
		Newsletter,
		BuyModal
	},
	computed: {
		store() {
			return store
		}
	},
	methods: {
		buyClick() {
			window.scroll({
				top: this.$refs.tabs.offsetTop - 60, // With nav bar compensation
				left: 0,
				behavior: 'smooth'
			})
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.background {

	margin-top: 60px;

	background: $gradient-dark;

}

.header {

	padding-top: 120px;
	padding-bottom: 85px;
	text-align: center;

	@media (max-width: 800px) {
		padding-top: 70px;
		padding-bottom: 55px;
	}

}

.title {

	margin: 0;

	font-weight: $bold;
	font-size: 36px;
	color: $color-white;
	letter-spacing: -0.4px;
	line-height: 1.5;

	@media (max-width: 800px) {
		font-size: 28px;
	}

}

.requirements {

	font-size: 16px;
	color: rgba($color-white,0.60);
	line-height: 1.5;

	@media (max-width: 800px) {
		font-size: 15px;
	}

}

.content {

	max-width: 800px;

	position: relative;
	margin: 0 auto;

}

.modal {

	padding: 64px;
	box-sizing: border-box;

	background: #FFFFFF;
	box-shadow: 0 20px 100px 0 rgba(17,12,46,0.10);
	border-radius: 16px;

}

.dots {

	width: 80px;
	height: 80px;

	position: absolute;

	&.-left {

		left: -130px;
		top: 0;

	}

	&.-right {

		right: -130px;
		bottom: 170px;

	}

	@media (max-width: 1000px) {
		display: none;
	}

}

.money-back {

	margin-top: -130px;
	padding-top: 250px;

	background: $color-white;

	@media (max-width: 800px) {
		padding-top: 220px;
	}

}

.rating {

	margin-top: 50px;

}

.newsletter {

	margin-top: 60px;

}

.footer {

	margin-top: 115px;

	@media (max-width: 1000px) {
		margin-top: 90px;
	}

}

// 👇 Language specific stuff

// 🇯🇵 Japanese
.-ja {

	.tab-link {

		.normal {

			@media (max-width: 750px) {
				font-size: 17px;
			}

			@media (max-width: 630px) {
				display: none;
			}

		}

		.mobile {

			font-size: 16px;

			@media (max-width: 630px) {
				display: inline;
			}

		}

	}

}

// 🇮🇹 Italian
.-it {

	.tab-link {

		.normal {

			@media (max-width: 800px) {
				font-size: 17px;
			}

			@media (max-width: 690px) {
				display: none;
			}

		}

		.mobile {

			@media (max-width: 690px) {
				display: inline;
			}

		}

	}

}

// 🇫🇷 French
.-fr {

	.tab-link {

		.normal {

			@media (max-width: 860px) {
				font-size: 17px;
			}

			@media (max-width: 750px) {
				display: none;
			}

		}

		.mobile {

			@media (max-width: 750px) {
				display: inline;
			}

		}

	}

}

// 🇪🇸 Spanish
.-es {

	.tab-link {

		.normal {

			@media (max-width: 760px) {
				font-size: 17px;
			}

			@media (max-width: 660px) {
				display: none;
			}

		}

		.mobile {

			@media (max-width: 660px) {
				display: inline;
			}

		}

	}

}
</style>
