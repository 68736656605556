<template>
	<div class="cleanshot-discount">
		<NavBar />
		<div class="background">
			<div class="col-1200">
				<header class="header">
					<h1 class="title">
						<span>{{ $t('cleanshot.title') }}</span>
					</h1>
					<p class="description" v-html="$t('cleanshot.description')"></p>
				</header>
				<div class="modal -animation" ref="modal">
					<form class="form -animation" @submit.prevent="submit" ref="form">
						<TextInput :placeholder="$t('input.email')" type="email" :required="true" v-model.trim="email" :disabled="loading"  />
						<ActionButton class="submit" size="large" :label="$t('button.upgrade')" :loading="loading" />
					</form>
					<div class="done" :class="{ '-show': this.done }">
						<img class="icon" src="../assets/img/icons/mail.svg" v-if="!done_error" alt="">
						<img class="icon" src="../assets/img/icons/error.svg" v-if="done_error" alt="">
						<div class="done-heading">{{ done_title }}</div>
						<div class="done-description">{{ done_message }}</div>
						<ActionButton class="back" label="Go back" @click="back" v-if="done_error" />
					</div>
				</div>
			</div>
		</div>
		<Newsletter />
		<div class="menu-overlay" :class="langClass"></div>
	</div>
</template>

<script>
import axios from 'axios'
import NavBar from '../components/NavBar.vue'
import ActionButton from '../components/ActionButton.vue'
import TextInput from '../components/TextInput.vue'
import Newsletter from '../components/Newsletter.vue'

export default {
	name: 'CleanShotDiscountPage',
	metaInfo() {
		return this.localizedMeta('/cleanshot-discount', 'cleanshot')
	},
	components: {
		NavBar,
		ActionButton,
		TextInput,
		Newsletter
	},
	data() {
		return {
			email: '',
			loading: false,
			done: false,
			done_error: false,
			done_title: '',
			done_message: ''
		}
	},
	methods: {
		submit() {
			if (!this.email) {
				this.shake()
				return
			}
			this.loading = true
			axios.post('/api/cleanshot', {
				email: this.email.replace(/\s+/g, '').toLowerCase()
			}).then((response) => {
				this.success(response.data.title, response.data.message)
			}).catch((error) => {
				console.error(error)
				if (error.response.status === 400) {
					this.fail(error.response.data.title, error.response.data.message)
				} else {
					this.shake()
				}
			}).then(() => {
				this.loading = false
			})
		},
		success(title, message) {
			this.done_title = title
			this.done_message = message
			this.done_error = false
			this.unfoldModal()
			setTimeout(() => {
				this.done = true
			}, 150)
		},
		fail(title, message) {
			this.done_title = title
			this.done_message = message
			this.done_error = true
			this.unfoldModal()
			setTimeout(() => {
				this.done = true
			}, 150)
			setTimeout(() => {
				this.foldModal()
			}, 5000)
		},
		unfoldModal() {
			this.$refs.form.classList.add('-done')
			this.$refs.modal.style.height = `${this.$refs.modal.scrollHeight}px`
			setTimeout(() => {
				this.$refs.modal.classList.add('-done')
			}, 0)
		},
		foldModal() {
			this.done = false
			setTimeout(() => {
				this.$refs.modal.classList.remove('-done')
				setTimeout(() => {
					this.$refs.form.classList.remove('-done')
					this.$refs.modal.style.height = ''
				}, 150)
			}, 150)
		},
		back() {
			this.foldModal()
		},
		shake() {
			this.$refs.form.classList.add('-shake')
			setTimeout(() => {
				this.$refs.form.classList.remove('-shake')
			}, 800)
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.background {

	margin-top: 60px;

	background: $gradient-dark;

}

.-animation {

	transition: all 0.15s ease;

}

.header {

	padding-top: 120px;
	padding-bottom: 85px;
	text-align: center;

	@media (max-width: 800px) {
		padding-top: 70px;
		padding-bottom: 55px;
	}

}

.title {

	margin: 0;

	font-weight: $bold;
	font-size: 36px;
	color: $color-white;
	letter-spacing: -0.4px;
	line-height: 1.5;

	@media (max-width: 800px) {
		font-size: 28px;
	}

}

.description {

	font-size: 16px;
	color: rgba($color-white,0.60);
	line-height: 1.5;

	::v-deep .bold {
		color: $color-white;
	}

}

.modal {

	max-width: 800px;

	position: relative;
	padding: 80px;
	box-sizing: border-box;
	margin: 0 auto;

	background: #FFFFFF;
	box-shadow: 0 20px 100px 0 rgba(17,12,46,0.10);
	border-radius: 16px;

	&.-done {
		height: 400px !important;
	}

	@media (max-width: 800px) {
		padding: 80px 40px;
	}

	@media (max-width: 650px) {
		padding: 60px 40px;
	}

	@media (max-width: 400px) {
		padding: 40px 25px;
	}

}

.form {

	display: flex;
	justify-content: space-between;

	@media (max-width: 650px) {
		flex-direction: column;
	}

	&.-done {

		opacity: 0;
		pointer-events: none;

	}

}

.text-input {

	flex-grow: 1;
	margin-right: 30px;

	@media (max-width: 650px) {
		margin-right: 0;
		margin-bottom: 20px;
	}

}

.newsletter {

	margin-top: -80px;
	padding-top: 235px;

	background: $color-white;

	@media (max-width: 1000px) {
		margin-top: -120px;
	}

}

.footer {

	margin-top: 115px;

	@media (max-width: 1000px) {
		margin-top: 90px;
	}

}

.done {

	width: 100%;
	height: 100%;

	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 99px 80px;
	box-sizing: border-box;
	text-align: center;

	pointer-events: none;
	opacity: 0;
	transform: scale(1.05);
	transition: all 0.15s ease;

	&.-show {
		pointer-events: all;
		opacity: 1;
		transform: scale(1);
	}

	@media (max-width: 1000px) {
		padding: 80px 40px;
	}

	@media (max-width: 600px) {
		padding: 35px 30px;
	}

}

.icon {

	width: 60px;
	height: 60px;

	margin-bottom: 20px;

}

.done-heading {

	font-weight: $bold;
	font-size: 35px;
	color: $color-black;
	letter-spacing: -0.29px;
	line-height: 1.7;

	@media (max-width: 800px) {
		font-size: 28px;
	}

}

.done-description {

	font-weight: $medium;
	font-size: 18px;
	color: $color-gray;
	letter-spacing: -0.15px;
	line-height: 2;

	@media (max-width: 800px) {
		font-size: 16px;
	}

}

.back {

	margin-top: 30px;
	flex-shrink: 0;

}
</style>
