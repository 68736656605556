<template>
	<div class="changelog">
		<NavBar />
		<div class="col-1200">
			<h1 class="heading">{{ $t('menu.changelog') }}</h1>
			<section class="versions">
				<div class="version">
					<div class="number">2.6</div>
					<ul class="changes">
						<li class="change">↩️ <span class="bold">Easily restore the previous measurement session after closing the app</span></li>
						<li class="change">🧲 <span class="bold">Guides now snap to area objects and align to the midpoint between guides</span></li>
						<li class="change">📐 <span class="bold">Hold Shift to maintain the aspect ratio of resized areas or to create perfect squares</span></li>
						<li class="change">A new context menu is now available when right-clicking on an object</li>
						<li class="change">PixelSnap now returns focus to the previous app after taking a screenshot with CleanShot integration</li>
						<li class="change">Improved compatibility with macOS Sequoia</li>
						<li class="change">Improved displaying guide distances below the notch</li>
						<li class="change">Fixed issues with Figma integration</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">2.5.4</div>
					<ul class="changes">
						<li class="change">🔉 <span class="bold">Screenshot sound will now respect CleanShot's settings when the integration is enabled</span></li>
						<li class="change">Added support for control+click to be recognized as right-click</li>
						<li class="change">Fixed Figma integration</li>
						<li class="change">Improved compatibility with macOS 13.0</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">2.5.3</div>
					<ul class="changes">
						<li class="change">Improved compatibility with macOS Ventura</li>
						<li class="change">Changed minimum system version to macOS 10.13</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">2.5.2</div>
					<ul class="changes">
						<li class="change">Added option to disable snapping in Guide and Area tools by holding ⌘ Command key</li>
						<li class="change">Added option to toggle screenshot padding by holding ⌥ (alt/option) when taking a screenshot</li>
						<li class="change">Improved compatibility with Figma</li>
						<li class="change">Minor fixes and improvements</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">2.5.1</div>
					<ul class="changes">
						<li class="change">Improved compatibility with CleanShot X</li>
						<li class="change">You can now start PixelSnap with right-click on the menu bar icon</li>
						<li class="change">Minor bug fixes and UI improvements</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">2.5</div>
					<ul class="changes">
						<li class="change">🧲 <span class="bold">Distance tool can snap to guides and selected objects now</span></li>
						<li class="change">📐 <span class="bold">Added option to display aspect ratio</span></li>
						<li class="change">✨ <span class="bold">Guide tool will now work with Sketch, Figma, Adobe XD and Affinity integrations</span></li>
						<li class="change">New preferences icons on macOS Big Sur</li>
						<li class="change">Added option to show width/height indicators</li>
						<li class="change">Improved compatibility with macOS Monterey</li>
						<li class="change">Many small bug fixes and improvements</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">2.4.2</div>
					<ul class="changes">
						<li class="change">⛰ <span class="bold">Improved compatibility with macOS Big Sur</span></li>
						<li class="change">✨ <span class="bold">New app icon</span></li>
						<li class="change">Bug fixes and UI improvements</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">2.4.1</div>
					<ul class="changes">
						<li class="change">Fixed bug with Figma integration when using Full-Screen mode</li>
						<li class="change">Minor bug fixes and UI improvements</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">2.4</div>
					<ul class="changes">
						<li class="change">🔌 Added <span class="bold">Figma</span> and <span class="bold">Affinity</span> integrations – Get correct dimensions regardless of the displayed zoom level!</li>
						<li class="change">Minor bug fixes</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">2.3.5</div>
					<ul class="changes">
						<li class="change">📐 Implement showing the distance between a guide and the edges of the screen</li>
						<li class="change">Bug fixes</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">2.3.4</div>
					<ul class="changes">
						<li class="change">⌨️ Added option to disable global hotkey in selected apps</li>
						<li class="change">Fixed bug with inaccessible preferences on macOS Big Sur</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">2.3.3</div>
					<ul class="changes">
						<li class="change">Improved copy to clipboard compatibility with other apps</li>
						<li class="change">Minor bug fixes</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">2.3.2</div>
					<ul class="changes">
						<li class="change">Added support for F1, F2, ... keys in keyboard shortcuts</li>
						<li class="change">Minor bug fixes</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">2.3.1</div>
					<ul class="changes">
						<li class="change">Added support for <a class="bold" href="https://getcleanshot.com?ref=pixelsnapchangelog">CleanShot X</a> ⚡️</li>
						<li class="change">Minor bug fixes</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">2.3</div>
					<ul class="changes">
						<li class="change">Added <span class="bold">🧽 Clear all</span> feature to clear all measurements</li>
						<li class="change">Added dark mode support to the onboarding</li>
						<li class="change">Minor bug fixes</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">2.2.3</div>
					<ul class="changes">
						<li class="change">Fix macOS Catalina permission bug</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">2.2.2</div>
					<ul class="changes">
						<li class="change">Minor bug fixes</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">2.2.1</div>
					<ul class="changes">
						<li class="change">🔇 You can now disable screenshot sound in the app preferences</li>
						<li class="change">Improved 🇷🇺&nbsp;Russian localization (Thanks to our awesome user - Dmitry!)</li>
						<li class="change">Minor improvements</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">2.2</div>
					<ul class="changes">
						<li class="change">PixelSnap now speaks <span class="bold">🇨🇳&nbsp;Chinese</span>, <span class="bold">🇯🇵&nbsp;Japanese</span>, <span class="bold">🇫🇷&nbsp;French</span>, <span class="bold">🇪🇸&nbsp;Spanish</span>, <span class="bold">🇮🇹&nbsp;Italian</span> and <span class="bold">🇷🇺&nbsp;Russian</span>!</li>
						<li class="change">Fixed bug with Adobe XD integration</li>
						<li class="change">Fixed crash in certain scenarios</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">2.1.1</div>
					<ul class="changes">
						<li class="change">Fixed bug with CleanShot integration</li>
						<li class="change">Fixed bug with Freezing screen and Background Mode</li>
						<li class="change">Fixed bug with Freezing screen on macOS High Sierra</li>
						<li class="change">Fixed bug with detecting distance in some cases</li>
						<li class="change">Minor improvements</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">2.1</div>
					<ul class="changes">
						<li class="change">🖥 <span class="bold">Background Mode</span> - The guides and measurements will stay on the screen when PixelSnap becomes inactive</li>
						<li class="change">🖼 <span class="bold">Screenshots padding</span> - Create perfectly aligned screenshots with a padding added to your selection, you can adjust it in the Preferences</li>
						<li class="change">Improved opening easy access context menu</li>
						<li class="change">Fixed bug with preventing mac from sleep</li>
						<li class="change">Fixed memory leak</li>
						<li class="change">Minor improvements</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">2.0</div>
					<ul class="changes">
						<li class="change">✨ Completely rewritten from scratch, the new version provides a truly magical experience. Measuring things has never been faster or more enjoyable!</li>
						<li class="action" @click="showChanges">Check out 30+ changes <img class="arrow" src="../assets/img/arrow-right.svg" alt=""></li>
					</ul>
				</div>
				<div class="version">
					<div class="number">1.4.1</div>
					<ul class="changes">
						<li class="change">Fixed bug when the app crashed after measuring bottom edge of a screen.</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">1.4</div>
					<ul class="changes">
						<li class="change">💎 <span class="bold">Sketch app support</span> - Get correct dimensions based on the zoom level (Can be enabled in Preferences)</li>
						<li class="change">Bug fixes</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">1.3.1</div>
					<ul class="changes">
						<li class="change">Fixed bug when using Alt-Tab shortly after copying a screenshot</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">1.3</div>
					<ul class="changes">
						<li class="change">📏 <span class="bold">Crosshair mode</span> - Easily check alignment of objects (Press Shift ⇧)</li>
						<li class="change">✋ You can now move the rectangle around the screen by pressing Shift ⇧ and dragging it</li>
						<li class="change">Added SASS clipboard format</li>
						<li class="change">Bug fixes and usability improvements</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">1.2</div>
					<ul class="changes">
						<li class="change">❄️ <span class="bold">Freezeing screen</span> - You can now measure moving objects and keep windows active!</li>
						<li class="change">🖥 More precise measuring on retina display</li>
						<li class="change">🎛 Copy screenshot to clipboard by pressing C</li>
						<li class="change">Fixed lag when switching between displays</li>
						<li class="change">Bug fixes and performance improvements</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">1.1</div>
					<ul class="changes">
						<li class="change">📸 <span class="bold">Introducing screenshots</span> - You can now save selected area as a screenshot!</li>
						<li class="change">📐 Added options to adjust tolerance modes</li>
						<li class="change">Fixed bug with adding application to login items on High Sierra</li>
						<li class="change">Fixed bug preventing the application from launching</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">1.0.2</div>
					<ul class="changes">
						<li class="change">Fixed bug with external display on High Sierra</li>
					</ul>
				</div>
				<div class="version">
					<div class="number">1.0.1</div>
					<ul class="changes">
						<li class="change">Fixed bug with High Sierra screen artifacts</li>
					</ul>
				</div>
			</section>
		</div>
		<Newsletter />
		<div class="menu-overlay" :class="langClass"></div>
		<ChangesModal :active="changesModalActive" @close="hideChanges" />
	</div>
</template>

<script>
import NavBar from '../components/NavBar.vue'
import Newsletter from '../components/Newsletter.vue'
import ChangesModal from '../components/ChangesModal.vue'

export default {
	name: 'ChangelogPage',
	metaInfo() {
		return this.localizedMeta('/changelog', 'changelog')
	},
	components: {
		NavBar,
		Newsletter,
		ChangesModal
	},
	data() {
		return {
			changesModalActive: false
		}
	},
	methods: {
		showChanges() {
			this.changesModalActive = true
		},
		hideChanges() {
			this.changesModalActive = false
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.heading {

	margin-top: 180px;

	font-weight: $bold;
	font-size: 36px;
	color: $color-black;
	letter-spacing: -0.4px;
	text-align: center;
	line-height: 1.5;

	@media (max-width: 800px) {
		margin-top: 130px;
		font-size: 28px;
	}

}

.versions {

	max-width: 600px;

	margin: 0 auto;
	margin-top: 120px;

	@media (max-width: 800px) {
		margin-top: 70px;
	}

}

.version {

	margin-bottom: 80px;

	&:last-of-type {
		margin-bottom: 0;
	}

	@media (max-width: 800px) {
		margin-bottom: 60px;
	}

}

.number {

	font-weight: $bold;
	font-size: 24px;
	color: $color-black;
	letter-spacing: -0.2px;
	line-height: 1.66;

}

.changes {

	padding-left: 50px;

	list-style: none;

	@media (max-width: 800px) {
		padding-left: 35px;
	}

}

.change {

	margin-bottom: 15px;

	font-weight: $medium;
	font-size: 17px;
	color: $color-gray;
	line-height: 1.7;

	&:last-of-type {
		margin-bottom: 0;
	}

	&::before {
		content: "—";
		margin-left: -50px;
		margin-right: 32px;
	}

	@media (max-width: 800px) {
		font-size: 16px;
		&::before {
			margin-left: -35px;
			margin-right: 19px;
		}
	}

}

.bold {

	color: $color-black;

}

.action {

	position: relative;
	display: inline;

	color: $color-primary;
	font-weight: $bold;
	font-size: 17px;
	line-height: 1.8;
	letter-spacing: -0.2px;

	cursor: pointer;

	@media (max-width: 800px) {
		font-size: 16px;
	}

	&:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 2px;
		bottom: -2px;
		left: 0;
		z-index: 2;
		background-color: $color-primary;
		opacity: 0;
		transition: opacity 0.1s ease;
	}

	&:hover:after {
		opacity: 1;
	}

	.arrow {
		width: 20px;
		height: 20px;
		position: relative;
		top: 4px;
		margin-left: 5px;
	}

}

.video {

	max-width: 100%;

	margin-top: 30px;
	margin-bottom: 60px;

	box-shadow: 0 5px 70px 0 rgba(17,12,46,0.06);

}

.newsletter {

	margin-top: 230px;

	@media (max-width: 1000px) {
		margin-top: 130px;
	}

}

.footer {

	margin-top: 115px;

	@media (max-width: 1000px) {
		margin-top: 90px;
	}

}
</style>
