var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"changelog"},[_c('NavBar'),_c('div',{staticClass:"col-1200"},[_c('h1',{staticClass:"heading"},[_vm._v(_vm._s(_vm.$t('menu.changelog')))]),_c('section',{staticClass:"versions"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.0")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("✨ Completely rewritten from scratch, the new version provides a truly magical experience. Measuring things has never been faster or more enjoyable!")]),_c('li',{staticClass:"action",on:{"click":_vm.showChanges}},[_vm._v("Check out 30+ changes "),_c('img',{staticClass:"arrow",attrs:{"src":require("../assets/img/arrow-right.svg"),"alt":""}})])])]),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24),_vm._m(25),_vm._m(26),_vm._m(27),_vm._m(28)])]),_c('Newsletter'),_c('div',{staticClass:"menu-overlay",class:_vm.langClass}),_c('ChangesModal',{attrs:{"active":_vm.changesModalActive},on:{"close":_vm.hideChanges}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.6")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("↩️ "),_c('span',{staticClass:"bold"},[_vm._v("Easily restore the previous measurement session after closing the app")])]),_c('li',{staticClass:"change"},[_vm._v("🧲 "),_c('span',{staticClass:"bold"},[_vm._v("Guides now snap to area objects and align to the midpoint between guides")])]),_c('li',{staticClass:"change"},[_vm._v("📐 "),_c('span',{staticClass:"bold"},[_vm._v("Hold Shift to maintain the aspect ratio of resized areas or to create perfect squares")])]),_c('li',{staticClass:"change"},[_vm._v("A new context menu is now available when right-clicking on an object")]),_c('li',{staticClass:"change"},[_vm._v("PixelSnap now returns focus to the previous app after taking a screenshot with CleanShot integration")]),_c('li',{staticClass:"change"},[_vm._v("Improved compatibility with macOS Sequoia")]),_c('li',{staticClass:"change"},[_vm._v("Improved displaying guide distances below the notch")]),_c('li',{staticClass:"change"},[_vm._v("Fixed issues with Figma integration")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.5.4")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("🔉 "),_c('span',{staticClass:"bold"},[_vm._v("Screenshot sound will now respect CleanShot's settings when the integration is enabled")])]),_c('li',{staticClass:"change"},[_vm._v("Added support for control+click to be recognized as right-click")]),_c('li',{staticClass:"change"},[_vm._v("Fixed Figma integration")]),_c('li',{staticClass:"change"},[_vm._v("Improved compatibility with macOS 13.0")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.5.3")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("Improved compatibility with macOS Ventura")]),_c('li',{staticClass:"change"},[_vm._v("Changed minimum system version to macOS 10.13")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.5.2")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("Added option to disable snapping in Guide and Area tools by holding ⌘ Command key")]),_c('li',{staticClass:"change"},[_vm._v("Added option to toggle screenshot padding by holding ⌥ (alt/option) when taking a screenshot")]),_c('li',{staticClass:"change"},[_vm._v("Improved compatibility with Figma")]),_c('li',{staticClass:"change"},[_vm._v("Minor fixes and improvements")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.5.1")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("Improved compatibility with CleanShot X")]),_c('li',{staticClass:"change"},[_vm._v("You can now start PixelSnap with right-click on the menu bar icon")]),_c('li',{staticClass:"change"},[_vm._v("Minor bug fixes and UI improvements")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.5")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("🧲 "),_c('span',{staticClass:"bold"},[_vm._v("Distance tool can snap to guides and selected objects now")])]),_c('li',{staticClass:"change"},[_vm._v("📐 "),_c('span',{staticClass:"bold"},[_vm._v("Added option to display aspect ratio")])]),_c('li',{staticClass:"change"},[_vm._v("✨ "),_c('span',{staticClass:"bold"},[_vm._v("Guide tool will now work with Sketch, Figma, Adobe XD and Affinity integrations")])]),_c('li',{staticClass:"change"},[_vm._v("New preferences icons on macOS Big Sur")]),_c('li',{staticClass:"change"},[_vm._v("Added option to show width/height indicators")]),_c('li',{staticClass:"change"},[_vm._v("Improved compatibility with macOS Monterey")]),_c('li',{staticClass:"change"},[_vm._v("Many small bug fixes and improvements")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.4.2")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("⛰ "),_c('span',{staticClass:"bold"},[_vm._v("Improved compatibility with macOS Big Sur")])]),_c('li',{staticClass:"change"},[_vm._v("✨ "),_c('span',{staticClass:"bold"},[_vm._v("New app icon")])]),_c('li',{staticClass:"change"},[_vm._v("Bug fixes and UI improvements")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.4.1")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("Fixed bug with Figma integration when using Full-Screen mode")]),_c('li',{staticClass:"change"},[_vm._v("Minor bug fixes and UI improvements")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.4")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("🔌 Added "),_c('span',{staticClass:"bold"},[_vm._v("Figma")]),_vm._v(" and "),_c('span',{staticClass:"bold"},[_vm._v("Affinity")]),_vm._v(" integrations – Get correct dimensions regardless of the displayed zoom level!")]),_c('li',{staticClass:"change"},[_vm._v("Minor bug fixes")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.3.5")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("📐 Implement showing the distance between a guide and the edges of the screen")]),_c('li',{staticClass:"change"},[_vm._v("Bug fixes")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.3.4")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("⌨️ Added option to disable global hotkey in selected apps")]),_c('li',{staticClass:"change"},[_vm._v("Fixed bug with inaccessible preferences on macOS Big Sur")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.3.3")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("Improved copy to clipboard compatibility with other apps")]),_c('li',{staticClass:"change"},[_vm._v("Minor bug fixes")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.3.2")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("Added support for F1, F2, ... keys in keyboard shortcuts")]),_c('li',{staticClass:"change"},[_vm._v("Minor bug fixes")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.3.1")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("Added support for "),_c('a',{staticClass:"bold",attrs:{"href":"https://getcleanshot.com?ref=pixelsnapchangelog"}},[_vm._v("CleanShot X")]),_vm._v(" ⚡️")]),_c('li',{staticClass:"change"},[_vm._v("Minor bug fixes")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.3")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("Added "),_c('span',{staticClass:"bold"},[_vm._v("🧽 Clear all")]),_vm._v(" feature to clear all measurements")]),_c('li',{staticClass:"change"},[_vm._v("Added dark mode support to the onboarding")]),_c('li',{staticClass:"change"},[_vm._v("Minor bug fixes")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.2.3")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("Fix macOS Catalina permission bug")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.2.2")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("Minor bug fixes")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.2.1")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("🔇 You can now disable screenshot sound in the app preferences")]),_c('li',{staticClass:"change"},[_vm._v("Improved 🇷🇺 Russian localization (Thanks to our awesome user - Dmitry!)")]),_c('li',{staticClass:"change"},[_vm._v("Minor improvements")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.2")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("PixelSnap now speaks "),_c('span',{staticClass:"bold"},[_vm._v("🇨🇳 Chinese")]),_vm._v(", "),_c('span',{staticClass:"bold"},[_vm._v("🇯🇵 Japanese")]),_vm._v(", "),_c('span',{staticClass:"bold"},[_vm._v("🇫🇷 French")]),_vm._v(", "),_c('span',{staticClass:"bold"},[_vm._v("🇪🇸 Spanish")]),_vm._v(", "),_c('span',{staticClass:"bold"},[_vm._v("🇮🇹 Italian")]),_vm._v(" and "),_c('span',{staticClass:"bold"},[_vm._v("🇷🇺 Russian")]),_vm._v("!")]),_c('li',{staticClass:"change"},[_vm._v("Fixed bug with Adobe XD integration")]),_c('li',{staticClass:"change"},[_vm._v("Fixed crash in certain scenarios")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.1.1")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("Fixed bug with CleanShot integration")]),_c('li',{staticClass:"change"},[_vm._v("Fixed bug with Freezing screen and Background Mode")]),_c('li',{staticClass:"change"},[_vm._v("Fixed bug with Freezing screen on macOS High Sierra")]),_c('li',{staticClass:"change"},[_vm._v("Fixed bug with detecting distance in some cases")]),_c('li',{staticClass:"change"},[_vm._v("Minor improvements")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("2.1")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("🖥 "),_c('span',{staticClass:"bold"},[_vm._v("Background Mode")]),_vm._v(" - The guides and measurements will stay on the screen when PixelSnap becomes inactive")]),_c('li',{staticClass:"change"},[_vm._v("🖼 "),_c('span',{staticClass:"bold"},[_vm._v("Screenshots padding")]),_vm._v(" - Create perfectly aligned screenshots with a padding added to your selection, you can adjust it in the Preferences")]),_c('li',{staticClass:"change"},[_vm._v("Improved opening easy access context menu")]),_c('li',{staticClass:"change"},[_vm._v("Fixed bug with preventing mac from sleep")]),_c('li',{staticClass:"change"},[_vm._v("Fixed memory leak")]),_c('li',{staticClass:"change"},[_vm._v("Minor improvements")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("1.4.1")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("Fixed bug when the app crashed after measuring bottom edge of a screen.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("1.4")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("💎 "),_c('span',{staticClass:"bold"},[_vm._v("Sketch app support")]),_vm._v(" - Get correct dimensions based on the zoom level (Can be enabled in Preferences)")]),_c('li',{staticClass:"change"},[_vm._v("Bug fixes")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("1.3.1")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("Fixed bug when using Alt-Tab shortly after copying a screenshot")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("1.3")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("📏 "),_c('span',{staticClass:"bold"},[_vm._v("Crosshair mode")]),_vm._v(" - Easily check alignment of objects (Press Shift ⇧)")]),_c('li',{staticClass:"change"},[_vm._v("✋ You can now move the rectangle around the screen by pressing Shift ⇧ and dragging it")]),_c('li',{staticClass:"change"},[_vm._v("Added SASS clipboard format")]),_c('li',{staticClass:"change"},[_vm._v("Bug fixes and usability improvements")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("1.2")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("❄️ "),_c('span',{staticClass:"bold"},[_vm._v("Freezeing screen")]),_vm._v(" - You can now measure moving objects and keep windows active!")]),_c('li',{staticClass:"change"},[_vm._v("🖥 More precise measuring on retina display")]),_c('li',{staticClass:"change"},[_vm._v("🎛 Copy screenshot to clipboard by pressing C")]),_c('li',{staticClass:"change"},[_vm._v("Fixed lag when switching between displays")]),_c('li',{staticClass:"change"},[_vm._v("Bug fixes and performance improvements")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("1.1")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("📸 "),_c('span',{staticClass:"bold"},[_vm._v("Introducing screenshots")]),_vm._v(" - You can now save selected area as a screenshot!")]),_c('li',{staticClass:"change"},[_vm._v("📐 Added options to adjust tolerance modes")]),_c('li',{staticClass:"change"},[_vm._v("Fixed bug with adding application to login items on High Sierra")]),_c('li',{staticClass:"change"},[_vm._v("Fixed bug preventing the application from launching")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("1.0.2")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("Fixed bug with external display on High Sierra")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version"},[_c('div',{staticClass:"number"},[_vm._v("1.0.1")]),_c('ul',{staticClass:"changes"},[_c('li',{staticClass:"change"},[_vm._v("Fixed bug with High Sierra screen artifacts")])])])
}]

export { render, staticRenderFns }