<template>
	<div class="nav-bar" :class="[{ '-shadow': shadow, '-sticky': sticky }, langClass]" ref="navBar">
		<div class="col-1200">
			<router-link class="logo-link" :to="localizedPath('/')">
				<img class="logo" src="../assets/img/logo.svg" alt="PixelSnap 2">
			</router-link>
			<LanguageDropdown class="medium-language" @change="closeMenu" />
			<span class="menu-trigger" @click="toggleMenu" ref="menuTrigger">{{ $t('button.menu') }}</span>
			<ul class="menu" ref="menu">
				<li class="item">
					<router-link class="link" :to="localizedPath('/changelog')">{{ $t('menu.changelog') }}</router-link>
				</li>
				<li class="item">
					<a class="link" href="https://licenses.maketheweb.io/" target="_blank" rel="noopener">{{ $t('menu.manager') }}</a>
				</li>
				<li class="item">
					<router-link class="link" :to="localizedPath('/faq')">{{ $t('menu.faq') }}</router-link>
				</li>
				<li class="item">
					<a class="link" href="mailto:hello@mtw.team">{{ $t('menu.contact') }}</a>
				</li>
				<li class="item -language">
					<LanguageDropdown class="link" @change="closeMenu" />
				</li>
				<li class="item -button" v-if="buy">
					<router-link :to="localizedPath('/buy')">
						<ActionButton size="small" :label="$t('button.nav_buy')" @click="$emit('buyClick', $event)" />
					</router-link>
				</li>
				<li class="item -mobile" v-if="buy" @click="mobileBuyClick">
					<router-link class="link -buy" :to="localizedPath('/buy')">{{ $t('button.buy') }}</router-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import ActionButton from './ActionButton.vue'
import LanguageDropdown from './LanguageDropdown.vue'

export default {
	name: 'NavBar',
	components: {
		ActionButton,
		LanguageDropdown
	},
	props: {
		buy: {
			type: Boolean,
			default: true
		},
		shadow: {
			type: Boolean,
			default: true
		},
		sticky: {
			type: Boolean,
			default: true
		}
	},
	methods: {
		toggleMenu() {
			this.$refs.navBar.classList.toggle('-active')
			this.$refs.menuTrigger.classList.toggle('-active')
			this.$refs.menu.classList.toggle('-active')
			document.querySelector('.menu-overlay').classList.toggle('-active')
		},
		closeMenu() {
			this.$refs.navBar.classList.remove('-active')
			this.$refs.menuTrigger.classList.remove('-active')
			this.$refs.menu.classList.remove('-active')
			document.querySelector('.menu-overlay').classList.remove('-active')
		},
		mobileBuyClick($event) {
			this.closeMenu()
			this.$emit('buyClick', $event)
		}
	}
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

.menu-overlay {

	width: 100%;
	height: 100%;

	display: none;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 99;

	background-color: rgba($color-black, 0.5);

	@media (max-width: 1050px) {
		&.-ja {
			&.-active {
				display: block;
			}
		}
	}

	@media (max-width: 900px) {
		&.-active {
			display: block;
		}
	}

}
</style>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.nav-bar {

	width: 100%;

	background: $color-white;

	&.-shadow {
		box-shadow: 0 14px 20px -8px rgba(14, 12, 27, 0.05);
	}

	&.-sticky {
		position: fixed;
		left: 0;
		top: 0;
		z-index: 100;
	}

	@media (max-width: 900px) {
		&.-active.-shadow {
			box-shadow: 0 14px 40px -8px rgba(14, 12, 27, 0.2);
		}
	}

}

.col-1200 {

	height: 100%;
	height: 60px;

	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 900px) {
		height: auto;
		flex-wrap: wrap;
		padding: 0;
	}

}

.logo {

	height: 32px;

	display: block;

}

.logo-link {

	@media (max-width: 900px) {
		padding-left: 5%;
	}

}

.menu {

	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;

	list-style: none;

	@media (max-width: 900px) {

		width: 100%;
		display: none;
		padding: 10px 5% 15px 5%;
		border-top: 1px solid lighten($color-silver, 15);

		&.-active {
			display: block;
		}

	}

}

.item {

	&.-button {
		margin-left: 10px;
		@media (max-width: 900px) {
			display: none;
		}
	}

	&.-mobile {
		display: none;
		@media (max-width: 900px) {
			display: block;
		}
	}

	&.-language {
		@media (max-width: 900px) {
			display: none;
		}
		@media (max-width: 375px) {
			display: block;
		}
	}

	&:first-of-type .link {
		margin-left: 0;
	}

	&:last-of-type .link {
		margin-right: 0;
		margin-bottom: 0;
	}

}

.link {

	margin: 0 10px;
	padding: 20px 15px;

	font-size: 15px;
	font-weight: $medium;
	color: $color-gray;
	text-decoration: none;
	transition: color 0.1s ease;

	&.router-link-active {
		color: $color-black;
	}

	&.-buy {
		font-weight: $bold;
		color: $color-primary;
	}

	&:hover {
		color: $color-black;
	}

	@media (max-width: 1000px) and (min-width: 901px) {
		margin: 0 5px;
		padding: 20px 10px;
		font-size: 13px;
	}

	@media (max-width: 900px) {
		display: block;
		margin: 0;
		padding: 15px 5px;
		margin-bottom: 5px;
		&.language-dropdown {
			display: flex;
		}
	}

}

.action {

	@media (max-width: 1000px) and (min-width: 801px) {
		&.-small {
			font-size: 13px;
			padding: 0 15px;
		}
	}

}

.medium-language {

	display: none;
	margin-left: auto;
	height: 60px;
	padding: 0 10px;

	@media (max-width: 900px) {
		display: flex;
	}

	@media (max-width: 375px) {
		display: none;
	}

}

.menu-trigger {

	display: none;
    align-items: center;
	padding: 20px;
	margin-right: -20px;

	font-size: 16px;
	font-weight: $medium;
	color: $color-black;
	text-decoration: none;

	cursor: pointer;

	&::after {

		content: '';
		width: 15px;
		height: 15px;

		display: inline-block;
		margin-left: 5px;

		background-image: url('../assets/img/arrow.svg');
		transition: all 0.15s ease;

	}

	&.-active {

		&::after {
			transform: rotate(180deg);
		}

	}

	@media (max-width: 900px) {
		display: flex;
		padding-right: 5%;
		margin-right: 0;
	}

}

// 👇 Language specific stuff

// 🇯🇵 Japanese
.-ja {

	.nav-bar {

		@media (max-width: 1050px) {
			&.-active.-shadow {
				box-shadow: 0 14px 40px -8px rgba(14, 12, 27, 0.2);
			}
		}

	}

	.col-1200 {

		@media (max-width: 1050px) {
			height: auto;
			flex-wrap: wrap;
			padding: 0;
		}

	}

	.logo-link {

		@media (max-width: 1050px) {
			padding-left: 5%;
		}

	}

	.menu {

		@media (max-width: 1050px) {
			width: 100%;
			display: none;
			padding: 10px 5% 15px 5%;
			border-top: 1px solid lighten($color-silver, 15);

			&.-active {
				display: block;
			}
		}

	}

	.item {

		&.-button {
			@media (max-width: 1050px) {
				display: none;
			}
		}

		&.-mobile {
			@media (max-width: 1050px) {
				display: block;
			}
		}

		&.-language {
			@media (max-width: 1050px) {
				display: none;
			}
			@media (max-width: 375px) {
				display: block;
			}
		}

	}

	.link {

		@media (max-width: 1300px) and (min-width: 1051px) {
			margin: 0 5px;
			padding: 20px 10px;
			font-size: 13px;
		}

		@media (max-width: 1050px) {
			display: block;
			margin: 0;
			padding: 15px 5px;
			margin-bottom: 5px;
			font-size: 15px;
			&.language-dropdown {
				display: flex;
			}
		}

	}

	.action {

		@media (max-width: 1300px) and (min-width: 801px) {
			&.-small {
				font-size: 13px;
				padding: 0 15px;
			}
		}

	}

	.medium-language {

		@media (max-width: 1050px) {
			display: flex;
		}

		@media (max-width: 375px) {
			display: none;
		}

	}

	.menu-trigger {

		@media (max-width: 1050px) {
			display: flex;
			padding-right: 5%;
			margin-right: 0;
		}

	}

}

// 🇪🇸 Spanish
.-es {

	.nav-bar {

		@media (max-width: 1170px) {
			&.-active.-shadow {
				box-shadow: 0 14px 40px -8px rgba(14, 12, 27, 0.2);
			}
		}

	}

	.col-1200 {

		@media (max-width: 1170px) {
			height: auto;
			flex-wrap: wrap;
			padding: 0;
		}

	}

	.logo-link {

		@media (max-width: 1170px) {
			padding-left: 5%;
		}

	}

	.menu {

		@media (max-width: 1170px) {
			width: 100%;
			display: none;
			padding: 10px 5% 15px 5%;
			border-top: 1px solid lighten($color-silver, 15);

			&.-active {
				display: block;
			}
		}

	}

	.item {

		&.-button {
			@media (max-width: 1170px) {
				display: none;
			}
		}

		&.-mobile {
			@media (max-width: 1170px) {
				display: block;
			}
		}

		&.-language {
			@media (max-width: 1170px) {
				display: none;
			}
			@media (max-width: 375px) {
				display: block;
			}
		}

	}

	.link {

		padding: 20px 5px;

		@media (max-width: 1300px) and (min-width: 1171px) {
			margin: 0 5px;
			padding: 20px 10px;
			font-size: 13px;
		}

		@media (max-width: 1170px) {
			display: block;
			margin: 0;
			padding: 15px 5px;
			margin-bottom: 5px;
			font-size: 15px;
			&.language-dropdown {
				display: flex;
			}
		}

	}

	.action {

		@media (max-width: 1300px) and (min-width: 1171px) {
			&.-small {
				font-size: 13px;
				padding: 0 15px;
			}
		}

	}

	.medium-language {

		@media (max-width: 1170px) {
			display: flex;
		}

		@media (max-width: 375px) {
			display: none;
		}

	}

	.menu-trigger {

		@media (max-width: 1170px) {
			display: flex;
			padding-right: 5%;
			margin-right: 0;
		}

	}

}

// 🇫🇷 French
.-fr {

	.nav-bar {

		@media (max-width: 960px) {
			&.-active.-shadow {
				box-shadow: 0 14px 40px -8px rgba(14, 12, 27, 0.2);
			}
		}

	}

	.col-1200 {

		@media (max-width: 960px) {
			height: auto;
			flex-wrap: wrap;
			padding: 0;
		}

	}

	.logo-link {

		@media (max-width: 960px) {
			padding-left: 5%;
		}

	}

	.menu {

		@media (max-width: 960px) {
			width: 100%;
			display: none;
			padding: 10px 5% 15px 5%;
			border-top: 1px solid lighten($color-silver, 15);

			&.-active {
				display: block;
			}
		}

	}

	.item {

		&.-button {
			@media (max-width: 960px) {
				display: none;
			}
		}

		&.-mobile {
			@media (max-width: 960px) {
				display: block;
			}
		}

		&.-language {
			@media (max-width: 960px) {
				display: none;
			}
			@media (max-width: 375px) {
				display: block;
			}
		}

	}

	.link {

		@media (max-width: 1150px) and (min-width: 961px) {
			margin: 0 5px;
			padding: 20px 10px;
			font-size: 13px;
		}

		@media (max-width: 960px) {
			display: block;
			margin: 0;
			padding: 15px 5px;
			margin-bottom: 5px;
			font-size: 15px;
			&.language-dropdown {
				display: flex;
			}
		}

	}

	.action {

		@media (max-width: 1150px) and (min-width: 961px) {
			&.-small {
				font-size: 13px;
				padding: 0 15px;
			}
		}

	}

	.medium-language {

		@media (max-width: 960px) {
			display: flex;
		}

		@media (max-width: 375px) {
			display: none;
		}

	}

	.menu-trigger {

		@media (max-width: 960px) {
			display: flex;
			padding-right: 5%;
			margin-right: 0;
		}

	}

}

// 🇮🇹 Italian
.-it {

	.nav-bar {

		@media (max-width: 1060px) {
			&.-active.-shadow {
				box-shadow: 0 14px 40px -8px rgba(14, 12, 27, 0.2);
			}
		}

	}

	.col-1200 {

		@media (max-width: 1060px) {
			height: auto;
			flex-wrap: wrap;
			padding: 0;
		}

	}

	.logo-link {

		@media (max-width: 1060px) {
			padding-left: 5%;
		}

	}

	.menu {

		@media (max-width: 1060px) {
			width: 100%;
			display: none;
			padding: 10px 5% 15px 5%;
			border-top: 1px solid lighten($color-silver, 15);

			&.-active {
				display: block;
			}
		}

	}

	.item {

		&.-button {
			@media (max-width: 1060px) {
				display: none;
			}
		}

		&.-mobile {
			@media (max-width: 1060px) {
				display: block;
			}
		}

		&.-language {
			@media (max-width: 1060px) {
				display: none;
			}
			@media (max-width: 375px) {
				display: block;
			}
		}

	}

	.link {

		padding: 20px 10px;

		@media (max-width: 1220px) and (min-width: 1061px) {
			margin: 0 5px;
			padding: 20px 10px;
			font-size: 13px;
		}

		@media (max-width: 1060px) {
			display: block;
			margin: 0;
			padding: 15px 5px;
			margin-bottom: 5px;
			font-size: 15px;
			&.language-dropdown {
				display: flex;
			}
		}

	}

	.action {

		@media (max-width: 1220px) and (min-width: 1061px) {
			&.-small {
				font-size: 13px;
				padding: 0 15px;
			}
		}

	}

	.medium-language {

		@media (max-width: 1060px) {
			display: flex;
		}

		@media (max-width: 375px) {
			display: none;
		}

	}

	.menu-trigger {

		@media (max-width: 1060px) {
			display: flex;
			padding-right: 5%;
			margin-right: 0;
		}

	}

}

// 🇷🇺 Russian
.-ru {

	.nav-bar {

		@media (max-width: 1160px) {
			&.-active.-shadow {
				box-shadow: 0 14px 40px -8px rgba(14, 12, 27, 0.2);
			}
		}

	}

	.col-1200 {

		@media (max-width: 1160px) {
			height: auto;
			flex-wrap: wrap;
			padding: 0;
		}

	}

	.logo-link {

		@media (max-width: 1160px) {
			padding-left: 5%;
		}

	}

	.menu {

		@media (max-width: 1160px) {
			width: 100%;
			display: none;
			padding: 10px 5% 15px 5%;
			border-top: 1px solid lighten($color-silver, 15);

			&.-active {
				display: block;
			}
		}

	}

	.item {

		&.-button {
			@media (max-width: 1160px) {
				display: none;
			}
		}

		&.-mobile {
			@media (max-width: 1160px) {
				display: block;
			}
		}

		&.-language {
			@media (max-width: 1160px) {
				display: none;
			}
			@media (max-width: 375px) {
				display: block;
			}
		}

	}

	.link {

		padding: 20px 10px;

		@media (max-width: 1350px) and (min-width: 1161px) {
			margin: 0 5px;
			padding: 20px 10px;
			font-size: 13px;
		}

		@media (max-width: 1160px) {
			display: block;
			margin: 0;
			padding: 15px 5px;
			margin-bottom: 5px;
			font-size: 15px;
			&.language-dropdown {
				display: flex;
			}
		}

	}

	.action {

		@media (max-width: 1350px) and (min-width: 1161px) {
			&.-small {
				font-size: 13px;
				padding: 0 15px;
			}
		}

	}

	.medium-language {

		@media (max-width: 1160px) {
			display: flex;
		}

		@media (max-width: 375px) {
			display: none;
		}

	}

	.menu-trigger {

		@media (max-width: 1160px) {
			display: flex;
			padding-right: 5%;
			margin-right: 0;
		}

	}

}
</style>
