<template>
	<div class="home">
		<NavBar/>
		<Header @showPlayer="showPlayer" @showMoneyBack="showMoneyBack" />
		<MoneyBackModal :active="moneyBackModalActive" @close="hideMoneyBack" />
		<Video @showPlayer="showPlayer" ref="video"/>
		<Introduction @showChanges="showChanges" />
		<ChangesModal :active="modalActive" @close="hideChanges" />
		<Features />
		<DarkFeatures />
		<Integration />
		<Testimonials />
		<Advertisement />
		<Newsletter />
		<Player :active="playerActive" @close="hidePlayer" />
		<div class="menu-overlay" :class="langClass"></div>
	</div>
</template>

<script>
import NavBar from '../components/NavBar.vue'
import Header from '../components/Header.vue'
import MoneyBackModal from '../components/MoneyBackModal.vue'
import Video from '../components/Video.vue'
import Introduction from '../components/Introduction.vue'
import ChangesModal from '../components/ChangesModal.vue'
import Features from '../components/Features.vue'
import DarkFeatures from '../components/DarkFeatures.vue'
import Integration from '../components/Integration.vue'
import Testimonials from '../components/Testimonials.vue'
import Advertisement from '../components/Advertisement.vue'
import Newsletter from '../components/Newsletter.vue'
import Player from '../components/Player.vue'

export default {
	name: 'Home',
	metaInfo() {
		return this.localizedMeta('/', 'home')
	},
	components: {
		NavBar,
		Header,
		MoneyBackModal,
		Video,
		Introduction,
		ChangesModal,
		Features,
		DarkFeatures,
		Integration,
		Testimonials,
		Advertisement,
		Newsletter,
		Player
	},
	data() {
		return {
			modalActive: false,
			moneyBackModalActive: false,
			playerActive: false
		}
	},
	methods: {
		showChanges() {
			this.modalActive = true
		},
		hideChanges() {
			this.modalActive = false
		},
		showMoneyBack() {
			this.moneyBackModalActive = true
		},
		hideMoneyBack() {
			this.moneyBackModalActive = false
		},
		showPlayer() {
			this.$refs.video.pause()
			this.playerActive = true
		},
		hidePlayer() {
			this.playerActive = false
			this.$refs.video.play()
		}
	}
}
</script>

<style lang="scss" scoped>
.newsletter {

	margin-top: 136px;

	@media (max-width: 1000px) {
		margin-top: 70px;
	}

}
</style>
