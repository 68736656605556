<template>
	<section class="dark-features">
		<div class="col-1200">
			<div class="highlights">
				<div class="hightlight">
					<img class="icon" src="../assets/img/icons/shortcut.svg" alt="">
					<h2 class="title">{{ $t('darkFeatures.shortcuts.title') }}</h2>
					<p class="description">{{ $t('darkFeatures.shortcuts.description') }}</p>
				</div>
				<div class="hightlight">
					<img class="icon" src="../assets/img/icons/appearance.svg" alt="">
					<h2 class="title">{{ $t('darkFeatures.appearance.title') }}</h2>
					<p class="description">{{ $t('darkFeatures.appearance.description') }}</p>
				</div>
				<div class="hightlight">
					<img class="icon" src="../assets/img/icons/lightweight.svg" alt="">
					<h2 class="title">{{ $t('darkFeatures.lightweight.title') }}</h2>
					<p class="description">{{ $t('darkFeatures.lightweight.description') }}</p>
				</div>
				<div class="hightlight">
					<img class="icon" src="../assets/img/icons/retina.svg" alt="">
					<h2 class="title">{{ $t('darkFeatures.retina.title') }}</h2>
					<p class="description">{{ $t('darkFeatures.retina.description') }}</p>
				</div>
			</div>
		</div>
		<div class="macbook-box">
			<div class="col-1200">
				<img class="macbook" :class="{ '-animated': animated }" src="../assets/img/macbook.png" srcset="../assets/img/macbook@2x.png 2x" alt="">
			</div>
			<div class="macbook-texts" ref="texts">
				<div class="col-1200">
					<h2 class="macbook-title" :class="langClass">{{ $t('darkFeatures.touchbar.title') }}</h2>
					<p class="macbook-description" :class="langClass">{{ $t('darkFeatures.touchbar.description') }}</p>
				</div>
			</div>
			<div class="gradient"></div>
		</div>
	</section>
</template>

<script>
let observer = null

export default {
	name: 'DarkFeatures',
	data() {
		return {
			animated: false
		}
	},
	methods: {
		intersectionCallback(entries) {
			if (entries.some(entry => entry.intersectionRatio > 0)) {
				this.animated = true
				observer.disconnect()
			}
		}
	},
	mounted() {
		observer = new IntersectionObserver(this.intersectionCallback, {
			threshold: 0.5
		})
		observer.observe(this.$refs.texts)
	},
	destroyed() {
		if (observer !== null) {
			observer.disconnect()
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.dark-features {

	overflow: hidden;
	position: relative;

	background: $color-black;
	background-image: $gradient-dark;

}

.highlights {

	display: grid;
	justify-content: space-around;
	grid-template-columns: auto auto auto auto;
	grid-column-gap: 25px;
	padding: 130px 0;

	@media (max-width: 1050px) {
		grid-template-columns: auto auto;
		grid-row-gap: 70px;
		grid-column-gap: 40px;
		padding: 100px 0;
	}

	@media (max-width: 550px) {
		grid-template-columns: auto;
		grid-row-gap: 70px;
		padding: 80px 0;
	}

}

.hightlight {

	text-align: center;

	&:last-of-type {

		margin-right: 0;

	}

}

.title {

	max-width: 90%;

	text-align: center;
	margin-bottom: 0;
	margin-left: auto;
    margin-right: auto;

	font-size: 24px;
	font-weight: $bold;
	line-height: 1.67;
	letter-spacing: -0.2px;
	color: $color-white;

	@media (max-width: 1200px) {
		font-size: 20px;
	}

}

.description {

	max-width: 280px;

	text-align: center;
	margin: 0 auto;
	margin-top: 16px;

	font-size: 16px;
	line-height: 1.5;
	color: rgba(255, 255, 255, 0.6);

	@media (max-width: 1200px) {
		margin-top: 10px;
		font-size: 14px;
	}

}

.macbook-box {

	width: 100vw;

	position: relative;

	@media (max-width: 800px) {
		display: none;
	}

}

.macbook {

	width: 123%;

	margin-left: -11.5%;

	opacity: 0;
	transform: translateY(150px);
	transition: opacity 0.9s ease, transform 1.2s ease;

	&.-animated {
		opacity: 1;
		transform: translateY(0);
	}

}

.gradient {

	width: 100vw;
	height: 75%;

	position: absolute;
	left: 0;
	bottom: 0;
	z-index: 1;

	background-image: linear-gradient(180deg, rgba(26,16,33,0.00) 0%, #1A1021 100%);

}

.macbook-texts {

	width: 100%;

	text-align: center;
	position: absolute;
	top: 50%;
	margin-top: -30px;
	z-index: 2;

	@media (max-width: 1000px) {
		margin-top: -20px;
	}

}

.macbook-title {

	text-align: center;
	margin: 0;
	margin-bottom: 16px;

	font-size: 48px;
	font-weight: $bold;
	line-height: 1.5;
	letter-spacing: -0.4px;
	color: $color-white;

	@media (max-width: 1150px) {
		&.-fr {
			font-size: 36px;
			margin-bottom: 6px;
		}
	}

	@media (max-width: 1000px) {
		font-size: 36px;
		margin-bottom: 6px;
	}

}

.macbook-description {

	text-align: center;
	margin: 0;

	font-size: 24px;
	font-weight: $medium;
	line-height: 1.5;
	letter-spacing: -0.2px;
	color: rgba($color-white, 0.6);

	@media (max-width: 1150px) {
		&.-fr {
			font-size: 19px;
		}
	}

	@media (max-width: 1000px) {
		font-size: 19px;
	}

}

</style>
