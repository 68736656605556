<template>
	<section class="introduction">
		<div class="col-1200">
			<div class="left">
				<p class="header">{{ $t('introduction.label') }}</p>
				<p class="title" :class="langClass" v-html="$t('introduction.title')"></p>
				<p class="text">{{ $t('introduction.content') }}</p>
				<p class="check-out" @click="$emit('showChanges')" ref="changes">
					{{ $t('introduction.changes') }}
					<img class="arrow" src="../assets/img/arrow-right.svg" alt="">
				</p>
			</div>
			<div class="right">
				<div class="progresses">
					<div class="version -color">
						<div class="label" :class="langClass">{{ $t('introduction.version2') }}</div>
						<div class="progress">
							<div class="bar -color" :class="{ '-animated': animated }"></div>
						</div>
					</div>
					<div class="version -grey">
						<div class="label" :class="langClass">{{ $t('introduction.version1') }}</div>
						<div class="progress">
							<div class="bar -grey" :class="{ '-animated': animated }"></div>
						</div>
					</div>
				</div>
				<div class="line"></div>
				<p class="description">{{ $t('introduction.explanation') }}</p>
			</div>
		</div>
	</section>
</template>

<script>
let observer = null

export default {
	name: 'Introduction',
	data() {
		return {
			animated: false
		}
	},
	methods: {
		intersectionCallback(entries) {
			if (entries.some(entry => entry.intersectionRatio > 0)) {
				this.animated = true
				observer.disconnect()
			}
		}
	},
	mounted() {
		observer = new IntersectionObserver(this.intersectionCallback, {
			threshold: 1.0
		})
		observer.observe(this.$refs.changes)
	},
	destroyed() {
		if (observer !== null) {
			observer.disconnect()
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.introduction {

	margin-top: -138px;
	padding-top: 230px;
	padding-bottom: 133px;

	color: $color-primary;

	background-image: $gradient-dark;

	@media (max-width: 1000px) {
		padding-top: 180px;
		padding-bottom: 70px;
	}

	@media (max-width: 500px) {
		margin-top: -100px;
		padding-top: 130px;
		padding-bottom: 50px;
	}

}

.col-1200 {

	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 1000px) {
		text-align: center;
		justify-content: center;
		z-index: 1;
		position: relative;
	}

}

.right {

	max-width: 500px;

	margin-left: 70px;
	flex-grow: 1;

	@media (max-width: 1000px) {
		display: none;
	}

}

.header {

	display: inline-block;
	padding: 10px 15px;
	margin-top: 0;

	font-size: 12px;
	font-weight: $bold;
	color: $color-primary;
	letter-spacing: 2px;
	line-height: 1.7;

	text-transform: uppercase;
	background-image: linear-gradient(135deg, rgba(#FF844A, 0.1) 0%, rgba(#FF3B79, 0.1) 100%);
	border-radius: 8px;

	@media (max-width: 1000px) {
		display: none;
	}

}

.title {

	margin-top: 18px;

	font-weight: $bold;
	font-size: 44px;
	line-height: 1.4;
	letter-spacing: -0.37px;
	color: $color-white;

	&.-ja {
		font-size: 40px;
	}

	@media (max-width: 500px) {
		margin-bottom: 25px;
		font-size: 35px;
	}

}

::v-deep .gradient-fill {

	display: inline-block;

	background: $gradient-primary;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

}

.text {

	max-width: 480px;
	margin-top: 0;
	margin-bottom: 42px;

	color: rgba($color-white, 0.7);
	line-height: 1.89;
	letter-spacing: -0.1px;
	font-size: 19px;

	@media (max-width: 1000px) {
		max-width: 500px;
	}

	@media (max-width: 500px) {
		font-size: 17px;
		margin-bottom: 25px;
	}

}

.check-out {

	display: inline-block;
	position: relative;
	margin: 0;

	color: $color-primary;
	font-size: 20px;
	font-weight: $bold;
	line-height: 1.8;
	letter-spacing: -0.2px;

	cursor: pointer;

	@media (max-width: 500px) {
		font-size: 18px;
	}

	.arrow {
		position: relative;
		top: 6px;
		margin-left: 6px;
	}

	&:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 2px;
		bottom: 0;
		left: 0;
		z-index: 2;
		background-color: $color-primary;
		opacity: 0;
		transition: opacity 0.1s ease;
	}

	&:hover:after {
		opacity: 1;
	}

}

.version {

	display: flex;
	align-items: center;

	color: $color-white;

	&.-grey {

		margin-top: 30px;

		font-size: 22px;
		font-weight: $bold;
		line-height: 1.64;
		letter-spacing: -0.2px;
		color: rgba(255, 255, 255, 0.6);

	}

	&.-color {

		font-size: 22px;
		font-weight: $bold;
		line-height: 1.64;
		letter-spacing: -0.2px;

	}

}

.label {

	width: 100px;

	text-align: right;

	&.-ja {
		width: 130px;
	}

	&.-it {
		width: 120px;
	}

}

.progress {

	flex-grow: 1;
	margin-left: 35px;

}

.bar {

	height: 17px;

	border-radius: 10px;
	background-image: linear-gradient(96deg, #ff844a, #ff3b79);

	&.-color {

		width: 17px;

		transition: width 0.5s ease;

		&.-animated {
			width: 50%;
		}

	}

	&.-grey {

		width: 17px;

		opacity: 0.16;
		background-image: linear-gradient(93deg, rgba(255, 255, 255, 0.61), #ffffff);
		transition: width 1.5s ease 0.1s;

		&.-animated {
			width: 100%;
		}

	}

}

.line {

	width: 100%;
	height: 1px;

	margin-top: 62px;
	margin-bottom: 25px;

	background: rgba($color-white, 0.1);

}

.description {

	margin: 0;

	font-size: 11px;
	font-weight: $medium;
	line-height: 1.82;
	letter-spacing: 0.8px;
	color: rgba($color-white, 0.3);

	text-transform: uppercase;

}

</style>
