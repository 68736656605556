<template>
	<button class="action" :class="[ `-${size}`, `-${color}`, { '-outline': outline } ]" @click="$emit('click', $event)">
		<img class="icon" src="../assets/img/icons/small-play.svg" v-if="play" :class="{ '-loading': loading }" alt="">
		<span class="label" :class="{ '-loading': loading }">{{ label }}</span>
		<div class="loading" v-if="loading">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</button>
</template>

<script>
export default {
	name: 'ActionButton',
	props: {
		label: String,
		size: {
			type: String,
			default: 'standard'
		},
		outline: {
			type: Boolean,
			default: false
		},
		color: {
			type: String,
			default: 'red'
		},
		play: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.action {

	height: 52px;

	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 0 30px;
	position: relative;

	color: $color-white;
	font-size: 15px;
	font-weight: $bold;
	letter-spacing: -0.19px;
	line-height: 1.6;

	border: none;
	border-radius: 8px;
	cursor: pointer;
	outline: none;
	transition: background-position 0.5s ease-out, transform 0.2s ease, box-shadow 0.2s ease, border 0.2s ease;
	user-select: none;

	&.-small {
		height: 35px;
		padding: 0 19px;
		font-size: 14px;
	}

	&.-medium {
		height: 40px;
		padding: 0 19px;
		font-size: 14px;
	}

	&.-large {
		height: 60px;
		padding: 0 28px;
		font-size: 16px;
	}

	&.-red {
		background-image: linear-gradient(135deg, #FF844A 0%, #F94069 50%, #FF844A 100%);
		background-size: 250%;
	}

	&.-outline {

		&.-red {
			color: $color-primary;
			border: 2px solid rgba($color-primary, 0.4);
			background: $color-white;
		}

	}

	&:hover {
		transform: translateY(-2px);
		&.-small {
			transform: translateY(-1px);
		}
		&.-red {
			box-shadow: 0 7px 20px 0 rgba(255, 104, 93, 0.3);
			background-position: 100%;
		}
		&.-outline.-red {
			border: 2px solid rgba($color-primary, 0.6);
			box-shadow: 0 7px 20px 0 rgba(255, 104, 93, 0.15);
			background: $color-white;
		}
	}

}

.label {

	&.-loading {
		opacity: 0;
	}

}

.icon {

	margin-right: 10px;

	&.-loading {
		opacity: 0;
	}

}

.loading {

	width: 25px;
	height: 25px;

	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -12.5px;
	margin-top: -12.5px;

}

.loading div {

	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 20px;
	height: 20px;
	margin: 2px;
	border: 2px solid #fff;
	border-radius: 50%;
	animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;

}

.loading div:nth-child(1) {

	animation-delay: -0.45s;

}

.loading div:nth-child(2) {

	animation-delay: -0.3s;

}

.loading div:nth-child(3) {

	animation-delay: -0.15s;

}

@keyframes loading {

	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}

}
</style>
