<template>
	<section class="moneyback-modal" :class="{ '-loaded': loaded, '-active': active }" @click="$emit('close')">
		<div class="content" @click.stop>
			<img class="close" src="../assets/img/icons/close.svg" @click="$emit('close')" alt="Close">
			<MoneyBack />
			<Rating />
		</div>
	</section>
</template>

<script>
import MoneyBack from '../components/MoneyBack.vue'
import Rating from '../components/Rating.vue'

export default {
	name: 'MoneyBackModal',
	components: {
		MoneyBack,
		Rating
	},
	props: {
		active: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			loaded: false
		}
	},
	watch: {
		active: (isActive) => {
			if (isActive) {
				document.querySelector('body').classList.add('-modal')
			} else {
				document.querySelector('body').classList.remove('-modal')
			}
		}
	},
	mounted() {
		if (typeof window.__PRERENDER_INJECTED === 'undefined') {
			this.loaded = true
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.moneyback-modal {

	width: 100%;
	height: 100%;

	display: none;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
    flex-direction: row;
    justify-content: center;
	align-items: center;

	background-image: linear-gradient(55deg, rgba($color-black, 0.6), rgba(#171330, 0.6));
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.2s ease;

	&.-loaded {
		display: flex;
	}

	&.-active {
		opacity: 1;
		pointer-events: all;
	}

}

.content {

	max-width: 640px;

	margin: 40px auto;
	box-sizing: border-box;
	padding: 50px 10px;
	position: relative;
	flex-grow: 1;

	border-radius: 16px;
	box-shadow: 0 48px 100px 0 rgba(17, 12, 46, 0.15);
	background-color: $color-white;
	transform: scale(0.85);
	opacity: 0;
	transition: transform 0.2s ease, opacity 0.2s ease;

	@media (max-width: 720px) {
		margin: 4%;
	}

}

.-active > .content {

	transform: scale(1);
	opacity: 1;

}

.close {

	position: absolute;
	right: 15px;
	top: 15px;
	padding: 15px;

	cursor: pointer;
	transition: all 0.1s ease;

	@media (max-width: 880px) {
		right: 10px;
		top: 10px;
	}

	&:hover {
		transform: scale(0.9);
	}

}

.rating {

	margin-top: 50px;

}
</style>
