<template>
	<section class="money-back">
		<div class="col-1200">
			<div class="content">
				<div class="heading">{{ $t('moneyback.title') }}</div>
				<p class="description" v-html="$t('moneyback.content')"></p>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'MoneyBack'
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.money-back {

	text-align: center;

}

.content {

	max-width: 500px;

	margin: 0 auto;

}

.heading {

	font-weight: $bold;
	font-size: 24px;
	color: $color-black;
	letter-spacing: -0.2px;
	line-height: 1.7;

}

.description {

	margin-top: 25px;

	font-size: 16px;
	color: $color-gray;
	line-height: 2;

}

.description ::v-deep .link {

	position: relative;

	font-weight: $medium;
	color: $color-primary;

	&:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 2px;
		bottom: -1px;
		left: 0;
		z-index: 2;
		background-color: $color-primary;
		opacity: 0;
		transition: opacity 0.1s ease;
	}

	&:hover:after {
		opacity: 0.8;
	}

}
</style>
